import React, {useContext, useState} from 'react';
import {ElementContext} from './ElementContext';
import { Icon } from '@iconify/react';
import { fetchBackend } from '../helpers/fetch';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';
import { Link } from 'react-router-dom';


const CancelSubscriptionScreen = () => {

  const { userStripeData, globalEnv, userConfiguration, userInformation } = useContext(ElementContext);
  const { t } = useTranslation();
  const { user } = useAuth();
  
  const [firstPart, setFirstPart] = useState({ enabled: true, reasonSelected: [], otherReason: '' })
  const [clicked, setClicked] = useState(false)
  
  const handleDeletingReason = (e, enabled) => {
      e.preventDefault()
      setFirstPart({ enabled, reasonSelected: firstPart.reasonSelected })
  }

  const handleSelectReason = async(e) => {
    const optionSelected = await e.target.value
    const reasonChecked = await e.target.checked
    const reasonsList = firstPart.reasonSelected

    if(reasonChecked){
      reasonsList.push(optionSelected)
      setFirstPart({ enabled: firstPart.enabled, reasonSelected: reasonsList })
    } else {
      const filteredReasonsList = reasonsList.filter( reason => reason !== optionSelected )
      setFirstPart({ enabled: firstPart.enabled, reasonSelected: filteredReasonsList })
    }
  }

  const handleWriteOtherReason = (e) => {
    const reasonText = e.target.value
    if(firstPart.reasonSelected.includes('other')){
      setFirstPart({
        enabled: firstPart.enabled,
        reasonSelected: firstPart.reasonSelected,
        otherReason: reasonText
      })
    }
  }

  const handleDeleteSubscription = async(e) => {
    e.preventDefault();
    setClicked(true)
    const hasMpEnabled = await globalEnv.MP_COUNTRIES_ENABLED.includes(userInformation.isoCode)

    const unsubscriptionData = {
      email: await user.email,
      subscriptionID: await userStripeData.idsuscripcion,
      reasonsData: firstPart.reasonSelected,
      otherReasonData: firstPart.otherReason,
      isUpdate: false,
      userConfiguration
    }

    if(hasMpEnabled){
      const mpIdSubscription = await userStripeData.idsuscripcion
      const deleteRequest = await fetchBackend(`delete-mp-subscription/${mpIdSubscription}`, {unsubscriptionData}, 'DELETE');
      const deleteResponse = await deleteRequest.json();
      if(deleteResponse.ok){ Swal.fire( t('done'), t('subscriptionCanceled'), 'success' ) }
    } else {
      const deleteRequest = await fetchBackend('delete-subscription', {unsubscriptionData}, 'DELETE');
      const deleteResponse = await deleteRequest.json();
      if(deleteResponse.ok){ Swal.fire( t('done'), t('subscriptionCanceled'), 'success' ) }
    }
    
    setTimeout(() => {
      window.location.replace('/')
    }, 3000);
  }

  return (
    <div className="home delete-subscription-screen">
      <div>
        <form id='newTemplateForm'>
            <div className='delete-sub-form'>
              
                <div className='harbinger-logo-azul'></div>

                <div className={ firstPart.enabled ? 'delete-sub-first-part animate__animated animate__fadeInLeft' : 'd-none' }>

                  <div className="d-flex flex-column justify-content-center align-items-center mt-1">
                    <div style={{ "width": "100%" }}>
                        <h5 className='text-center text-mediumblue mb-1'>
                          {t('cancelSubscription')}
                        </h5>
                    </div>
                    <small className='text-center text-lightblue mb-4'>
                        {t('unsubMessage')}.
                    </small>
                    <small className='color-login text-center mb-4'>
                        { t('unsubExplanation') }.
                    </small>
                    <small className='color-login text-center'>
                        {t('unsubConfirm')}
                    </small>
                      
                    <div className='d-flex justify-content-center mt-5 mb-2'>
                        <Link to="/" className='btn btn-sm btn-mediumblue'>
                            { t('noReturn') }
                        </Link>
                        
                        <button
                            className='btn btn-sm btn-lightblue d-flex align-items-center ms-3'
                            onClick={ (e) => handleDeletingReason(e, false) }
                        >
                          <span>{ t('yesContinue') }</span>
                          <Icon icon="material-symbols:arrow-forward-ios" className='ms-2 delete-arrow' />
                        </button>
                    </div>
                  </div>
                </div>

                <div className={ !firstPart.enabled ? 'delete-sub-second-part animate__animated animate__fadeInRight' : 'd-none' }>
                  
                  <div className="d-flex flex-column justify-content-center align-items-center mt-1">
                    <div style={{ "width": "100%" }}>
                        <h5 className='text-center text-mediumblue mb-1'>
                          { t('tellUsYourReason') }
                        </h5>
                    </div>

                    <div>
                        <label className="form-label text-lightblue template-labels mb-4">
                          { t('selectOption') }
                        </label>
                        {
                          globalEnv.UNSUBSCRIPTION_REASONS.map( (reason, index) => (
                            <div className='d-flex align-items-center' key={index}>
                                <input type="checkbox" name='tempCategory' id={`reasonOption${index}`} value={reason} className='tempCategory' onChange={ handleSelectReason } />
                                <label htmlFor={`reasonOption${index}`} className='delete-category-radio'>
                                  <span>{ reason }</span>
                                </label>
                            </div>
                          ))
                        }
                        {/* Other reason */}
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center'>
                            <input type="checkbox" name='tempCategory' id="radio-marketing" value="other" className='tempCategory' onChange={ handleSelectReason } />
                            <label htmlFor='radio-marketing' className='delete-category-radio'>
                              <span>{ t('other') }</span>
                            </label>
                          </div>

                          <div className={ firstPart.reasonSelected.includes('other') ? 'animate__animated animate__zoomIn ms-3 mt-2' : 'd-none' }>
                            <label className="form-label text-lightblue template-labels">
                              { t('completeField') }
                            </label>
                            <textarea
                              className='form-control mt-2'
                              placeholder={`${t('write')}...`}
                              id="delete-sub-textarea"
                              name='tempBody'
                              value={ firstPart.otherReason }
                              onChange={ handleWriteOtherReason }
                              rows="6">
                            </textarea>
                          </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between mt-5 mb-2'>
                        <button
                            className='btn btn-sm btn-lightblue d-flex align-items-center'
                            onClick={ (e) => handleDeletingReason(e, true) }
                        >
                          <Icon icon="material-symbols:arrow-back-ios-new" className='me-2 delete-arrow' />
                          {t('back')}
                        </button>
                        
                        <button
                          disabled={ (firstPart.reasonSelected.length === 0) || clicked }
                          className='btn btn-sm btn-91 ms-3'
                          onClick={ handleDeleteSubscription }>
                            {
                              clicked
                              ? <div>
                                  <span className='text-light'>
                                      {t('processing')}...
                                  </span>
                                  <div className="spinner-border text-light loading-button ms-2" role="status">
                                      <span className="visually-hidden">...</span>
                                  </div>
                                </div>
                              : t('unsubscribe')
                            }
                        </button>
                    </div>
                  </div>
                </div>
            </div>
        </form>
      </div>
    </div>
  )
}

export default CancelSubscriptionScreen