import React, {useContext, useEffect} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { fetchPythonBackend } from '../../helpers/fetch';
import { useAuth } from '@frontegg/react';
import { useState } from 'react';
import { platformsLogos } from '../integrations';
import Swal from 'sweetalert2';
import { DataExchange } from 'aws-sdk';
import {fetchBackend} from '../../helpers/fetch'

Modal.setAppElement('#root');

const InsertNewIntegrationModal = () => {

    const { user } = useAuth();
    const { newIntegrationModalOpen, setNewIntegrationModalOpen, setIntegrationsUserInformation, integrationsUserInformation } = useContext(ElementContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [selectedPlatform, setSelectedPlatform] = useState('sapb1')

    const [credentialsActivateSwitch, setCredentialsActivateSwitch] = React.useState(false)

    console.log(credentialsActivateSwitch)

    const handleSubmitCredentials = async(e) => {
        e.preventDefault()
        setLoading(true)

        try {
            if(!credentialsActivateSwitch){
                
                const tenantId = user.email.replace('@', '_')
                const name = await document.querySelector('#cnnName').value
                const address = await document.querySelector('#dbAddress').value
                const port = await document.querySelector('#dbPort').value
                const schema = await document.querySelector('#dbSchema').value
                const dbUser = await document.querySelector('#dbUser').value
                const password = await document.querySelector('#dbPass').value

                const body = { name, address, port, schema, dbUser, password, tenantId }
                const testConnRequest = await fetchPythonBackend('api/integrations/sap-b1', body, 'POST');
                const testConnResponse = await testConnRequest.json();
                const insertedId = await testConnResponse.insertedId;
            

                if(testConnResponse.ok){
                    setIntegrationsUserInformation([ ...integrationsUserInformation, { tenantid: tenantId, credenciales: {address, port, schema, user: dbUser, password}, createdDate: new Date(), plataforma: selectedPlatform, nombreconexion: name, idintegracion: insertedId }])

                    closeModal()

                }
            } else {
                const sapb1Data = await fetchBackend('extraction-data-sapb1')
                const sapb1DataResponse = await sapb1Data.json()
                console.log(sapb1DataResponse)
                const tenantId = user.email.replace('@', '_')
                const name = await document.querySelector('#cnnName').value
                const fiscalKeyApi= await document.querySelector('#fiscalKeyApi').value
                const tokenApi = await document.querySelector('#tokenApi').value
                const insertedId = await sapb1DataResponse.insertedId;


                if(sapb1DataResponse.ok){

                    const body = { name,fiscalKeyApi,tokenApi, tenantId }
                    const testConnRequest = await fetchPythonBackend('extraction-data-sapb1', body, 'POST');
                    const testConnResponse = await testConnRequest.json();
                    console.log(testConnResponse)
                    setIntegrationsUserInformation([ ...integrationsUserInformation, { tenantid: tenantId, 
                        credenciales: false,
                        createdDate: new Date(), 
                        plataforma: selectedPlatform,
                        nombreconexion: name ,
                        rut: fiscalKeyApi,
                        token: tokenApi,
                        idintegracion: insertedId
                    }]);

                    closeModal()
                }
                
            }

        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const closeModal = () => {
        setNewIntegrationModalOpen(false)
    }

    const handleCloseForm = (e) => {
        e.preventDefault()
        closeModal()
    }

  return (
    <Modal
        isOpen={ newIntegrationModalOpen }
        id='new-integration-modal'
        className="modal-mfa p-4"
        overlayClassName="modal-fondo"
    >
        <h4 className='text-center text-mediumblue mb-4'>
            {t("addNewIntegration")}
        </h4>
        
        <small className='text-justify main-label border-top d-block pt-3 pb-4'>
            {t("SelectThePlatform")}
        </small>

        <div className='select-platform-img'>
            <div className={ selectedPlatform === 'sapb1' ? 'selected-logo' : '' } onClick={ () => setSelectedPlatform('sapb1') }>
                <img src={ platformsLogos.sapb1 } />
            </div>
            <div className={ selectedPlatform === 's4hana' ? 'selected-logo' : '' } onClick={ () => setSelectedPlatform('s4hana') }>
                <img src={ platformsLogos.s4hana } />
            </div>
            <div className={ selectedPlatform === 'hubspot' ? 'selected-logo' : '' } onClick={ () => setSelectedPlatform('hubspot') }>
                <img src={ platformsLogos.hubspot } />
            </div>
        </div>

        <form onSubmit={handleSubmitCredentials}>
        
            <small className='text-justify d-block main-label pt-3 mt-4'>
                {t("assignNameIntegration")}
            </small>
            <div className="d-flex flex-column mt-1 mb-3">
                <label htmlFor="cnnName">
                    <small className='credentials-label'>{t("connectionName")}</small>
                </label>
                <input
                    disabled={loading}
                    autoComplete='off'
                    id='cnnName'
                    type="text"
                    className='form-control text-center'
                    placeholder={t("enterName")}
                    required
                />
            </div>

            <div className='box-text-integ'>
                <p>{t("chooseMethod")}</p>
            </div>
            <div className='box-integation-new'>
                <p id={credentialsActivateSwitch ? 'text-toogle-1-checked' :'text-toogle-1'} >{t("database")}</p>
                <div id="toogle-switch-2" class="form-check form-switch">
                        <input className="form-check-input" 
                        type="checkbox" 
                        role="switch" 
                        id="flexSwitchCheckChecked" 
                        checked={credentialsActivateSwitch}
                        onChange={() => setCredentialsActivateSwitch(!credentialsActivateSwitch)}
                        />
                        <label className="form-check-label" for="flexSwitchCheckChecked">
                        </label>
                </div>
                <p id={credentialsActivateSwitch ? 'text-toogle-2-checked' :'text-toogle-2'}>{t("seidorConnector")}</p>
            </div>

        {credentialsActivateSwitch ? <div className='box-api-rest'>

            <small className='text-justify d-block main-label pt-3 mt-4'>
                {t("connectionDetails")}
            </small>

            <div className="d-flex flex-column mb-3 mt-1">
                <label htmlFor="fiscalKeyApi">
                    <small className='credentials-label'>{t("Fiscal Key")}</small>
                </label>
                <input
                    disabled={loading}
                    autoComplete='off'
                    id='fiscalKeyApi'
                    type="text"
                    className='form-control text-center'
                    placeholder={t("userIdentification")}
                    required
                />
            </div>

            <div className="d-flex flex-column mb-3 mt-1">
                <label htmlFor="tokenApi">
                    <small className='credentials-label'>{t("accessToken")}</small>
                </label>
                <input
                    disabled={loading}
                    autoComplete='off'
                    id='tokenApi'
                    type="text"
                    className='form-control text-center'
                    placeholder={t("enterToken")}
                    required
                />
            </div>
        </div> : (
            <div className='box-credentials'>

                <small className='text-justify d-block main-label pt-3 mt-4'>
                    {t("connectionDetails")}
                </small>

                {
                    selectedPlatform === 'sapb1' && (
                        <>
                            <div className="d-flex flex-column mb-3 mt-1">
                                <label htmlFor="dbAddress">
                                    <small className='credentials-label'>{t("databaseAddress")}</small>
                                </label>
                                <input
                                    disabled={loading}
                                    autoComplete='off'
                                    id='dbAddress'
                                    type="text"
                                    className='form-control text-center'
                                    placeholder={t("enterAddress")}
                                    required
                                />
                            </div>
                            
                            <div className="d-flex flex-column mt-2 mb-3">
                                <label htmlFor="dbPort">
                                    <small className='credentials-label'>{t("databasePort")}</small>
                                </label>
                                <input
                                    disabled={loading}
                                    autoComplete='off'
                                    id='dbPort'
                                    type="text"
                                    className='form-control text-center'
                                    placeholder={t("enterPort")}
                                    required
                                />
                            </div>
                            
                            <div className="d-flex flex-column mt-2 mb-3">
                                <label htmlFor="dbSchema">
                                    <small className='credentials-label'>{t("databaseSchema")}</small>
                                </label>
                                <input
                                    disabled={loading}
                                    autoComplete='off'
                                    id='dbSchema'
                                    type="text"
                                    className='form-control text-center'
                                    placeholder={t("enterSchema")}
                                    required
                                />
                            </div>
                        </>
                    )
                }
            
                <div className="d-flex flex-column mt-2 mb-3">
                    <label htmlFor="dbUser">
                        <small className='credentials-label'>{t("databaseUser")}</small>
                    </label>
                    <input
                        disabled={loading}
                        autoComplete='off'
                        id='dbUser'
                        type="text"
                        className='form-control text-center'
                        placeholder={t("enterUser")}
                        required
                    />
                </div>

                <div className="d-flex flex-column mt-2 mb-3">
                    <label htmlFor="dbPass">
                        <small className='credentials-label'>{t("databasePassword")}</small>
                    </label>
                    <input
                        disabled={loading}
                        autoComplete='off'
                        id='dbPass'
                        // type="password" // Al dejar el type seteado, es autocompletado por Chrome automáticamente indeseablemente
                        className='form-control text-center'
                        placeholder={t("enterPassword")}
                        onChange={ (e) => e.target.type = 'password' }
                        required
                    />
                </div>
            </div>
        )}

            <div className='d-flex justify-content-center align-items-center mt-4'>
                <button
                    type='submit'
                    className='btn btn-lightblue btn-sm me-3'
                    disabled={loading}
                >
                    {
                        loading ? (
                            <>
                                <span className='text-light'>{t('processing')}...</span>
                                <div className="spinner-border text-light loading-button ms-2" role="status">
                                    <span className="visually-hidden">...</span>
                                </div>
                            </>
                        ) : t('save')
                    }
                    
                </button>

                <button
                    className='btn btn-91 btn-sm me-3'
                    onClick={ handleCloseForm }
                    disabled={loading}
                >
                    {t('close')}
                </button>
            </div>
        </form>
    </Modal>
  )
}

export default InsertNewIntegrationModal