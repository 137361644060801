import React, {useContext, useState} from 'react'
import {ElementContext} from '../ElementContext';
import NewFileListModal from '../modals/NewFileListModal'
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import CloudConnectionsTable from '../tables/CloudConnectionsTable'


const CloudConnections = () => {
  
  const { setFilesListsModalOpen } = useContext(ElementContext)
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)


  const handleUpdateTable = async() => {
      setLoading(true)
      console.log('updating table...')
      setLoading(false)
  }

  
  return (
    <div className='home animate__animated animate__fadeIn'>
        <div className="d-flex flex-column justify-content-between table-container pb-3">
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-12">
                        <h2 className='text-mediumblue mt-3 mb-0'>Listas de archivos (Título)</h2>
                        <p className='subtitle'>
                            Listas de archivos (Subtítulo)
                        </p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row d-flex justify-content-end align-items-center">

                    <div className='col-4 btn-group'>
                        <button
                            disabled={loading}
                            className="btn btn-sm btn-mediumblue d-flex align-items-center justify-content-center"
                            onClick={ () => setFilesListsModalOpen(true) }>
                                <Icon icon="ic:baseline-plus" className='icon-size-m me-1' />
                                {t('addFile')}
                        </button>
                        <button
                            disabled={loading}
                            className="btn btn-sm btn-lightblue d-flex align-items-center justify-content-center"
                            onClick={ handleUpdateTable }>
                                <Icon icon="dashicons:update-alt" className='icon-size-m me-1' />
                                {t('updateTable')}
                        </button>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <CloudConnectionsTable />
                    </div>
                </div>
            </div>
        </div>

        <NewFileListModal />
    </div>
  )
}

export default CloudConnections