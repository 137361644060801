import React, { useCallback, useMemo, useRef, useState, useContext } from 'react';
import {ElementContext} from './ElementContext'
import { AgGridReact } from 'ag-grid-react';
import DeleteContactModal from './modals/DeleteContactModal';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { fetchBackend } from '../helpers/fetch';
import Swal from 'sweetalert2';
import phone from 'phone';
import { DateTime } from 'luxon';
import * as xlsx from 'xlsx';
import { useTranslation } from 'react-i18next';
import { getContactsByTenant } from '../helpers/contactos';
import { useAuth } from '@frontegg/react';
// import ContactListCheckboxIndicator from './ContactListCheckboxIndicator';  
// import Tooltip from '@mui/material/Tooltip';
// import '../styles/table.css';


const timeZone = DateTime.now().zoneName;
// const countryCodeList = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}')

class flagCountryIndicator {
  init(params) {
      const element = document.createElement('span');
      const imageElement = document.createElement('img');
      const countryElement = document.createElement('span');
      const noCountryElement = document.createElement('small');

      if(params.value){
        imageElement.src = `https://flagcdn.com/${(params.value).toLowerCase()}.svg`;
        imageElement.width = '25';
        imageElement.className = 'me-2'
        
        element.appendChild(imageElement);
        element.appendChild(countryElement);
        element.appendChild(document.createTextNode(params.value));
        this.eGui = element;
      } else {
        noCountryElement.innerHTML = '<strong>No válido</strong>'
        element.appendChild(noCountryElement);
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}


const createNewRowData = (isoCode) => {
  const newData = {
    idcontacto: '',
    numero: isoCode,
    nombre: '',
    apellido: '',
    empresa: '',
    cargo: '',
    pais: '',
    observaciones: '',
    customfields: {},
  };
  return newData;
};

const ContactList = () => {

  const { user } = useAuth();
  const { infoToShow, setInfoToShow, activeList, setIsActive, changesApplied, setChangesApplied, madeByUser, setConfirmDeleteContactModal, rowSelected, setRowSelected, nameActive, globalEnv, setAllTenantContacts, userInformation } = useContext(ElementContext);

  const { t } = useTranslation();

  const [inputCount, setInputCount] = useState(1)
  const [infoToSave, setInfoToSave] = useState([])
  const [loadedRowInformation, setLoadedRowInformation] = useState('')
  const [contactToRemove, setContactToRemove] = useState({})

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ height: '80vh' }), []);
  const gridStyle = useMemo(() => ({ height: '65vh', marginTop: '5px' }), []);
  const [columnDefs] = useState([
    { headerName: t('number'), field: 'numero', editable: true, sortable: true, filter: true, minWidth: 180, pinned: 'left', cellClass: 'numberType' },
    { headerName: t('name'), field: 'nombre', editable: true, sortable: true, filter: true, minWidth: 140 },
    { headerName: t('lastname'), field: 'apellido', editable: true, sortable: true, filter: true, minWidth: 160 },
    { headerName: t('company'), field: 'empresa', editable: true, sortable: true, filter: true, minWidth: 200 },
    { headerName: t('position'), field: 'cargo', editable: true, sortable: true, filter: true, minWidth: 160 },
    {
      headerName: t('country'),
        valueGetter: (params) => {
          if(params.data.numero){
            const verify = params.data.numero.slice(0, 3) === '503' ? {countryIso2:'SV'} : phone(`+${params.data.numero}`)
            return verify.countryIso2
          }
        },
      editable: false,
      sortable: true,
      minWidth: 100,
      cellRenderer: flagCountryIndicator
    },
    {
      headerName: t('observations'),
      field: 'observaciones',
      editable: true,
      sortable: true,
      filter: true,
      minWidth: 400,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: { maxLength: globalEnv.MSG_MAX_LENGTH }
    },
    // MONTO, FECHA y NUMERO DE FACTURA
    {
      headerName: 'Monto',
      field: 'customfields.monto',
      editable: true,
      sortable: true,
      filter: true,
      minWidth: 200,
    },
    {
      headerName: 'Fecha de Vencimiento',
      field: 'customfields.fechavencimiento',
      editable: true,
      sortable: true,
      filter: true,
      minWidth: 200,
    },
    {
      headerName: 'Número de Factura',
      field: 'customfields.numerofactura',
      editable: true,
      sortable: true,
      filter: true,
      minWidth: 200,
    }
  ]);

  
  let defaultColDef = useMemo(() => {
    return {
      flex: 1
    };
  }, []);


  const addItems = useCallback((addIndex) => {
    setRowSelected(false)

    const cantidad = document.querySelector('#cantidad').value
    let cant = parseInt(cantidad);

    if(cant === 0){
      return Swal.fire( t('warning'), t('numberUpperThanOne'), 'warning' )
    }

    let contador = 0;
    while( contador < cant ){
      const newItems = [
        createNewRowData(userInformation.countryCode)
      ];
  
      gridRef.current.api.applyTransaction({
        add: newItems,
        addIndex: addIndex,
      });
      contador += 1
    }

    setChangesApplied(true)
  }, [setChangesApplied, setRowSelected]);

  
  const onRemoveSelected = async() => {
    const selectedData = gridRef.current.api.getSelectedRows()[0];
    const data = {
      "idcontacto": selectedData.idcontacto,
      "nombre": selectedData.nombre + ' ' + selectedData.apellido,
    }
    setConfirmDeleteContactModal(true)
    setContactToRemove(data)
    setRowSelected(false)
  }

  
  const onRemoveAllContacts = async() => {
    const lista = {
      "idlistaactiva": activeList,
      "nombrelista": nameActive,
    }
    setConfirmDeleteContactModal(true)
    setContactToRemove(lista)
    setRowSelected(false)
  }

  
  const onBtExport = useCallback(() => {
    setRowSelected(false)
    gridRef.current.api.exportDataAsExcel();
  }, [setRowSelected]);

  
  const defaultExcelExportParams = useMemo(() => {
    return {
      processCellCallback: (params) => {
        if(!params.value){
          return '-'
        } else {
          return params.value
        }
      },
    };
  }, []);


  // Eliminar contactos de una lista
  const deleteData = async() => {
    const resultado = await fetchBackend( `delete-contacts/${activeList}`, {}, 'DELETE' )
    const body = await resultado.json();

    if( !body.ok ){
        Swal.fire('Oops', `${body.msg}`, 'error')
    }
  }

  

  // Guardar contactos
  const saveData = async() => {
    let body;
    const tenant = user.email.replace('@', '_')

    const updateInfo = infoToSave.some(user => !user.idcontacto.includes('ID'))
    const newInfo = infoToSave.some(user => user.idcontacto.includes('ID'))

    // Si en la grilla hay contactos que ya existen en BD, realiza un UPDATE
    if(updateInfo){
      const existsUser = infoToSave.filter( user => !user.idcontacto.includes('ID') )
      const data = [...existsUser];
      const res = await fetchBackend( 'contacts', {data}, 'PUT' )
      body = await res.json();
    }
    
    // Si en la grilla hay contactos que NO existen en BD, realiza un INSERT
    if(newInfo) {
      const userNotExistInDB = infoToSave.filter( user => user.idcontacto.includes('ID') || user.idcontacto === '' )
      const data = [...userNotExistInDB];
      const res = await fetchBackend( 'contacts', {data}, 'POST' )
      body = await res.json();
    }
    
    // Obtiene de BD la nueva lista luego de haber guardado
    // const fetchGetContacts = await fetchBackend(`contacts/${activeList}`);
    // const resGetContacts = await fetchGetContacts.json();
    // const dataResult = await resGetContacts.data;
    // setInfoToShow(dataResult);
    const contactList = await getContactsByTenant(tenant)
    setAllTenantContacts(contactList)
    
    
    // if( !resGetContacts.ok ){
    //     return Swal.fire('Oops', t('getContactsFailure'), 'error');
    // }
    if( !body.ok ){
        Swal.fire('Oops', t('requestFailure'), 'error')
    } else {
        Swal.fire(t('success'), t('saveSuccess'), 'success')
    }
  }

  
  const getRowData = useCallback(async() => {
    const rowData = [];
    await gridRef.current.api.forEachNode(function (node) {
      rowData.push(node.data);
    });

    setInfoToSave(rowData)
  }, []);

  
  const fieldsVerify = useCallback(async() => {
    const rowData = [];
    await gridRef.current.api.forEachNode(function (node) {
      rowData.push(node.data);
    });

    // Acá se pueden hacer validaciones para los números

    const someEmptyNum = rowData.some( fila => fila.numero === '');
    if(someEmptyNum){
      return Swal.fire(t('warning'), t('numberFieldEmpty'), 'warning')
    }
  }, [t]);


  const getAndSaveRowData = async() => {
    const tenant = user.email.replace('@', '_')
    const rowData = [];
    const fechacreacion = DateTime.now();
    
    gridRef.current.api.forEachNode(function (node) {
      rowData.push({...node.data, fechacreacion, usuario: madeByUser, tenantid: tenant, idlista: activeList});
    });

    await deleteData();
    
    const data = rowData
    const res = await fetchBackend( 'contacts', {data}, 'POST' )
    const body = await res.json();
    
    if( !body.ok ){
        Swal.fire('Oops', t('requestFailure'), 'error')
    } else {
        Swal.fire(t('success'), t('saveSuccess'), 'success')
        // const getNewContactList = await getContactsByList(activeList);
        // setInfoToShow(getNewContactList)
        const contactList = await getContactsByTenant(tenant)
        setAllTenantContacts(contactList)
    }
  }
  
  
  const saveContactsFromExcel = async() => {
    const tenant = user.email.replace('@', '_')
    const data = infoToShow
    
    const res = await fetchBackend( 'contacts', {data}, 'POST' )
    const body = await res.json();
    
    if( !body.ok ){
        Swal.fire('Oops', t('requestFailure'), 'error')
    } else {
        Swal.fire(t('success'), t('saveSuccess'), 'success')
    }

    // const savedContacts = await fetchBackend( `contacts/${activeList}`, 'GET' )
    // const body2 = await savedContacts.json()
    // setInfoToShow(body2.data)
    const contactList = await getContactsByTenant(tenant)
    setAllTenantContacts(contactList)
  }


  // Guardar los cambios realizados en lista de contactos, incluso BORRAR
  const handleSaveInfo = async() => {
    gridRef.current.api.stopEditing();
    await fieldsVerify();

    switch (loadedRowInformation) {
      case 'loadedFromExcel':
        await getRowData();
        await deleteData();
        await saveContactsFromExcel();
        setChangesApplied(false);
        setInfoToSave([]);
        break;

      case 'loadedFromClipboard':
        await getAndSaveRowData();
        break;
    
      default:
        await saveData();
        break;
    }
    
    // console.log(gridRef.current.api.applyTransaction)
    gridRef.current.api.refreshCells({
      force: true,
      columns: []
    })

    setChangesApplied(false)
    setInfoToSave([])
    setLoadedRowInformation('')
    setRowSelected(false)
  }


  // Botón CANCELAR devuelve la info tal y como estaba antes de haberlo modificado
  const handleCancelButton = async() => {
      setInfoToShow([])
      const resultado = await fetchBackend( 'getallcontacts', 'GET' )
      const body = await resultado.json();
      
      const infoArray = body.data;
      const existe = infoArray.some( item => item.idlista === activeList )

      if(existe){
        const infoContactos = infoArray.filter( info => info.idlista === activeList )
        setInfoToShow(infoContactos)
        setIsActive(infoContactos)
      } else {
        setInfoToShow(null)
    }

    setChangesApplied(false)
    setRowSelected(false)
  }


  const readUploadFile = (e) => {
    e.preventDefault();
    const tenant = user.email.replace('@', '_')
    setRowSelected(false)
    const file = e.target.files[0]

    // Validar que sea un archivo Excel
    if( file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
      Swal.fire(t('warning'), t('isnotExcelFile'), 'warning')
      return
    }

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async(e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          
          let dataFromFile = [];

          // No pisar los datos anteriores en la tabla
          if(infoToShow !== null){
            dataFromFile = [...infoToShow]
          }

          let keys = Array();
          for(let key in json['0']){
              keys.push(key);
          }

          // Construcción de datos a ser guardados
          for(let elem of json){
            if(elem[keys[5]] !== undefined && elem[keys[5]].length > globalEnv.MSG_MAX_LENGTH){
              Swal.fire(t('warning'), `${t('maxLengthCharReached')}: ${globalEnv.MSG_MAX_LENGTH}`, 'warning')
              await handleCancelButton()
              return
            }
            dataFromFile = [
              ...dataFromFile,
              {
                idcontacto: '#ID',
                numero: elem[keys[0]].toString() || '',
                nombre: elem[keys[1]] || '',
                apellido: elem[keys[2]] || '',
                empresa: elem[keys[3]] || '',
                cargo: elem[keys[4]] || '',
                idlista: activeList,
                // pais: elem[keys[5]],
                observaciones: elem[keys[5]] || '',
                usuario: madeByUser,
                tenantid: tenant,
                fechacreacion: DateTime.now().setZone(timeZone),
              }
            ]
          }
          
          setInfoToShow(dataFromFile)
          setLoadedRowInformation('loadedFromExcel')
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    
    // Input files reset
    document.querySelector('#upload').value = '';
    setChangesApplied(true);
  }


  const handleCellChange = async(params) => {
    const focusedCell = await params.api.getFocusedCell().rowIndex;
    const focusedData = await params.api.getSelectedRows()[0]
    const tenant = user.email.replace('@', '_')

    if(await focusedData.idcontacto === ''){
      focusedData.idcontacto = '#ID' + focusedCell;
    } else {
      focusedData.fechamodificacion = DateTime.now().setZone(timeZone);
    }
    if(!focusedData.idlista){
      focusedData.idlista = activeList
    }
    if(!focusedData.tenantid){
      focusedData.tenantid = tenant
    }
    if(!focusedData.usuario){
      focusedData.usuario = madeByUser
    }

    setInfoToSave(focusedData)
    const alreadyExists = infoToSave.some( infoContacto => infoContacto.idcontacto === focusedData.idcontacto )

    if(alreadyExists){
      const replicaInfoToSave = infoToSave.filter( infoContacto => infoContacto.idcontacto !== focusedData.idcontacto )
      setInfoToSave([
        ...replicaInfoToSave,
        focusedData
      ])
    } else {
      setInfoToSave([
        ...infoToSave,
        focusedData
      ])
    }

    setChangesApplied(true)
    
    gridRef.current.api.refreshCells({
      force: true,
      columns: []
    })
  }
  
  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  
  const onGridReady = useCallback((params) => {
    sizeToFit()
    autoSizeAll()
  }, [sizeToFit, autoSizeAll]);

  
  const processCellFromClipboard = useCallback((params) => {
    setLoadedRowInformation('loadedFromClipboard')

    gridRef.current.api.refreshCells({
      force: true,
      columns: []
    })

    return params.value
  }, []);


  
  const excelStyles = [
      {
        id: 'numberType',
        numberFormat: {
          format: '0',
        }
      }
  ];


  return (
    <div className='col-9' style={containerStyle}>
      <div style={{ height: '90%', display: 'flex', flexDirection: 'column' }}>
        <div className='d-flex justify-content-between'>
          <div className='input-group w-25 me-1'>
            <button
              id='addButton'
              className='btn btn-sm btn-lightblue w-75'
              onClick={addItems}
              disabled={ !activeList }
            >
              {t('add')}
            </button>
            <input
              className={`form-control centerContent w-25
                          ${inputCount.toString().length >= 2 ? 'ps-2 pe-0' : 'ps-3 pe-0'}`}
              type='number'
              id="cantidad"
              name='counter'
              value={ inputCount }
              onChange={ (e) => setInputCount(e.target.value) }
              disabled={ !activeList }
            />
          </div>

          <button
            className='btn btn-sm btn-darkblue-static w-25 me-1'
            onClick={onRemoveSelected}
            disabled={ !rowSelected }
          >
              {t('remove')}
          </button>
          <button
            className='btn btn-sm btn-mediumblue w-25 me-1'
            onClick={onRemoveAllContacts}
            disabled={ !activeList || !infoToShow }
          >
              {t('removeAll')}
          </button>
          <div className="btn-group w-25">
            <label
              htmlFor="upload"
              className={ 
                !activeList || changesApplied 
                ? 'btn btn-sm btn-outline-mediumblue d-flex align-items-center justify-content-center disabled' 
                : 'btn btn-sm btn-outline-mediumblue d-flex align-items-center justify-content-center' }
              >
                {t('import')}
            </label>
            <input
              type="file"
              name="upload"
              id="upload"
              onChange={readUploadFile}
              className='d-none'
              disabled={ !activeList }
            />
            <button
              className='btn btn-sm btn-outline-darkblue'
              onClick={onBtExport}
              disabled={ !activeList || !infoToShow }
            >
              {t('export')}
            </button>
          </div>
        </div>

        <div style={{ flexGrow: '1' }}>
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={infoToShow}
              columnDefs={columnDefs}
              onCellValueChanged={ handleCellChange }
              defaultColDef={defaultColDef}
              rowSelection={'multiple'}
              animateRows={true}
              excelStyles={excelStyles}
              enableRangeSelection={true}
              suppressClipboardPaste={false}
              suppressAggFuncInHeader={true}
              defaultExcelExportParams={defaultExcelExportParams}
              enableCellChangeFlash={true}
              suppressChangeDetection={false}
              onGridReady={onGridReady}
              processCellFromClipboard={processCellFromClipboard}
              onRowSelected={ () => setRowSelected(true) }
              stopEditingWhenCellsLoseFocus={true}
              pagination={true}
            ></AgGridReact>
          </div>
        </div>
      </div>
      
      <div className="d-flex justify-content-end align-items-center pt-3" onMouseEnter={ () => gridRef.current.api.stopEditing() }>
        {
          changesApplied ? (
            <div className="alert alert-darkblue mb-0 p-alert me-3" role="alert">
              {t('someChanges')}
            </div>
          ) : null
        }
        <button
          className='btn btn-outline-lightblue-white btn-sm'
          onClick={ handleSaveInfo }
          disabled={ !changesApplied }
        >
          {t('save')}
        </button>

        <button
          id='cancelButton'
          className='btn btn-91 btn-sm ms-1'
          onClick={ handleCancelButton }
          disabled={ !changesApplied }
        >
          {t('cancel')}
        </button>
      </div>

      <DeleteContactModal contactInfo={contactToRemove} />
    </div>
  );
};

export default ContactList;