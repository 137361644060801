import React, {useContext} from 'react';
import { ElementContext } from './ElementContext';
import { useTranslation } from 'react-i18next';
import '../styles/sidebar.css'

let videoUno;
let videoDos;
let videoTres;
let videoCuatro;

const Home = () => {

  const { toggleSidebar } = useContext(ElementContext);
  const { t, i18n } = useTranslation();


  if( i18n.resolvedLanguage === 'en' ){
    videoUno = 'https://www.youtube.com/embed/iW_UQWcKM9Q'
    videoDos = 'https://www.youtube.com/embed/jLafp2s1bhk'
    videoTres = 'https://www.youtube.com/embed/CVLo7Xl3paw'
    videoCuatro = 'https://www.youtube.com/embed/aDvGxj5z8Us'
  } else if( i18n.resolvedLanguage === 'es' ){
    videoUno = 'https://www.youtube.com/embed/9bqQwkqA3Sw'
    videoDos = 'https://www.youtube.com/embed/HTVCsKm9P6g'
    videoTres = 'https://www.youtube.com/embed/tWgcqdHgp4I'
    videoCuatro = 'https://www.youtube.com/embed/O7Gfeg_OqxI'
  }


  return (
    <div className="home animate__animated animate__fadeIn">
      <div className={ toggleSidebar ? 'titles-margin-close' : 'titles-margin-open' }>
          <div className="text">
              {t('welcomeHbg')}
          </div>
          <h5 className='text-lightblue mb-4'>{t('harbingerNextSteps')}</h5>
          
          <div className="row d-flex pb-5 w-100">
            <div className='col-6'>
              <h6 className='text-mediumblue mt-4'>{t('howToCreateLists')}</h6>
              <iframe
                className='mt-2 tutorials-iframe'
                width="100%" height="315"
                src={ videoUno }
                title={t('howToCreateListsTitle')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </div>
            
            <div className='col-6'>
              <h6 className='text-mediumblue mt-4'>{t('howToSendAndSchedule')}</h6>
              <iframe
                className='mt-2 tutorials-iframe'
                width="100%" height="315"
                src={ videoDos }
                title={t('howToSendAndScheduleTitle')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </div>

            <div className='col-6'>
              <h6 className='text-mediumblue mt-4'>{t('howToCreateTemplates')}</h6>
              <iframe
                className='mt-2 tutorials-iframe'
                width="100%" height="315"
                src={ videoTres }
                title={t('howToCreateTemplatesTitle')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </div>
            
            <div className='col-6'>
              <h6 className='text-mediumblue mt-4'>{t('howToSendSMS')}</h6>
              <iframe
                className='mt-2 tutorials-iframe'
                width="100%" height="315"
                src={ videoCuatro }
                title={t('howToSendSMSTitle')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </div>
          </div>

      </div>
    </div>
  )
};

export default Home;
