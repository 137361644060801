import React, { useMemo, useRef, useState, useContext } from 'react';
import {ElementContext} from './ElementContext'
import { AgGridReact } from 'ag-grid-react';
import cronstrue from 'cronstrue/i18n'
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ScheduleCompleted from './ScheduleCompleted';
import ActionButtonRenderer from './ActionButtonRenderer';
import {DateTime} from 'luxon';
import { useTranslation } from 'react-i18next';


const timezone = DateTime.now().zoneName;

// Datetime renderer
class dateTimeFormater {
  init(params) {
      const element = document.createElement('span');

      if(params.value){
        const hourConvertion = DateTime.fromISO(params.value).setZone(timezone).toFormat('D tt');
        element.innerText = hourConvertion
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}


class scheduleState {
  init(params) {
      const element = document.createElement('div');
      const stateElement = document.createElement('span');
      const iconElement = document.createElement('i');

      if(params.value.state === 'false'){
        iconElement.className = 'bx bxs-check-circle text-success icon-size'
        stateElement.innerHTML = `<span class="bg-lightblue text-white px-1">
                                    ${params.value.language === 'en' ? 'Finished' : 'Finalizado'}
                                  </span>`
        
        element.className = 'text-center d-flex justify-content-center align-items-center'
        element.appendChild(iconElement);
        element.appendChild(stateElement);

        this.eGui = element;
      } else if (params.value.state === 'true') {
        iconElement.className = 'bx bxs-alarm text-warning icon-size'
        stateElement.innerHTML = `<span class="bg-lightblue text-white px-1">
                                    ${params.value.language === 'en' ? 'Scheduled' : 'Programado'}
                                  </span>`
        
        element.className = 'text-center d-flex justify-content-center align-items-center'
        element.appendChild(iconElement);
        element.appendChild(stateElement);
        this.eGui = element;
      } else if (params.value.state === 'canceled'){
        iconElement.className = 'bx bx-x-circle text-danger icon-size'
        stateElement.innerHTML = `<span class="bg-lightblue text-white px-1">
                                    ${params.value.language === 'en' ? 'Canceled' : 'Cancelado'}
                                  </span>`
        
        element.className = 'text-center d-flex justify-content-center align-items-center'
        element.appendChild(iconElement);
        element.appendChild(stateElement);
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}


class convertSchedule {
  init(params) {
      const element = document.createElement('div');
      const cronExpression = document.createElement('span');


      if(params.value.tiempoEstablecido){
        let cronWithoutSpace = params.value.tiempoEstablecido.split(' ');

        // // Conversión a zona horaria local
        const localDateTimeOffset = DateTime.now().setZone(timezone).offset / 60;

        const getHourCron = parseInt(cronWithoutSpace[1]);
        let convertedHour = (getHourCron + localDateTimeOffset);

        if(convertedHour < 0){
          // Conversión de la hora, por desfazaje de la zona horaria
          convertedHour = convertedHour + 24;
          // Conversión del día, por desfazaje de zona horaria
          cronWithoutSpace[2] = (parseInt(cronWithoutSpace[2]) - 1).toString();
        }

        convertedHour = convertedHour.toString();
        cronWithoutSpace[1] = convertedHour;
        const convertedCron = cronWithoutSpace.join(' ');

        cronExpression.innerText = cronstrue.toString(convertedCron, {locale: params.value.language})
        element.appendChild(cronExpression);

        this.eGui = element;
      } else {

        element.className = 'text-center'
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}



const ScheduleDone = () => {

  const { scheduleToShow } = useContext(ElementContext);
  
  const { t, i18n } = useTranslation();

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ height: '75%' }), []);
  const gridStyle = useMemo(() => ({ height: '75vh', marginTop: '5px', marginRight: '3px' }), []);
  const [columnDefs] = useState([
    {
      headerName: t('action'),
      valueGetter: (params) => {
        const state = params.data.estadoschedule
        const name = params.data.nombreschedule
        return {state, name}
      },
      minWidth: 120,
      cellRenderer: ActionButtonRenderer
    },
    {
      headerName: t('status'),
      field: 'estadoschedule',
      minWidth: 170,
      cellRenderer: scheduleState,
      valueGetter: (params) => {
        const state = params.data.estadoschedule
        const language = i18n.resolvedLanguage
        return {state, language}
      }
    },
    { headerName: t('taskNumber'), field: 'nombreschedule', minWidth: 120, resizable: true },
    { 
      headerName: t('frequency'),
      field: 'tiempoestablecido',
      minWidth: 340,
      resizable: true,
      cellRenderer: convertSchedule,
      valueGetter: (params) => {
        const tiempoEstablecido = params.data.tiempoestablecido
        const language = i18n.resolvedLanguage
        return {tiempoEstablecido, language}
      }

    },
    { headerName: t('scheduledOn'), field: 'fecha', minWidth: 180, sort: 'desc', cellRenderer: dateTimeFormater },
  ]);


  const defaultColDef = useMemo(() => {
    return {
      flex: 1, sortable: true, filter: true
    };
  }, []);


  return (
    <div className="home row">
        <div className='col-6 pe-0' style={containerStyle}>

          <h2 className="text-mediumblue text-center mt-4 mb-0">
            {t('schedulesTitle')}
          </h2>
          <p className='text-center subtitle mb-4'>
            {t('schedulesSubtitle')}
          </p>

          <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexGrow: '1' }}>
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                  ref={gridRef}
                  rowData={scheduleToShow}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // rowSelection={'multiple'}
                  animateRows={true}
                  enableRangeSelection={false}
                  suppressClipboardPaste={false}
                  suppressAggFuncInHeader={true}
                  enableCellChangeFlash={true}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>

        <ScheduleCompleted/>
    </div>
  );
};

export default ScheduleDone;