import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { deleteAllContactsFromList, deleteContactById, getContactsByList } from '../../helpers/contactos';


Modal.setAppElement('#root');


const DeleteContactModal = ({contactInfo}) => {

    const { activeList, confirmDeleteContactModal, setConfirmDeleteContactModal, setInfoToShow, setAllTenantContacts, allTenantContacts } = useContext(ElementContext);
    const { t } = useTranslation();

    const closeModal = () => {
        setConfirmDeleteContactModal(false)
    }

    const handleConfirm = async() => {
        if(contactInfo.idcontacto){
            await deleteContactById(contactInfo);
            
            const getNewContactList = await getContactsByList(activeList);
            setInfoToShow(getNewContactList)
            const filteredAllTenantContacts = await allTenantContacts.filter( contact => parseInt(contact.idcontacto) !== parseInt(contactInfo.idcontacto) )
            setAllTenantContacts(filteredAllTenantContacts)
        } else if(contactInfo.idlistaactiva){
            await deleteAllContactsFromList(activeList);
            
            setInfoToShow([])
            const filteredAllTenantContacts = await allTenantContacts.filter( contact => parseInt(contact.idlista) !== parseInt(activeList) )
            setAllTenantContacts(filteredAllTenantContacts)
        }
        
        closeModal();
    }


  return (
        <Modal
            isOpen={confirmDeleteContactModal}
            onRequestClose={closeModal}
            closeTimeoutMS={ 200 }
            className="modal-deletecontact p-5"
            overlayClassName="modal-fondo"
        >
            {
                contactInfo.idcontacto && (
                    <>
                        <h5 className='text-center'>{t('confirmRemoveList1')} <span className='text-lightblue'>{contactInfo.nombre}</span> {t('confirmRemoveList2')}</h5>
                        <h4 className='text-center'>{t('areYouSure')}</h4>
                    </>
                )
            }
            
            {
                contactInfo.idlistaactiva && (
                    <>
                        <h5 className='text-center'>{t('confirmRemoveAllContacts')} <span className='text-lightblue'>{contactInfo.nombrelista}</span></h5>
                        <h4 className='text-center'>{t('areYouSure')}</h4>
                    </>
                )
            }

            <div className='d-flex justify-content-center'>
                <button
                    className='btn btn-sm btn-mediumblue mt-3 me-2'
                    onClick={ handleConfirm }
                >
                    {t('remove')}
                </button>

                <button
                    className='btn btn-sm btn-91 mt-3'
                    onClick={ closeModal }
                >
                    {t('cancel')}
                </button>
            </div>
            
        </Modal>
  )
}

export default DeleteContactModal