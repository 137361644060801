import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './i18n';

ReactDOM.render (
  <React.Suspense fallback="loading">
    <BrowserRouter>
        <App/>
        <Routes>
          <Route exact path='/account/sign-up' element={ <Navigate to='/redirect' replace/> }/>
          <Route path='*' element={null} />
        </Routes>
    </BrowserRouter>
  </React.Suspense>,
  document.getElementById('root')
);