import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');


const SessionClosedModal = () => {

    const { sessionClosedModal } = useContext(ElementContext);
    const { t } = useTranslation();
    const [closeRequested, setCloseRequested] = useState(false)
    
    const handleCloseModal = async() => {
        setCloseRequested(true)
        window.location.replace('/formulario-whatsapp')
    }

  return (
        <Modal
            isOpen={ sessionClosedModal }
            className="modal-newgroup p-4"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center text-mediumblue mb-4'>{ t('warning') }</h4>
            <p className='text-center'>
                <small>
                    { t('loginSessionAgain') }.
                </small>
            </p>

            <div className='d-flex justify-content-center align-items-center mt-4'>
                <button
                    className='btn btn-sm btn-lightblue'
                    onClick={ handleCloseModal }
                    disabled={ closeRequested }
                >
                    {
                        closeRequested ? (
                            <div className='d-flex align-items-center justify-content-center'>
                                <span className='text-light'>{t('processing')}...</span>
                                <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                    <span className="visually-hidden">...</span>
                                </div>
                            </div>
                        ) : t('close')
                    }
                </button>
            </div>
        </Modal>
  )
}

export default SessionClosedModal