import * as React from 'react';
import { ElementContext } from '../ElementContext';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { fetchBackend, fetchPythonBackend } from '../../helpers/fetch';
import SlateTextEditorEmpty from '../SlateTextEditorEmpty';
import { getContactsByTenant } from '../../helpers/contactos';
import { getConvertedMessages, getRecipientsPhone } from '../../helpers/integrations';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';
import Swal from 'sweetalert2';
import Lottie from 'react-lottie';
import clientsAnimation from '../../img/lottie/clients.json';
import employeesAnimation from '../../img/lottie/employees.json';
import leadsAnimation from '../../img/lottie/leads.json';
import suppliersAnimation from '../../img/lottie/suppliers.json';
import SelectListModal from '../modals/SelectListModal';
import TemplateModal from '../modals/TemplateModal';
import BannedPhoneModal from '../modals/BannedPhoneModal';
import BannedTenantModal from '../modals/BannedTenantModal';
import SessionClosedModal from '../modals/SessionClosedModal';
import ScheduleWhatsAppMessages from '../modals/ScheduleWhatsAppMessages';
import SlateTextEditorWithTemplate from '../SlateTextEditorWithTemplate';
import ExtractionSchedule from '../ui/ExtractionSchedule';
import '../../styles/stepper.css'


const clientsLottieOptions = {
    mode: 'normal', // Opcional: define el modo de animación, puede ser normal, invertido o bucle invertido.
    background: 'transparent', // Opcional: define el color de fondo, puede ser transparente.
    direction: 1, // Opcional: 1 para adelante, -1 para atrás
    speed: 1, // Opcional: define la velocidad de la animación. Los valores predeterminados son 1.
    loop: true, // Opcional: Si deseas que la animación se reproduzca en bucle.
    autoplay: true, // Opcional: Si deseas que la animación comience a reproducirse automáticamente.
    animationData: clientsAnimation, // El archivo JSON de Lottie.
};

const employeesLottieOptions = {
    mode: 'normal', // Opcional: define el modo de animación, puede ser normal, invertido o bucle invertido.
    background: 'transparent', // Opcional: define el color de fondo, puede ser transparente.
    direction: 1, // Opcional: 1 para adelante, -1 para atrás
    speed: 1, // Opcional: define la velocidad de la animación. Los valores predeterminados son 1.
    loop: true, // Opcional: Si deseas que la animación se reproduzca en bucle.
    autoplay: true, // Opcional: Si deseas que la animación comience a reproducirse automáticamente.
    animationData: employeesAnimation, // El archivo JSON de Lottie.
};

const leadsLottieOptions = {
    mode: 'normal', // Opcional: define el modo de animación, puede ser normal, invertido o bucle invertido.
    background: 'transparent', // Opcional: define el color de fondo, puede ser transparente.
    direction: 1, // Opcional: 1 para adelante, -1 para atrás
    speed: 1, // Opcional: define la velocidad de la animación. Los valores predeterminados son 1.
    loop: true, // Opcional: Si deseas que la animación se reproduzca en bucle.
    autoplay: true, // Opcional: Si deseas que la animación comience a reproducirse automáticamente.
    animationData: leadsAnimation, // El archivo JSON de Lottie.
};

const suppliersLottieOptions = {
    mode: 'normal', // Opcional: define el modo de animación, puede ser normal, invertido o bucle invertido.
    background: 'transparent', // Opcional: define el color de fondo, puede ser transparente.
    direction: 1, // Opcional: 1 para adelante, -1 para atrás
    speed: 1, // Opcional: define la velocidad de la animación. Los valores predeterminados son 1.
    loop: true, // Opcional: Si deseas que la animación se reproduzca en bucle.
    autoplay: true, // Opcional: Si deseas que la animación comience a reproducirse automáticamente.
    animationData: suppliersAnimation, // El archivo JSON de Lottie.
};


const extractionInit = {
    type: 'C',
    associatedFields: {
        phone: null, name: null, lastname: null, company: null, position: null, observations: null
    },
    listName: null,
    listId: null,
    scheduledExtraction: false,
    sendMessage: false,
    message: null,
    createdDate: new Date()
}


export default function HorizontalLinearStepper() {

    
        const { setNewExtractionModalOpen, listAssingment, setListAssingment, extractionData, setExtractionData, madeByUser, nombreEmisor, numeroEmisor, delayMsg, oscillationMsg, setListNames, setAllTenantContacts, activePlatformCredentials, extractionsByIntegrationId, setExtractionsByIntegrationId, message, setTemplateModal, messageWithTemplate, setMessageWithTemplate} = React.useContext   (ElementContext)

        const { t } = useTranslation();
        const { user } = useAuth();
        const [buttonSelected, setButtonSelected] = React.useState('')
        const [loading, setLoading] = React.useState(false)
        const [activeStep, setActiveStep] = React.useState(0);
        const [scheduleActivate, setScheduleActivate] = React.useState(true)

        const selectInfoStep = t("selectInformationEntity");
        const defineFieldsStep = t("defineFieldsToExtract");
        const createListStep = t("createList");
        const sendMessageStep = t("sendMessage");
 
        const steps = [
            selectInfoStep,
            defineFieldsStep,
            createListStep,
            sendMessageStep
        ];

        const handleChangeRadio = (activateRadio) => {
        setScheduleActivate(activateRadio)
    }


    React.useEffect(() => {
        setExtractionData({ ...extractionData, message })
    }, [message])
    

    const handleNext = () => { if(activeStep < 3){ setActiveStep((prevActiveStep) => prevActiveStep + 1); } };
    const handleBack = () => { if(activeStep > 0){ setActiveStep((prevActiveStep) => prevActiveStep - 1); } };

    const closeModal = () => {
        setActiveStep(0)
        setExtractionData(extractionInit)
        setNewExtractionModalOpen(false)
    }

    const handleSubmitForm = async(e) => {
        e.preventDefault()
        setLoading(true)
        const tenantId = user.email.replace('@', '_')
        let listId = null;

        try {
        // Extracción SAP B1
        const integrationId = await activePlatformCredentials.idintegracion
        const sapBody = { extractionData, listAssingment, tenantId, listId, activePlatformCredentials: await activePlatformCredentials.credenciales, integrationId }
        const sapDataRequest = await fetchPythonBackend( 'api/extractions/sap-b1', sapBody, 'POST' );
        const sapDataResponse = await sapDataRequest.json();
        let saveContactResp;
        let infoContactos;
        const contactsToSave = []
        
        if(sapDataResponse.ok){
            // Crear y guardar nueva lista
            const fechacreacion = new Date()
            const nuevaLista = extractionData.listName
            const origen = 'sapb1'
            const saveList = await fetchBackend( 'lists', {nuevaLista, madeByUser, tenantId, fechacreacion, origen}, 'POST' );
            const listResponse = await saveList.json();
            listId = await listResponse.insertedList.idlista
            
            if(listResponse.ok){
                // Obtener nuevo listado de listas / USO DE SETSTATE
                const getListas = await fetchBackend(`lists/${tenantId}`);
                const resListas = await getListas.json();
                const filterCommonLists = await resListas.data.filter(lista => lista.tipodelista === 'common')
                setListNames(filterCommonLists)
            }
            // Guardar el id de la lista creada en la tabla de extracciones
            const updateExtBody = { tenantId, listId }
            const updateExtRequest = await fetchPythonBackend( 'api/extractions/save-listid', updateExtBody, 'PUT' );
            const updateExtResponse = await updateExtRequest.json();

            // Guardar contactos en la nueva lista creada
            for( let contact of sapDataResponse.usersData ){
                let dataToAdd = {
                    nombre: contact[extractionData.associatedFields.name],
                    numero: contact[extractionData.associatedFields.phone],
                    apellido: contact[extractionData.associatedFields.lastname],
                    empresa: contact[extractionData.associatedFields.company],
                    cargo: contact[extractionData.associatedFields.position],
                    observaciones: contact[extractionData.associatedFields.observations],
                    customfields: {
                        deuda: contact[extractionData.associatedFields.deuda],
                        fechavencimiento: contact[extractionData.associatedFields.fechavencimiento],
                    },
                    idlista: listId,
                    usuario: await user.email,
                    tenantid: tenantId,
                    fechacreacion: new Date()
                }

                contactsToSave.push(dataToAdd)
            }

            const saveContactsReq = await fetchBackend( 'contacts', {data: contactsToSave}, 'POST' )
            saveContactResp = await saveContactsReq.json();
            if(saveContactResp.ok){
                // Obtener nueva lista de todos los contactos
                const contactList = await getContactsByTenant(tenantId)
                infoContactos = contactList.filter( info => parseInt(info.idlista) === parseInt(listId) )
                setAllTenantContacts(contactList)
                
                setExtractionsByIntegrationId([ ...extractionsByIntegrationId, extractionData ])
            }
        } else {
            return Swal.fire(t('error'), (t("informationNotExtracted")), 'error')
        }
  
        if(saveContactResp.ok && extractionData.sendMessage){
            const arrayEstados = []
            const numbers = await getRecipientsPhone(contactsToSave)
            numbers.forEach( number => arrayEstados.push(true) )

            const convertedMessages = await getConvertedMessages(message, infoContactos, numbers)
            const data = {
                datosEmisor: {nombreEmisor, numeroEmisor},
                datosDestinatarios: infoContactos,
                message,
                messages: convertedMessages, //Array con msgs reemplazados
                arrayEstados,
                numbers, //Array con los numeros destinatarios
                usuario: await user.email,
                tenantid: tenantId,
                time: parseInt(delayMsg),
                oscillationMsg,
                canal: 'WhatsApp',
                filesName: []
            }
  
            const sendMessageReq = await fetchBackend( 'send-whatsapp', data, 'POST' );
            const sendMessageResp = await sendMessageReq.json();
            if(sendMessageResp.ok){
                closeModal()
            }
        }
        closeModal()
        setLoading(false)

      } catch (error) {
        console.log(error.message)
        Swal.fire(t('error'), (t("requestNotCompleted")), 'error')
        closeModal()
        setLoading(false)
      }
  }

  const handleTableFieldChange = (e) => {
      const { name, value } = e.target
      let listAssingmentChange = listAssingment;

      if(listAssingment.includes(value)){
          // console.log(listAssingmentChange)
      } else {
          listAssingmentChange.push(value)
      }
      
      let fieldsChange = extractionData.associatedFields
      fieldsChange[name] = value

      setListAssingment(listAssingmentChange)
      setExtractionData({ ...extractionData, associatedFields: fieldsChange, createdDate: new Date() })
  }


    const openTemplateModal = (e) => {
        e.preventDefault();

        setMessageWithTemplate('')
        setButtonSelected(e.target.value)
        setTemplateModal(true)
}



  return (
    <Box sx={{ width: '100%', height: '100%' }} className='d-flex flex-column justify-content-between'>
      <Stepper activeStep={activeStep} className='mb-3'>
        {
          steps.map( (label, index) => (
            <Step key={label} className={ (activeStep === index) ? 'step-active' : '' }>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))
        }
      </Stepper>
      
      <>
        <div className={ activeStep === 3 ? 'd-none' : 'no-overflow'}>
            <div className={ activeStep === 0 ? 'container ext-config-page animate__animated animate__fadeInLeft' : 'd-none' }>
                <div className="row d-flex justify-content-center align-items-center pt-3">
                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                        <div className={ extractionData.type === 'C' ? 'wizard-mini-card-icon wizard-card-bordered' : 'wizard-mini-card-icon'} onClick={ () => setExtractionData({ ...extractionData, type: 'C' }) }>
                            <Lottie style={{ width: '150px', height: '150px' }} options={clientsLottieOptions} />
                            <span>{t("customers")}</span>
                        </div>
                    </div>
                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                        <div className={ extractionData.type === 'L' ? 'wizard-mini-card-icon wizard-card-bordered' : 'wizard-mini-card-icon'} onClick={ () => setExtractionData({ ...extractionData, type: 'L' }) }>
                            <Lottie style={{ width: '150px', height: '150px' }} options={leadsLottieOptions} />
                            <span>{t("prospects")}</span>
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center align-items-center mt-1 pb-3">
                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                        <div className={ extractionData.type === 'E' ? 'wizard-mini-card-icon wizard-card-bordered' : 'wizard-mini-card-icon'} onClick={ () => setExtractionData({ ...extractionData, type: 'E' }) }>
                            <Lottie style={{ width: '150px', height: '150px' }} options={employeesLottieOptions} />
                            <span>{t("employees")}</span>
                        </div>
                    </div>
                    <div className="col-3 d-flex flex-column justify-content-center align-items-center">
                        <div className={ extractionData.type === 'S' ? 'wizard-mini-card-icon wizard-card-bordered' : 'wizard-mini-card-icon'} onClick={ () => setExtractionData({ ...extractionData, type: 'S' }) }>
                            <Lottie style={{ width: '150px', height: '150px' }} options={suppliersLottieOptions} />
                            <span>{t("suppliers")}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={ activeStep === 1 ? 'ext-config-page animate__animated animate__fadeInRight' : 'd-none' }>
                <div className="mb-4 d-flex justify-content-center align-items-center">
                    <label  id="text-form-extrat" htmlFor="extractionListName" className="form-label labels-size">
                        {t("whatFieldsExtract")}
                    </label>
                </div>

                <div className='row select-table-fields'>
                    <div className="col-4 second-column">
                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("phone")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='phone'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>
                        
                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("name")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='name'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>
                        
                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("lastName")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='lastname'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>
                        
                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("company")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='company'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>
                        
                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("position")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='position'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>
                        
                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("observations")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='observations'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>

                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("debt")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='deuda'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>

                        <div>
                            <label id="text-form-data" htmlFor="" className='form-label m-0'>{t("dueDate")}:</label>
                            <select
                                className="form-select form-select-sm"
                                aria-label="form-select-sm example"
                                name='fechavencimiento'
                                onChange={ handleTableFieldChange }
                            >
                                {
                                    listAssingment.map( (option) => {
                                        return <option key={option} value={option}>{ option }</option>
                                    } )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={ activeStep === 2 ? 'ext-config-page animate__animated animate__fadeInRight' : 'd-none' }>
                <div className='d-flex flex-column align-items-center'>
                    <div>
                        <label id="text-form-extrat" htmlFor="extractionListName" className="form-label labels-size mb-2">
                            {t("createdContactList")}
                        </label>
                        <input
                            autoComplete='off'
                            id='extractionListName'
                            disabled={loading}
                            type="text"
                            className="form-control"
                            placeholder={t("enterListName")}
                            onChange={ (e) => setExtractionData({ ...extractionData, listName: e.target.value }) }
                        />
                    </div>

                        <div className="mt-3 extraction-radio">
                            <label id="text-form-extrat" className="form-label labels-size mb-2">
                                {t("extractInformationNow")}
                            </label>


                            <div className="form-check p-0 form-switch d-flex justify-content-between align-items-center">
                                <div className="box-toogle">
                                        <p id={!scheduleActivate ? 'text-toogle-no-checked' : 'text-toogle-no'}>{t("no")}</p>
                                        <div className="switch-toogle-icon">
                                            <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            checked={scheduleActivate}
                                            onChange={() => handleChangeRadio(!scheduleActivate)}
                                            />
                                        </div>
                                        <p id={scheduleActivate ? 'text-toogle-si-checked' : 'text-toogle-si'}>{t("yes")}</p>
                                </div>

                            </div>

                        </div>
                    
                        <div className = 'box-extraction-select'>
                            {   
                            <ExtractionSchedule scheduleActivate={scheduleActivate}/>
                            }  
                        </div>
                </div>

                
            </div>
        </div>
        
        <div className={ activeStep === 3 ? 'scroll-y-overflow' : 'd-none'}>
            <div className='ext-config-page animate__animated animate__fadeInRight'>
                <div className='optional-page'>
                    <div className='optional-config-page'>
                        <label id="text-form-extrat-step4" htmlFor="extractionListName" className="form-label labels-size mb-2">
                            {t("sendMessageNow")}
                        </label>
                        
                        <div id="box-toogle-content">
                            <label id={!extractionData.sendMessage ? 'no-later-checked' : 'no-later'} htmlFor="notSendMsgNow" className="form-check-label labels-size">
                                {t("noLater")}
                            </label>
                            <div id="box-switch" className="form-check form-switch">
                                <input className="form-check-input "
                                type="checkbox" 
                                role="switch" 
                                id="toggleSendMessage" 
                                checked={extractionData.sendMessage}
                                onChange={() => setExtractionData({ ...extractionData, sendMessage: !extractionData.sendMessage })}
                                />
                            </div>
                            <label id={extractionData.sendMessage ? 'yes-now-checked' : 'yes-now'} htmlFor="yesSendMsgNow" className="form-check-label labels-size">
                                {t("yesNow")}
                            </label>
                        </div>
                    </div>
                    <div className="box-template d-flex align-items-start ">
                        <div className={ extractionData.sendMessage ? 'ext-config-richtext' : 'ext-config-richtext richtext-disabled' }>
                            {
                                messageWithTemplate ? <SlateTextEditorWithTemplate /> : <SlateTextEditorEmpty />
                            }
                        </div>

                        <button id="form-button" 
                            className="btn-sm btn-lightblue me-2 w-15"
                            type="submit"
                            onClick={ openTemplateModal }
                            value="opentemplate">
                            {t("openTemplate")}
                        </button>
                    </div>

                </div>
            </div>
        </div>
        </>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} className='mt-3'>
                        <button
                            className='btn btn-sm btn-lightgray me-4'
                            disabled={ loading }
                            onClick={closeModal}
                        >
                        {t("cancel")}
                        </button>

                        <div className='btn-group stepper-btn-group'>
                            <button
                                className='btn btn-sm btn-lightblue '
                                disabled={ loading || (activeStep === 0)}
                                onClick={handleBack}
                            >
                                {t("back")}
                            </button>
                            {
                            activeStep === steps.length - 1
                            ? <button
                                className='btn btn-sm btn-mediumblue'
                                disabled={ loading }
                                onClick={handleSubmitForm}
                            >
                                {t("finish")}
                            </button>
                            : <button
                                className='btn btn-sm btn-mediumblue'
                                disabled={ loading }
                                onClick={handleNext}
                            >
                                {t("next")}
                            </button>
                            }
                        </div>
            </Box>
        <SelectListModal/>
        <SessionClosedModal/>
        <ScheduleWhatsAppMessages/>
        <TemplateModal buttonSelected={buttonSelected} message={message} type={'standard'} />
        <BannedPhoneModal />
        <BannedTenantModal />
    </Box>
    );
}