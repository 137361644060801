import SapB1Logo from '../../img/sapb1.png'
import S4Hana from '../../img/s4hana.png'
import HubSpot from '../../img/hubspot.png'
import databases from '../../img/database1.png'
import apirest from '../../img/apirest1.png'


export const platformsLogos = {
    'sapb1': SapB1Logo,
    's4hana': S4Hana,
    'hubspot': HubSpot,
    'databases':databases,
    'apirest':apirest
}

export const entitiesType = {
    'C': 'Clients',
    'S': 'Suppliers',
    'E': 'Employees',
    'L': 'Leads',
}