import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import { useTranslation } from 'react-i18next';
import { fetchBackend } from '../../helpers/fetch';

const SessionsTable = () => {
    
    const { QRSolicitado, setQRSolicitado, setMultisessionModal, tenantId, multiSessionUsuarioEmisor, setMultiSessionUsuarioEmisor, setIsMultiSessionAuthenticated, setRequestedQRNumber, gettingSessions } = useContext(ElementContext);
    const { t } = useTranslation();
    const [deleteMultisessionRequested, setDeleteMultisessionRequested] = useState({
        sessionNumber: 0, requested: false
    })
    
    const handleRemoveSession = async(num) => {
        setDeleteMultisessionRequested({
            sessionNumber: num, requested: true
        })
        const resultado = await fetchBackend( `eliminar-sesion/${tenantId}${num}`, {}, 'DELETE' )
        const body = await resultado.json();
        if(body.ok){
            const filtereduserList = multiSessionUsuarioEmisor.filter( user => user.ordenUsuario !== num )

            setQRSolicitado(false)
            setMultiSessionUsuarioEmisor(filtereduserList)
            setDeleteMultisessionRequested({
                sessionNumber: 0, requested: false
            })
        }
    }
    
    const handleGetQR = async(number) => {
        setRequestedQRNumber(number)
        setQRSolicitado(true)
        const checkIfSessionExists = await fetchBackend(`check-session/${tenantId}${number}`)
        const checkResp = await checkIfSessionExists.json()

        if(checkResp.sessionExists && checkResp.info){
            const {pushname, wid} = await checkResp.info;
            setMultiSessionUsuarioEmisor([...multiSessionUsuarioEmisor, {
                nombreEmisor: pushname,
                numeroEmisor: wid.user,
                ordenUsuario: number,
                tenantSession: tenantId + number
            }])
            
            setQRSolicitado(false)
            setRequestedQRNumber(false)
            setIsMultiSessionAuthenticated(true)
        } else {
            setMultisessionModal(true)
            await fetchBackend(`qr-request/${tenantId}${number}`);
        }
    }

    const getUser = (num) => {
        const user = multiSessionUsuarioEmisor.filter( user => user.ordenUsuario === num )
        const userName = user[0].nombreEmisor;
        const userPhone = user[0].numeroEmisor;

        return { userName, userPhone }
    }
    


  return (
    <div className='container-fluid'>     
        <div className="row">
            <div className='col-6 d-flex justify-content-center align-items-center'>
                <table className='table table-sm'>
                    <tbody>
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 0)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(0).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(0).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(0) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 0 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(0) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 1)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(1).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(1).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(1) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 1 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(1) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 2)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(2).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(2).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(2) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 2 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(2) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 3)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(3).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(3).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(3) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 3 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(3) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 4)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(4).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(4).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(4) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 4 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(4) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div className='col-6 d-flex justify-content-center align-items-center'>
                <table className='table table-sm'>
                    <tbody>
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 5)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(5).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(5).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(5) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 5 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(5) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 6)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(6).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(6).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(6) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 6 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(6) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 7)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(7).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(7).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(7) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 7 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(7) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 8)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(8).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(8).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(8) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 8 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(8) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                        
                        <tr>
                            <td className='d-flex justify-content-between align-items-center'>
                                {
                                    (multiSessionUsuarioEmisor.some( user => user.ordenUsuario === 9)) ? (
                                        <div className='animate__animated animate__fadeInUp d-flex justify-content-between align-items-center w-100'>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxs-user text-mediumblue me-1'></i>
                                                    <small className='session-info'>
                                                        {getUser(9).userName}
                                                    </small>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='bx bxl-whatsapp text-mediumblue me-1' ></i>
                                                    <small className='session-info'>
                                                        {getUser(9).userPhone}
                                                    </small>
                                                </div>
                                            </div>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-lightblue"
                                                onClick={ () => handleRemoveSession(9) }
                                                disabled={ deleteMultisessionRequested.requested }
                                            >
                                                
                                                {
                                                    (deleteMultisessionRequested.sessionNumber === 9 && deleteMultisessionRequested.requested) ? (
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                          <span className='text-light'>{t('processing')}...</span>
                                                          <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                                              <span className="visually-hidden">Loading...</span>
                                                          </div>
                                                        </div>
                                                    ) : t('disconnect')
                                                }
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <small className='text-91'>{ t('clickGetSession') }</small>
                                            
                                            <button
                                                className="btn btn-sm badge rounded-pill bg-mediumblue"
                                                onClick={ () => handleGetQR(9) }
                                                disabled={ QRSolicitado || gettingSessions || deleteMultisessionRequested.requested }
                                            >
                                                { t('getSession') }
                                            </button>
                                        </>
                                    )
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default SessionsTable