import React, { useContext } from 'react';
import { ElementContext } from './ElementContext';
import { useTranslation } from 'react-i18next';


const SelectListButton = () => {

  const { modalIsOpen, setModalIsOpen, infoToShow, setInfoToShow, setInputList } = useContext(ElementContext);
  const { t } = useTranslation();

  const handleOpenSelectListModal = () => {
    if( infoToShow.length > 0 ){
      setInfoToShow([])
    } else {
      setInputList([
        { numero: '' }
      ])
    }

    setModalIsOpen(!modalIsOpen)
  }
  
  return (
    <button
        className="btn btn-darkblue-to-outline btn-sm"
        onClick={ handleOpenSelectListModal }
    >
        {t('selectList')}
    </button>
  )
}

export default SelectListButton