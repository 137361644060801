import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';
import {fetchBackend} from '../../helpers/fetch'


Modal.setAppElement('#root');


const CloudFileInformation = () => {
    
    const { user } = useAuth();
    const { setShowCloudFileModalOpen, showCloudFileModalOpen, cloudFileInformation, setCloudConnectionsList } = useContext(ElementContext)
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)

    const closeModal = (e) => {
        e.preventDefault()
        setShowCloudFileModalOpen(false)
    }

    const handleDelete = async(e) => {
        e.preventDefault()
        setIsLoading(true)
        const idList = await cloudFileInformation.idlist
        const deleteFileListRequest = await fetchBackend(`lists/${idList}`, {}, 'DELETE')
        const deleteFileListResponse = await deleteFileListRequest.json()

        if(deleteFileListResponse.ok){
            const tenant = await user.email.replace('@', '_')
            const listasRequest = await fetchBackend(`lists/${tenant}`);
            const listasResponse = await listasRequest.json();
            const fileLists = await listasResponse.data.filter( list => list.tipodelista === 'file' )
            const fileListData = await fileLists.map(fileList => {
                const fileCloud = JSON.parse(fileList.configuracion)
                fileCloud.idlist = fileList.idlista
                return fileCloud
            })

            for(let fileInfo in fileListData){
                const fileUrl = await fileListData[fileInfo].fileUrl
                const fileInfoRequest = await fetchBackend('cloud-file-info', {fileUrl}, 'POST')
                const fileInfoResponse = await fileInfoRequest.json()
                const fileName = await fileInfoResponse.fileMetaData.properties.title
                fileListData[fileInfo].fileName = fileName
            }
            setCloudConnectionsList(fileListData)
        }
        setShowCloudFileModalOpen(false)
        setIsLoading(false)
    }
    

  return (
        <Modal
            isOpen={showCloudFileModalOpen}
            closeTimeoutMS={ 200 }
            className="modal-usersettings"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center'>Información del archivo</h4>
            <hr />

            <form>
                <div className="mb-3">
                    <label htmlFor="tempName" className="form-label template-labels mb-0">
                        {t('listName')}
                    </label>
                    <div className='wa-message-box-modal py-1 px-2'>
                        <span>{ cloudFileInformation.listname }</span>
                    </div>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="tempLang" className="form-label template-labels mb-0">
                        {t('fileName')}
                    </label>
                    <div className='wa-message-box-modal py-1 px-2'>
                        <span>{ cloudFileInformation.fileName }</span>
                    </div>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="tempCateg" className="form-label template-labels mb-0">
                        {t('platform')}
                    </label>
                    <div className='wa-message-box-modal py-1 px-2'>
                        <span>
                            {
                                cloudFileInformation.platform === 'gdrive' ? 'Google Drive' : null
                            }
                        </span>
                    </div>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="tempMsg" className="form-label mb-0">{t('fileUrl')}</label>
                    
                    <div className='file-url-box py-1 px-2'>
                        <a
                            href={cloudFileInformation.fileUrl}
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            { cloudFileInformation.fileUrl }
                        </a>
                    </div>
                </div>

                <div className='mt-4 btn-group w-100'>
                    <button
                        disabled={isLoading}
                        className='btn btn-sm btn-lightblue'
                        onClick={ handleDelete }
                    >
                        {
                            isLoading ? (
                                <>
                                    <span className='text-light'>{t('processing')}...</span>
                                    <div className="spinner-border text-light loading-button ms-2" role="status">
                                        <span className="visually-hidden">...</span>
                                    </div>
                                </>
                            ) : t('delete')
                        }
                    </button>
                    <button
                        disabled={isLoading}
                        className='btn btn-sm btn-91'
                        onClick={ closeModal }
                    >
                        {t('close')}
                    </button>
                </div>
            </form>
        </Modal>
  )
}

export default CloudFileInformation