import React from 'react'
import { useTranslation } from 'react-i18next';

const NotificationText = () => {
    
  const { t } = useTranslation();

  return (
    <div style={{ "height": "90vh", "width": "60vw", "margin": "auto" }} className='d-flex justify-content-center align-items-center'>
        <div>
            <div className='harbinger-logo-azul'></div>
            
            <h5 className='text-center color-login'>
                {t('bannedDomainText1')}
            </h5>
            <p className='text-mediumblue text-center'>
            {t('bannedDomainText2')} <strong>cloud@seidoranalytics.com</strong> {t('bannedDomainText3')}.
            </p>
        </div>
    </div>
  )
}

export default NotificationText