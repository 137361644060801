import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { fetchBackend } from '../../helpers/fetch';
import { useAuth } from '@frontegg/react';
import phone from 'phone'
import flagNotFound from '../../img/no_flag.png'
import { DateTime } from 'luxon';

Modal.setAppElement('#root');


const SMSMFAModal = () => {

    const { user } = useAuth();
    const { MFAModal, setMFAModal, userConfiguration, setUserConfiguration } = useContext(ElementContext);
    const { t } = useTranslation();
    const [tenantPhone, setTenantPhone] = useState('')
    const [codeNumber1, setCodeNumber1] = useState('')
    const [codeNumber2, setCodeNumber2] = useState('')
    const [codeNumber3, setCodeNumber3] = useState('')
    const [codeNumber4, setCodeNumber4] = useState('')
    const [codeNumber5, setCodeNumber5] = useState('')
    const [codeNumber6, setCodeNumber6] = useState('')
    const [fullCode, setFullCode] = useState('')
    const [phoneCountry, setPhoneCountry] = useState('ZZ')
    const [sentCodeMessage, setSentCodeMessage] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const [wrongCode, setWrongCode] = useState(false)
    const [userChecked, setUserChecked] = useState(false)
    

    const handleMFARequest = async() => {
        setLoadingButton(true)
        const data = {
            tenant: user.email.replace('@', '_'),
            phone: tenantPhone
        }
        const sendSmsCodeRequest = await fetchBackend('sendSmsCodeRequest', {data}, 'POST')
        const smsCodeResponse = await sendSmsCodeRequest.json()
        if(smsCodeResponse.ok){
            setSentCodeMessage(true)
            setFullCode(smsCodeResponse.code)
            setLoadingButton(false)
        }
    }


    const handleSetTenantPhone = (e) => {
        const { value } = e.target;
        const phoneNumber = value.includes('+') ? value.replace('+','') : value
        setTenantPhone(phoneNumber)
        const phoneInfo = phone(`+${phoneNumber}`)
        if( phoneInfo.isValid ){
            setPhoneCountry(phoneInfo.countryIso2)
        } else {
            setPhoneCountry('ZZ')
        }
    }

    const handleNumberChange = (e) => {
        setWrongCode(false)
        const { name, value } = e.target;
        switch (name) {
            case 'input2':
                    setCodeNumber1(value)
        document.querySelector(`#${name}`).select()
                break;
            case 'input3':
                    setCodeNumber2(value)
        document.querySelector(`#${name}`).select()
                break;
            case 'input4':
                    setCodeNumber3(value)
        document.querySelector(`#${name}`).select()
                break;
            case 'input5':
                    setCodeNumber4(value)
        document.querySelector(`#${name}`).select()
                break;
            case 'input6':
                    setCodeNumber5(value)
        document.querySelector(`#${name}`).select()
                break;
            case 'last':
                    setCodeNumber6(value)
        document.querySelector(`#${name}`).focus()
                break;
            default: break;
        }
    }


    const handleCheckVerificationCode = async() => {
        setLoadingButton(true)
        const enteredCode = `${codeNumber1}${codeNumber2}${codeNumber3}${codeNumber4}${codeNumber5}${codeNumber6}`
        if(enteredCode !== fullCode){
            setWrongCode(true)
            setLoadingButton(false)
        } else {
            setUserChecked(true)
            // Guardado de las configuraciones en DB
            const options = {
                delay: userConfiguration.delay,
                language: userConfiguration.language,
                oscilacionTiempo: userConfiguration.oscilacionTiempo,
                selectedWACredentials: userConfiguration.selectedWACredentials,
                whatsappAPIcredentials: userConfiguration.whatsappAPIcredentials,
                smsCodeValidated: true,
                enteredPhone: tenantPhone
            }
            const data = {
                email: user.email,
                settings: JSON.stringify(options),
                fechamodificacion: DateTime.now()
            }
            
            const res = await fetchBackend('settings', {data}, 'PUT')
            const body = await res.json();
            console.log(body)
            setUserConfiguration(options)

            setTimeout(() => {
                setMFAModal(false)
            }, 3000);
        }
    }



  return sentCodeMessage ? (
    <Modal
        isOpen={ MFAModal }
        className="modal-mfa p-4"
        overlayClassName="modal-fondo"
    >
        <h4 className='text-center text-mediumblue mb-4'>
            Validación de identidad
        </h4>
        <small className='text-justify border-top d-block py-4'>
            Acabamos de enviar un mensaje de texto con un nuevo <strong>código de verificación</strong> al número de teléfono que termina en <strong>{ tenantPhone.slice(tenantPhone.length - 4) }</strong>
        </small>
        <small className='d-block mb-3'>
            Para continuar, escribe el código de 6 dígitos
        </small>

        <div className="input-group">
            <input id='input1' type="text" name='input2' maxLength='1' className="form-control text-center codeNumber" value={ codeNumber1 } onChange={ handleNumberChange } />
            <input id='input2' type="text" name='input3' maxLength='1' className="form-control text-center codeNumber" value={ codeNumber2 } onChange={ handleNumberChange } />
            <input id='input3' type="text" name='input4' maxLength='1' className="form-control text-center codeNumber" value={ codeNumber3 } onChange={ handleNumberChange } />
            <input id='input4' type="text" name='input5' maxLength='1' className="form-control text-center codeNumber" value={ codeNumber4 } onChange={ handleNumberChange } />
            <input id='input5' type="text" name='input6' maxLength='1' className="form-control text-center codeNumber" value={ codeNumber5 } onChange={ handleNumberChange } />
            <input id='input6' type="text" name='last' maxLength='1' className="form-control text-center codeNumber" value={ codeNumber6 } onChange={ handleNumberChange } />
        </div>
        
        {
            wrongCode && (
                <small className='d-block text-danger mt-2'>
                    <strong>
                        Código incorrecto
                    </strong>
                </small>
            )
        }

        <small id='last' className='border-bottom d-block py-4'>
            ¿No recibiste el código? <strong className='text-mediumblue pointer' onClick={ handleMFARequest }>Reenviar</strong>
        </small>
        <div className='d-flex justify-content-center align-items-center mt-4'>
            {
                userChecked ? (
                    <div className='d-flex align-items-center justify-content-center animate__animated animate__bounceIn'>
                        <h5 className='text-success mb-0'>
                            Verificado
                        </h5>
                        <i className='bx bxs-user-check text-success'></i>
                    </div>
                ) : (
                    <div className='d-flex align-items-center justify-content-center'>
                        <button
                            className='btn btn-91 btn-sm me-3'
                            onClick={ () => window.location.replace('/') }
                        >
                            Cancelar
                        </button>
                        <button
                            className='btn btn-sm btn-lightblue'
                            onClick={ handleCheckVerificationCode }
                            disabled={ !codeNumber1 || !codeNumber2 || !codeNumber3 || !codeNumber4 || !codeNumber5 || !codeNumber6 || loadingButton }
                        >
                            Continuar
                        </button>
                    </div>
                )
            }
        </div>
    </Modal>
  ) : (
        <Modal
            isOpen={ MFAModal }
            className="modal-mfa p-4"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center text-mediumblue mb-4'>
                Validación de identidad
            </h4>

            <small className='text-justify border-top d-block py-3'>
                Enviaremos un código de verificación a tu teléfono móvil para asegurarnos de que realmente eres tú.
            </small>
            <strong>
                Número de teléfono
            </strong>

            <div id="tenantPhone" className="input-group mt-2 mb-3">
                <span className="input-group-text" id="basic-addon1">
                    <img
                        alt="Phone country"
                        src={
                            phoneCountry === 'ZZ'
                            ? flagNotFound
                            : `http://purecatamphetamine.github.io/country-flag-icons/3x2/${phoneCountry}.svg`
                        }
                        width={35}
                        className='me-2'
                    />
                    +
                </span>
                <input
                    type="text"
                    name="tenantPhone"
                    className='form-control text-center'
                    value={ tenantPhone }
                    onChange={ handleSetTenantPhone }
                />
            </div>

            <small className='border-bottom d-block py-4'>
                Al proporcionar tu teléfono móvil, aceptas recibir mensajes de texto de Harbinger.
            </small>
            <div className='d-flex justify-content-center align-items-center mt-4'>
                
                <button
                    className='btn btn-91 btn-sm me-3'
                    onClick={ () => window.location.replace('/') }
                >
                    Cancelar
                </button>
                <button
                    className='btn btn-sm btn-lightblue'
                    onClick={ handleMFARequest }
                    disabled={ phoneCountry === 'ZZ' || loadingButton }
                >
                    {
                        loadingButton ? (
                            <>
                              <span className='text-light'>{t('processing')}...</span>
                              <div className="spinner-border text-light loading-button ms-2" role="status">
                                  <span className="visually-hidden">Loading...</span>
                              </div>
                            </>
                        ) : 'Solicitar código'
                    }
                </button>
            </div>
        </Modal>
  )
}

export default SMSMFAModal