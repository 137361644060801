import React, {useContext} from 'react'
import {ElementContext} from './ElementContext'
import Swal from 'sweetalert2';
import { fetchBackend } from '../helpers/fetch';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';


const timezone = DateTime.now().zoneName;

const ActionButtonRenderer = (props) => {

  const { user } = useAuth();
  const { setScheduleToShow, madeByUser } = useContext(ElementContext);
  const { t } = useTranslation();

  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    
  const handleFinishSchedule = async() => {
    const tenant = user.email.replace('@', '_')
    const nameSchedule = cellValue.name;
    const fechamodificacion = DateTime.now().setZone(timezone);
    const newState = 'false'
    
    const resultado = await fetchBackend( 'finalizar-tarea', {nameSchedule, madeByUser, tenantId: tenant, fechamodificacion, newState}, 'PUT' )
    const body = await resultado.json();
    
    if( !body.ok ){
      return Swal.fire('Oops', t('problem'), 'error')
    }
    
    // Get Schedule
    const getNewSchedule = await fetchBackend(`schedule/${tenant}`);
    const bodyNewSchedule = await getNewSchedule.json();
    const dataNewSchedule = await bodyNewSchedule.data;
    setScheduleToShow(dataNewSchedule);
  }

  // Comentario para ser borrado

  return cellValue.state === 'true' ? (
      <div className="d-flex justify-content-center mt-2">
        <button
            id='finish-task-btn'
            className='btn btn-xs btn-mediumblue'
            onClick={handleFinishSchedule}
        >
            {t('finishTask')}
        </button>
      </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center">
        <span>-</span>
    </div>
  )
}

export default ActionButtonRenderer