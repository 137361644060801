import React, { useContext } from 'react';
import {ElementContext} from './ElementContext';
import { useTranslation } from 'react-i18next';

const AddButton = () => {

  const { inputList, setInputList, infoToShow, setInfoToShow, userInformation } = useContext(ElementContext);
  const { t } = useTranslation();

  const handleAddInput = () => {
    if( infoToShow.length > 0 ){
      setInfoToShow([])
    } else {
      setInputList([
        ...inputList,
        { numero: userInformation.countryCode }
      ])
    }

  }

  return (
    <button
        id="addField"
        className="btn btn-outline-lightblue btn-sm"
        onClick={ handleAddInput }
    >
        {t('add')}
    </button>
  )
};

export default AddButton;
