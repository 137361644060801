import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { fetchBackend } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import AppInfo from '../../../package.json'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';


Modal.setAppElement('#root');


const lngs = {
    en: { nativeName: 'EN' },
    es: { nativeName: 'ES' }
}


const UserSettingsModal = () => {

    const { user } = useAuth();
    const { userSettingsModal, setUserSettingsModal, userOptionSelected, setUserOptionSelected, delayMsg, setDelayMsg, oscillationMsg, setOscillationMsg, globalEnv, userConfiguration } = useContext(ElementContext)

    const { t, i18n } = useTranslation();
    const [delayEmpty, setDelayEmpty] = useState(false)
    const supportEmail = globalEnv.REACT_APP_SUPPORT_EMAIL;

    const closeModal = () => {
        setUserOptionSelected('')
        setUserSettingsModal(false)
    }

    const handleDelayChange = (e) => {
        setDelayMsg(e.target.value)
        if(e.target.value){
            setDelayEmpty(false)
        } else {
            setDelayEmpty(true)
        }
    }

    const closeFormModal = async(e) => {
        e.preventDefault()
        if(!delayMsg){
            return setDelayEmpty(true)
        }
        await setDelayMsg(userConfiguration.delay)
        await setOscillationMsg(JSON.parse(userConfiguration.oscilacionTiempo))
        await i18n.changeLanguage(userConfiguration.language)
        closeModal()
    }

    const handleSubmitSettings = async(e) => {
        e.preventDefault();
        if(!delayMsg){
            return setDelayEmpty(true)
        }

        // Guardado de las configuraciones iniciales en DB
        const options = {
            delay: parseInt(delayMsg),
            oscilacionTiempo: oscillationMsg,
            language: i18n.resolvedLanguage,
            selectedWACredentials: userConfiguration.selectedWACredentials,
            whatsappAPIcredentials: userConfiguration.whatsappAPIcredentials
        }

        const data = {
            email: await user.email,
            settings: JSON.stringify(options)
        }
        
        const res = await fetchBackend( 'settings', {data}, 'PUT' )
        const body = await res.json();
        
        if( !body.ok ){
            Swal.fire('Oops', t('problem'), 'error')
        } else {
            Swal.fire(t('success'), t('settingsSaved'), 'success')
        }
        
        closeModal()
    }


    const handleCloseUserBox = () => {
        document.querySelector('#user-info').click();
        closeModal();
    }


    const searchAndClickLangButton = async(e, language) => {
        e.preventDefault();
        await document.querySelector(`#${language}`).click();
    }


    if(userOptionSelected === 'settings'){
        return <Modal
                    isOpen={userSettingsModal}
                    // onRequestClose={closeModal}
                    closeTimeoutMS={ 200 }
                    className="modal-usersettings"
                    overlayClassName="modal-fondo"
                >
                    
                    <h4 className='text-center'>{t('settings')}</h4>
                    <hr />
                    <form onSubmit={handleSubmitSettings}>
                        {
                            delayEmpty && (
                                <small className='text-danger'>Este campo no puede quedar vacío</small>
                            )
                        }
                        <div className='d-flex align-items-center'>
                            <small>{t('delayBetweenMsgs')}:</small>
                            <input
                                type="number"
                                className='delay-style'
                                min={5}
                                value={delayMsg}
                                onChange={ handleDelayChange }
                                onInvalid={(e)=>{e.target.setCustomValidity( t('minFiveOrUpper') )}}
                            />
                            <small>{t('sec')}.</small>
                        </div>

                        <div className='osc-style d-flex justify-content-between align-items-center'>
                            <small>{t('oscillationBetweenMsgs')}:</small>
                            <div className='d-flex flex-column enabled-disabled-box'>
                                <small
                                    className={ oscillationMsg ? 'text-mediumblue' : 'text-91' }
                                >
                                    { oscillationMsg ? t('enabled') : t('disabled') }    
                                </small>
                                
                                <label htmlFor="oscilacion">
                                    <input type="checkbox" className='d-none' name="oscilacion" id="oscilacion" checked={oscillationMsg} onChange={ () => { setOscillationMsg(!oscillationMsg) } } />
                                    <div className="user-toggle-switch">
                                        <span className="user-switch"></span>
                                    </div>
                                </label>
                            </div>
                        </div>

                        
                        <div className='osc-style d-flex justify-content-between align-items-center'>
                            <small>{t('language')}:</small>
                            <div className="btn-group">
                                {Object.keys(lngs).map(lng => (
                                    <button
                                        key={lng}
                                        onClick={ (e) => searchAndClickLangButton(e,lng) }
                                        disabled={i18n.resolvedLanguage === lng}
                                        className='btn btn-sm btn-91'
                                    >
                                        {lngs[lng].nativeName}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className='d-flex justify-content-between mt-5'>
                            <Link
                                to="/baja"
                                className="btn btn-sm btn-lightblue text-decoration-none"
                                onClick={ handleCloseUserBox }
                            >
                                {t('cancelSubscription')}
                            </Link>

                            <div>
                                <button
                                    className='btn btn-sm btn-mediumblue me-1'
                                >
                                    {t('apply')}
                                </button>
                                <button
                                    className='btn btn-sm btn-secondary'
                                    onClick={ closeFormModal }
                                >
                                    {t('cancel')}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className='btn-group d-none'>
                        {Object.keys(lngs).map(lng => (
                            <button
                                key={lng}
                                id={lng}
                                onClick={() => i18n.changeLanguage(lng)}
                                disabled={i18n.resolvedLanguage === lng}
                                className='btn btn-sm btn-91'
                            >
                                {lngs[lng].nativeName}
                            </button>
                        ))}
                    </div>
                </Modal>
    }

    
    if(userOptionSelected === 'about'){
        return <Modal
                    isOpen={userSettingsModal}
                    onRequestClose={closeModal}
                    closeTimeoutMS={ 200 }
                    className="modal-usersettings"
                    overlayClassName="modal-fondo"
                >
                    
                    <h4 className='text-center'>{t('about')}</h4>
                    <hr />
                    <div className='d-flex flex-column align-items-center my-4'>
                        <h3>Harbinger</h3>
                        <h6 className='mb-0'>{t('version')}: {AppInfo.version}</h6>
                    </div>

                    <hr />
                    <div className='d-flex flex-column align-items-center'>
                        <small>{t('anyQuestions')}:</small>
                        <h6>
                            <a
                                href={`mailto:${supportEmail}`}
                                className='text-decoration-none'
                                target='_blank'
                                rel="noopener noreferrer"
                            >
                                {supportEmail}
                            </a>
                        </h6>
                    </div>

                    <div className='d-flex justify-content-center mt-4'>
                        <button
                            className='btn btn-sm btn-lightblue'
                            onClick={ closeModal }
                        >
                            {t('close')}
                        </button>
                    </div>
                </Modal>
    }

  return (
        <div></div>
  )
}

export default UserSettingsModal