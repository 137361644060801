import React, { useContext, useEffect, useState } from 'react';
import {ElementContext} from './ElementContext'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BannedTenantModal from './modals/BannedTenantModal';
import { useAuth } from '@frontegg/react';
import { Icon } from '@iconify/react';
import '../styles/sidebar.css'
import '../styles/userinfo-card.css'
import '../styles/attachfile-card.css'


const Sidebar = () => {

    const { user } = useAuth();
    const { isDarkMode, setIsDarkMode, userPlan, toggleSidebar, setToggleSidebar, setInfoToShow, globalEnv, setEsMultisesion, setBannedTenantModal } = useContext(ElementContext);
    
    const { t, i18n } = useTranslation();
    const [boxChecked, setBoxChecked] = useState(false)
    
    let helpURL = globalEnv.REACT_APP_HELP_LINK;

    const body = document.querySelector('body');
    isDarkMode ? body.classList.add('dark') : body.classList.remove('dark');

    
    const darkMode = () => {
        const body = document.querySelector('body');
        setIsDarkMode(!isDarkMode)

        isDarkMode ? (body.classList.add('dark')) : (body.classList.remove('dark'))
    }

    const handleSetMultisesion = (bool) => {
        setInfoToShow([])
        setEsMultisesion(bool)
    }

    
    useEffect(() => {
        const tenant = user.email.replace('@', '_')
        if( globalEnv.BANNED_TENANTS.includes(tenant) ){
            setBannedTenantModal(true)
        }
    }, [])

    const handleCheckboxCollapse = () => {
        const boxChecked = document.querySelector('#collapse-checkbox').checked

        if(boxChecked){
            setBoxChecked(false)
            document.querySelector('#collapse-checkbox').checked = false
        } else {
            setBoxChecked(true)
            document.querySelector('#collapse-checkbox').checked = true
        }
    }
    

  return (
    <nav className={ toggleSidebar ? "sidebar" : "sidebar close"}>
        <header>
            <Link to="/">
                { toggleSidebar ? <div className="image-large"></div> : <div className="image-short"></div> }
            </Link>

            <i className='bx bx-chevron-right toggle bg-white border border-primary' onClick={ () => setToggleSidebar(!toggleSidebar) }></i>
        </header>

        <div className="menu-bar">

            <div className="menu">
                <div className='d-flex justify-content-center mb-4'>
                    <span className={ toggleSidebar ? 'customer-plan font13' : 'customer-plan font10'}>{ userPlan.toUpperCase() }</span>
                </div>

                <ul id="sidebar-box-2" className="ps-0">
                    {
                        globalEnv.SIDEBAR_MENU.SEND_MESSAGES.includes(userPlan) && (
                            <li className="p-0" onClick={ () => handleSetMultisesion(false) }>
                                <Link to="/formulario-whatsapp">
                                    <i className='bx bxl-whatsapp icon'></i>
                                    <span className="text">{t('sendMsgs')}</span>
                                </Link>
                            </li>
                        )
                    }
                    
                    {
                        globalEnv.SIDEBAR_MENU.WHATSAPP_API.includes(userPlan) && (
                            <>
                                <li className="p-0" onClick={ handleCheckboxCollapse }>
                                    <div className="collapsible pointer">
                                        <Icon icon="heroicons:user-group-solid" className='sidebar-icon' />
                                        <span className="text">Whatsapp API</span>
                                        <i className={`bx bxs-chevron-${ boxChecked ? 'up' : 'down' } icon ms-auto`}></i>
                                    </div>
                                </li>
                                
                                <input type="checkbox" className='d-none' id="collapse-checkbox" />
                                <li className='navCollapse ms-auto'>
                                    <Link to="/whatsapp-api-credentials" className='d-flex justify-content-between ps-3 py-1 text-decoration-none'>
                                        <span className="text">
                                            {t('credentials')}
                                        </span>
                                        <Icon icon="mdi:id-card-outline" className='sidebar-icon' />
                                    </Link>
                                    <Link to="/whatsapp-api-templates" className='d-flex justify-content-between ps-3 py-1 text-decoration-none'>
                                        <span className="text">
                                            {t('templates')}
                                        </span>
                                        <Icon icon="tabler:template" className='sidebar-icon' />
                                    </Link>
                                    <Link to="/send-template-message" className='d-flex justify-content-between ps-3 py-1 mb-1 text-decoration-none'>
                                        <span className="text">
                                            {t('sendMsgs')}
                                        </span>
                                        <Icon icon="mingcute:mail-send-line" className='sidebar-icon' />
                                    </Link>
                                </li>
                            </>
                        )
                    }
                    
                    {
                        globalEnv.SIDEBAR_MENU.MULTISESSION_MESSAGES.includes(userPlan) && (
                            <li className="p-0" onClick={ () => handleSetMultisesion(true) }>
                                <Link  to="/formulario-multisesion">
                                    <i className='bx bxs-devices icon'></i>
                                    <span className="text">{t('sendMultisession')}</span>
                                </Link>
                            </li>
                        )
                    }
                    
                    {
                        globalEnv.SIDEBAR_MENU.SEND_SMS.includes(userPlan) && (
                            <li className="p-0" onClick={ () => setInfoToShow([]) }>
                                <Link to="/formulario-sms">
                                    <i className='bx bx-message-detail icon'></i>
                                    <span className="text">{t('sms')}</span>
                                </Link>
                            </li>
                        )
                    }

                    {
                        globalEnv.SIDEBAR_MENU.SCHEDULE_MESSAGES.includes(userPlan) && (
                            <li className="p-0">
                                <Link to="/programados">
                                    <i className='bx bx-alarm icon' ></i>
                                    <span className="text">{t('scheduledMsgs')}</span>
                                </Link>
                            </li>
                        )
                    }

                    {
                        globalEnv.SIDEBAR_MENU.CONTACT_LISTS.includes(userPlan) && (
                            <li className="p-0">
                                <Link to="/mis-listas">
                                    <i className='bx bx-list-ul icon'></i>
                                    <span className="text">{t('listsManagement')}</span>
                                </Link>
                            </li>
                        )
                    }

                    {
                        globalEnv.SIDEBAR_MENU.FILE_LISTS.includes(userPlan) && (
                            <li className="p-0">
                                <Link to="/cloud-connections">
                                    <i className='bx bx-cloud icon'></i>
                                    <span className="text">{t('fileLists')}</span>
                                </Link>
                            </li>
                        )
                    }
                    
                    {
                        globalEnv.SIDEBAR_MENU.HISTORY_MESSAGES.includes(userPlan) && (
                            <li className="p-0">
                                <Link to="/historico">
                                    <i className='bx bx-history icon' ></i>
                                    <span className="text">{t('history')}</span>
                                </Link>
                            </li>
                        )
                    }

                    {
                        globalEnv.SIDEBAR_MENU.TEMPLATES_MESSAGES.includes(userPlan) && (
                            <li className="p-0">
                                <Link to="/plantillas">
                                    <i className='bx bx-spreadsheet icon' ></i>
                                    <span className="text">{t('templates')}</span>
                                </Link>
                            </li>
                        )
                    }
                    
                    {
                        globalEnv.SIDEBAR_MENU.AI_TEMPLATES.includes(userPlan) && (
                            <li className="p-0" onClick={ () => handleSetMultisesion(false) }>
                                <Link to="/message-generator">
                                    <Icon icon="fluent:bot-24-regular" className='sidebar-icon'/>
                                    <span className="text">{ t('aiTemplates') }</span>
                                </Link>
                            </li>
                        )
                    }
                    
                    {/* {
                        globalEnv.SIDEBAR_MENU.INTEGRATIONS.includes(userPlan) && (
                            <li className="p-0" onClick={ () => handleSetMultisesion(false) }>
                                <Link to="/platform-integrations">
                                    <Icon icon="solar:round-graph-broken" className='sidebar-icon'/>
                                    <span className="text">{ t('integrations') }</span>
                                </Link>
                            </li>
                        )
                    } */}

                    {
                        globalEnv.SIDEBAR_MENU.HELP.includes(userPlan) && (
                            <li className="p-0">
                                <a
                                    href={ i18n.resolvedLanguage === 'es' ? helpURL : `${helpURL}/en/`}
                                    target="_blank"
                                    className='text-decoration-none'
                                    rel="noopener noreferrer"
                                >
                                    <i className='bx bx-help-circle icon'></i>
                                    <span className="text">{t('help')}</span>
                                </a>
                            </li>
                        )
                    }
                </ul>
            </div>

            <div className="bottom-content">
                <label htmlFor="user-info" className='user-li'>
                    <li className='pointer'>
                        <img src={ user.profilePictureUrl } className='profile-img icon-img' alt='' />
                        <span className='prof-name-font'>{ user.name }</span>
                    </li>
                </label>

                <li className="mode" onClick={ darkMode }>
                    <div className="sun-moon">
                        <i className='bx bx-moon icon moon'></i>
                        <i className='bx bx-sun icon icon2 sun'></i>
                    </div>
                    <span className={ isDarkMode ? 'mode-text text' : 'mode-text text text-mediumblue' }>
                        {
                            isDarkMode ? "Dark mode" : "Light Mode"
                        }
                    </span>
                    
                    <div className="toggle-switch">
                        <span className="switch"></span>
                    </div>
                </li>
                
            </div>
        </div>
        
        <BannedTenantModal />
    </nav>
  )
};

export default Sidebar;
