import React, { useMemo, useContext } from 'react';
import {ElementContext} from '../ElementContext'
import 'moment/locale/es'
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useTranslation } from 'react-i18next';


    const ExecutedPaymentTracking = () => {
    
    const { toggleSidebar, ExecutedPaymentTracking } = useContext(ElementContext);
    console.log(ExecutedPaymentTracking)

    const containerStyle = useMemo(() => ({ height: '80%' }), []);
    const { t, i18n } = useTranslation()

    return (
        <div className='home col-12 animate__animated animate__fadeIn' style={containerStyle}>

            <div className="d-flex align-items-center justify-content-between">
                <div className={ toggleSidebar ? 'titles-margin-close mb-4' : 'titles-margin-open mb-4' }>
                    <div className="text">
                    {t("collectionFollowUp")}
                    </div>
                    <p className='subtitle mb-0'>
                    {t("reportPlatformUsage")}
                    </p>
                </div>
            </div> 
            
            <div className="table-powerbi">
                <iframe name= "TableroPowerBi" title="Harbinger-Tablero de Cobranzas" width="100%" height="600" src="https://app.powerbi.com/reportEmbed?reportId=64f555ae-c5b1-4666-9ac5-a13fcc4d74a4&autoAuth=true&ctid=9445ab2e-91b8-406c-982b-5559fa56982f" frameborder="0" allowFullScreen={true}></iframe> 
            </div>

        </div>
    );
};

export default ExecutedPaymentTracking;