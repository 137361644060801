import React from 'react';
import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';


export const DateRenderer = (props) => {
  return <div>{new Date(props.value).toLocaleDateString()}</div>
}

export const AckMessageRenderer = (props) => {
    return ((props.data.estadorecepcion === 'ACK_ERROR') &&
            <Tooltip TransitionComponent={Zoom} title={ props.value.msgNotSent }>
              <Icon icon="uil:exclamation-triangle" className='wa-status-error ms-3'/>
            </Tooltip>)

        || ((props.data.estadorecepcion === 'ACK_PENDING') &&
            <Tooltip TransitionComponent={Zoom} title={ props.value.sentMsg }>
              <Icon icon="ci:check-big" className='wa-status-server ms-3'/>
            </Tooltip>)

        || ((props.data.estadorecepcion === 'ACK_SERVER') && 
            <Tooltip TransitionComponent={Zoom} title={ props.value.sentMsg }>
              <Icon icon="ci:check-big" className='wa-status-server ms-3'/>
            </Tooltip>)

        || ((props.data.estadorecepcion === 'ACK_DEVICE') &&
            <Tooltip TransitionComponent={Zoom} title={ props.value.receivedMsg }>
              <Icon icon="ci:check-all-big" className='wa-status-device ms-3'/>
            </Tooltip>)

        || ((props.data.estadorecepcion === 'ACK_READ') && 
            <Tooltip TransitionComponent={Zoom} title={ props.value.readMsg }>
              <Icon icon="ci:check-all-big" className='wa-status-read ms-3'/>
            </Tooltip>)

        || ((props.data.estadorecepcion === 'ACK_PLAYED') && 
            <Tooltip TransitionComponent={Zoom} title={ props.value.playedMsg }>
              <Icon icon="ci:check-all-big" className='wa-status-played ms-3'/>
            </Tooltip>)
}


export const TemplateTypeIconRenderer = (props) => {

  return ((props.data.tipoplantilla === 'standard') &&
          <Tooltip TransitionComponent={Zoom} title={ props.value.standardTemplate }>
            <Icon icon="ph:user-list-fill" className='templates-type-icon standard-icon'/>
          </Tooltip>)

      || ((props.data.tipoplantilla === 'ai-saved') &&
          <Tooltip TransitionComponent={Zoom} title={ props.value.aiTemplate }>
            <Icon icon="mdi:robot" className='templates-type-icon ai-icon'/>
          </Tooltip>)
}


const CellRenderer = () => {return (<div>-</div>)}
export default CellRenderer