import React, { useMemo, useRef, useState, useContext, useCallback } from 'react'
import {ElementContext} from '../ElementContext';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import PlatformIconRenderer from '../filesLists/CloudConnectionsRenderer';
import CloudFileInformation from '../modals/CloudFileInformation';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../styles/table.css'


const CloudConnectionsTable = () => {
    
    const { cloudConnectionsList, setCloudFileInformation, setShowCloudFileModalOpen } = useContext(ElementContext)
    const { t } = useTranslation()
  
    const gridRef = useRef(); // Optional - for accessing Grid's API

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'listname',
            headerName: t('name'),
        },
        {
            field: 'fileName',
            headerName: t('fileName'),
        },
        {
            field: 'platform',
            headerName: t('platform'),
            cellRenderer: PlatformIconRenderer
        }
    ]);
    
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({ sortable: true, filter: true }) );

    
    const onFirstDataRendered = useCallback( async() => {
        gridRef.current.api.sizeColumnsToFit();
        // const allColumnIds = [];
        // await gridRef.current.columnApi.getColumns().forEach((column) => {
        //     allColumnIds.push(column.getId());
        // });
        // await gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, []);

    const handleCellClicked = (e) => {
        setCloudFileInformation(e.data)
        setShowCloudFileModalOpen(true)
    }


    return (
        <div>
            <div className="ag-theme-alpine" style={{width: "100%", height: 450}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={cloudConnectionsList}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    onCellClicked={ handleCellClicked }
                    onFirstDataRendered={onFirstDataRendered}
                />
            </div>
            <CloudFileInformation/>
        </div>
    )
}

export default CloudConnectionsTable