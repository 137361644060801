import React from 'react';
import { useTranslation } from 'react-i18next';

const PaymentDoneMessage = () => {
  
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column'>
        <span className='color-login text-center'>
            {t('paymentDoneMsg1')}
        </span>
        <span className='color-login text-center'>
            {t('paymentDoneMsg2')}
        </span>
    </div>
  )
}

export default PaymentDoneMessage