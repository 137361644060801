import React from 'react'

const MainLoader = () => {
  return (
    <div style={{ height: '100vh' }} className='d-flex justify-content-center align-items-center'>
        <div className="spinner-grow text-mediumblue" role="status">
            <span className="visually-hidden">...</span>
        </div>
    </div>
  )
}

export default MainLoader