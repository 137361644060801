import React, { useContext } from 'react'
import { ElementContext } from '../ElementContext';
import { useAuth } from '@frontegg/react';
import { getWhatsappApiTemplates, handleUpdateSelectedCredentials } from '../../helpers/whatsapp-api';
import { fetchBackend } from '../../helpers/fetch';
import { useTranslation } from 'react-i18next';

const WhatsappAPISelector = () => {

    const { userConfiguration, setUserConfiguration, selectedWACredentials, setSelectedWACredentials, setWhatsappTemplates, wAPIUserInformation, setApprovedTemplates } = useContext(ElementContext)
    const { user } = useAuth();
    const { t } = useTranslation();

    const handleSelectWACredentials = async() => {
        const selectedWANumberID = await document.querySelector('#WACredentials').selectedOptions[0].value;

        const configuration = await userConfiguration
        const email = await user.email
        const updateSelected = await handleUpdateSelectedCredentials(configuration, selectedWANumberID, email)
        if(updateSelected){
            const allWACredentials = await userConfiguration.whatsappAPIcredentials
            const filteredWACredentials = await allWACredentials.filter( cred => cred.phoneNumberId === selectedWANumberID )[0]
            const whatsappApiTemplates = await getWhatsappApiTemplates(filteredWACredentials)
            const allTemplates = whatsappApiTemplates.allTemplates
            const approvedTemplates = whatsappApiTemplates.approvedTemplates
            setWhatsappTemplates(allTemplates)
            setApprovedTemplates(approvedTemplates)
    
            const configRequest = await fetchBackend(`settings/${email}`)
            const configResponse = await configRequest.json()
            const configData = await configResponse.data[0].configuracion
            const newUserConfiguration = await JSON.parse(await configData)
            setUserConfiguration(newUserConfiguration)
            setSelectedWACredentials(selectedWANumberID)
        } else {
            console.log('No se pudo completar la solicitud')
        }
    }


  return (
    <>
        <label className='mb-1' htmlFor="WACredentials">
            <small className='wa-selector-label'>{t('selectWhatsappSource')}</small>
        </label>
        <select
            className='form-select form-select-sm wa-selector-box'
            name="WACredentials"
            id="WACredentials"
            defaultValue={selectedWACredentials}
            onChange={ handleSelectWACredentials }
        >
            {
                wAPIUserInformation.map( whatsappInfo => (
                    <option key={whatsappInfo.id} value={whatsappInfo.id}>
                        { whatsappInfo.verified_name } ({whatsappInfo.display_phone_number})
                    </option>
                ))
            }
        </select>
    </>
  )
}

export default WhatsappAPISelector