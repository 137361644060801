import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { ElementContext } from '../ElementContext';


const SelectListFromContacts = () => {
    
    const { setInfoToShow, setModalIsOpen, listNames, setActiveList, allTenantContacts } = useContext(ElementContext);
    const { t } = useTranslation();
    const [listRequested, setListRequested] = useState(false)


    const handleAddList = async(lista) => {
        setListRequested(true)
        const { idlista, nombrelista } = lista
        const infoContactos = allTenantContacts.filter( info => parseInt(info.idlista) === parseInt(idlista) )
        setInfoToShow(infoContactos)
        setActiveList(nombrelista)
        setModalIsOpen(false)
        setListRequested(false)
    }

  return (
    <div className='animate__animated animate__fadeIn'>
        {
            listRequested ? (
                <div className='d-flex align-items-center justify-content-center py-5'>
                  <h5 className='text-mediumblue'>{t('loadingContacts')}...</h5>
                  <div className="spinner-grow text-mediumblue ms-2" role="status">
                      <span className="visually-hidden">...</span>
                  </div>
                </div>
            ) : (
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th className='ps-4'>#ID</th>
                        <th>{t('listName')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        listNames.map(list => (
                        <tr
                            className='pointer'
                            key={list.idlista}
                            onClick={ () => handleAddList(list) }
                        >
                            <th className='ps-4'>{list.idlista}</th>
                            <td>{list.nombrelista}</td>
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
            )
        }
    </div>
  )
}

export default SelectListFromContacts