import Swal from 'sweetalert2';
import validator from 'validator';
import { fetchBackend } from './fetch';


export const validarEmail = async(email) => {
    const esEmail = validator.isEmail(email);
    const stringEmail = email
    const desiredEmail = stringEmail.replace(/[^\w\s\@\.\-]/gi, 'regex')
    const emailCharVerify = desiredEmail.includes('regex')

    if(!esEmail){
        Swal.fire('Atención', 'Ingrese un email válido por favor', 'warning')
        return false
    } else if (emailCharVerify){
        Swal.fire('Atención', 'El email ingresado contiene caracteres no permitidos', 'warning')
        return false
    }
    return true
}


export const checkBannedText = async(message, bannedText) => {
    let checkText = false;
    for(let text of bannedText){
        if(message.includes(text)){ checkText = true }
    }
    return checkText
}


export const blockUserInDatabase = async(tenantid) => {
    await fetchBackend( `update-user-data`, {tenantid}, 'PUT' )
}


export const checkTenantSession = async(tenantid) => {
    const checkIfSessionExists = await fetchBackend(`check-session/${tenantid}`)
    const checkResp = await checkIfSessionExists.json()

    return checkResp.sessionExists
}