import Swal from "sweetalert2";
import { fetchBackend } from "./fetch";



export const getContactsByTenant = async(email) => {
    const tenant = email.replace('@', '_')
    try {
        const fetchGetContacts = await fetchBackend(`contacts-by-tenant/${tenant}`);
        const resGetContacts = await fetchGetContacts.json();
        const dataResult = await resGetContacts.data;
        
        if( !resGetContacts.ok ){
            return Swal.fire('Oops', 'Ha habido un problema', 'error');
        }
        return dataResult
    } catch (error) {
        console.log('Error en DeleteContactModal.jsx: ' + error)
    }
}

export const getContactsByList = async(activeList) => {
    try {
        const fetchGetContacts = await fetchBackend(`contacts/${activeList}`);
        const resGetContacts = await fetchGetContacts.json();
        const dataResult = await resGetContacts.data;
        
        if( !resGetContacts.ok ){
            return Swal.fire('Oops', 'Ha habido un problema', 'error');
        }
        return dataResult
    } catch (error) {
        console.log('Error en DeleteContactModal.jsx: ' + error)
    }
}

export const deleteContactById = async(contactInfo) => {
    try {
        const res = await fetchBackend( `contacts/${await contactInfo.idcontacto}`, {}, 'DELETE' )
        const bodyRes = await res.json();
        
        if( !bodyRes.ok ){
            return Swal.fire('Oops', 'Ha habido un problema', 'error')
        } else if(bodyRes.ok) {
            return Swal.fire('Success', 'Contacto eliminado', 'success')
        }
    } catch (error) {
        console.log('Error desde deleteContactById en DeleteContactModal.jsx: ' + error)
    }
}

export const deleteAllContactsFromList = async(activeList) => {
    try {
        const fetchDeleteAll = await fetchBackend( `delete-contacts/${activeList}`, {}, 'DELETE' );
        const resDeleteAll = await fetchDeleteAll.json();;
    
        if( !resDeleteAll.ok ){
            return Swal.fire('Oops', 'Ha habido un problema', 'error');
        } else if(resDeleteAll.ok){
            return Swal.fire('Success', 'Contactos eliminados', 'success');
        }
    } catch (error) {
        console.log('Error desde deleteAllContactsFromList en DeleteContactModal.jsx: ' + error)
    }
}