import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { getWhatsappApiTemplates, insertVariablesSelectElements, postWhatsappApiTemplates } from '../../helpers/whatsapp-api';
import '../../styles/wa-templates.css'


Modal.setAppElement('#root');

const WAPINewTemplateModal = () => {
    
    const { whatsAPIModalOpen, setWhatsAPIModalOpen, setWhatsappTemplates, selectedWACredentials, userConfiguration, setApprovedTemplates } = useContext(ElementContext)
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [newTemplateValues, setNewTemplateValues] = useState({
        'tempName': '',
        'tempLanguage': '',
        'tempCategory': '',
        'tempHeader': '',
        'tempBody': '',
        'tempFooter': '',
    })
    const [insertedVar, setInsertedVar] = useState(0)
    const [firstPart, setFirstPart] = useState({ enabled: true, completed: false })


    const closeModal = () => {
        setWhatsAPIModalOpen(false)
    }

    const handleNewTemplateChange = async(e) => {
        const { name, value } = e.target;
        const { tempName, tempLanguage, tempCategory, tempHeader, tempBody, tempFooter } = newTemplateValues
        const newTemplate = { tempName, tempLanguage, tempCategory, tempHeader, tempBody, tempFooter }

        if( name === 'tempName' ){
            newTemplate[name] = await value.replace(' ', '_').toLowerCase()
        } else if(name === 'tempBody'){
            const var1 = document.querySelector('#exampleVar1')
            const var2 = document.querySelector('#exampleVar2')
            const var3 = document.querySelector('#exampleVar3')

            if(value.includes('{{1}}') && !var1){
                setInsertedVar(1)
                await insertVariablesSelectElements(1)
            } else if(value.includes('{{2}}') && !var2){
                setInsertedVar(2)
                await insertVariablesSelectElements(2)
            } else if(value.includes('{{3}}') && !var3){
                setInsertedVar(3)
                await insertVariablesSelectElements(3)
            }

            let newText = null;
            if(var1 && !value.includes('{{1}}')){
                setInsertedVar(0)
                var1.remove()
                var2 && var2.remove()
                var3 && var3.remove()
                newText = await value.replace('{{2}}', '').replace('{{3}}', '')
            }
            if(var2 && !value.includes('{{2}}')){
                setInsertedVar(1)
                var2.remove()
                var3 && var3.remove()
                newText = await value.replace('{{3}}', '')
            }
            if(var3 && !value.includes('{{3}}')){
                setInsertedVar(2)
                var3.remove()
            }

            newText ? newTemplate[name] = await newText.trim() : newTemplate[name] = await value
        } else {
            newTemplate[name] = await value
        }

        if(newTemplate.tempName && newTemplate.tempLanguage && newTemplate.tempCategory){
            setFirstPart({ enabled: firstPart.enabled, completed: true })
        } else {
            setFirstPart({ enabled: firstPart.enabled, completed: false })
        }

        setNewTemplateValues(newTemplate)
    }

    const handleConfigurationPage = (e, enabled) => {
        e.preventDefault()
        setFirstPart({ enabled, completed: firstPart.completed })
    }

    const handleInsertVariable = async() => {
        if(insertedVar < 3){
            const newVar = insertedVar + 1
            const variable = ` {{${newVar}}}`;
            const finalBody = newTemplateValues.tempBody.trim() + variable;
            
            const { tempName, tempLanguage, tempCategory, tempHeader, tempFooter } = newTemplateValues
            const newTemplate = {
                tempName, tempLanguage, tempCategory, tempHeader, tempBody: finalBody, tempFooter
            }

            setNewTemplateValues(newTemplate)
            setInsertedVar(newVar)
            await document.querySelector('#tempBody').focus()

            await insertVariablesSelectElements(newVar)
        }
    }

    const handleSendNewTemplate = async(e) => {
        e.preventDefault()
        setLoading(true)
        const allWACredentials = await userConfiguration.whatsappAPIcredentials
        const WACredentials = await allWACredentials.filter( cred => cred.phoneNumberId === selectedWACredentials )[0]
        const { tempName, tempLanguage, tempCategory, tempHeader, tempBody, tempFooter } = newTemplateValues

        let varValues = [];
        for(let i=1; i<=insertedVar; i++){
            const varValue = await document.querySelector(`#exampleVar${i}`).selectedOptions[0].value
            const realValue = await varValue.replace('$NOMBRE$', 'Sonia')
                                            .replace('$APELLIDO$', 'Sanchez')
                                            .replace('$NUMERO$', '1133997755')
                                            .replace('$CARGO$', 'Developer')
                                            .replace('$PAIS$', 'Argentina')
                                            .replace('$OBSERVACIONES$', '458289')
            varValues.push(realValue)
        }

        const templateParams = {
            'name': tempName,
            'language': tempLanguage,
            'category': tempCategory,
            "components": [
                {
                  "type": "HEADER",
                  "format": "TEXT",
                  "text": tempHeader,
                  "example": { "header_text":["-"] }
                },
                {
                   "type": "BODY",
                   "text": tempBody,
                   "example": {"body_text": [varValues.length > 0 ? varValues : [""]]}
                },
                {
                  "type": "FOOTER",
                  "text": tempFooter
                }
            ]
        }

        const newTemplateReq = await postWhatsappApiTemplates(WACredentials, templateParams)
        
        if(newTemplateReq.id){
            const whatsappApiTemplates = await getWhatsappApiTemplates(WACredentials)
            const allTemplates = whatsappApiTemplates.allTemplates
            const approvedTemplates = whatsappApiTemplates.approvedTemplates
            setWhatsappTemplates(allTemplates)
            setApprovedTemplates(approvedTemplates)
            closeModal()

            setNewTemplateValues({ 'tempName': '', 'tempLanguage': '', 'tempCategory': '', 'tempHeader': '', 'tempBody': '', 'tempFooter': '' })
        } else {
            Swal.fire(t('error'), t('templateBadRequest'), 'error')
            console.log(newTemplateReq)
        }
        
        setLoading(false)
    }

    const handleCancelNewTemplate = (e) => {
        e.preventDefault()
        closeModal()
    }
    

  return (
        <Modal
            isOpen={whatsAPIModalOpen}
            closeTimeoutMS={ 200 }
            className="modal-new-template"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center text-mediumblue mb-0'>{t('newTemplate')}</h4>
            {
                firstPart.enabled ? (
                    <div className="d-flex justify-content-center">
                        <small className='text-lightblue'>
                            {t('step')} 1: {t('configureYourTemplate')}
                        </small>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <small className='text-lightblue'>
                            {t('step')} 2: {t('editTemplateContent')}
                        </small>
                    </div>
                )
            }
            <hr />

            <form id='newTemplateForm'>
                <div className='template-form'>
                    <div className={ firstPart.enabled ? 'template-form-first-part animate__animated animate__fadeInLeft' : 'd-none' }>
                        <div className="mb-3">
                            <label htmlFor="tempName" className="form-label template-labels mb-0">
                                {t('yourTemplateName')}
                            </label>
                            <input
                                autoComplete='off'
                                disabled={loading}
                                type="text"
                                className="form-control"
                                id="tempName"
                                name='tempName'
                                value={ newTemplateValues.tempName }
                                onChange={ handleNewTemplateChange }
                                placeholder={t('templateName')} />
                        </div>
                    
                        <div className="mb-3">
                            <label htmlFor="tempLanguage" className="form-label template-labels mb-0">
                                {t('selectTempLanguage')}
                            </label>
                            <select className="form-select form-select-sm" id="tempLanguage" name='tempLanguage' defaultValue={'default'} aria-label="Select language" disabled={loading} onChange={ handleNewTemplateChange } >
                                <option value="default" disabled>{t('selectLanguage')}</option>
                                <option value="es_ES">{t('spanish')}</option>
                                <option value="en_US">{t('english')}</option>
                            </select>
                        </div>
                    
                        <div className="mb-3">
                            <label className="form-label template-labels mb-2">
                                {t('selectTempCateg')}
                            </label>
                            <div className='d-flex align-items-start'>
                                <input type="radio" name='tempCategory' id="radio-transactional" value="TRANSACTIONAL" className='tempCategory mt-1' onChange={ handleNewTemplateChange } />
                                <label htmlFor='radio-transactional' className='category-radio'>
                                    <span>{t('transactional')}</span>
                                    <small>
                                        {t('transactionalDetails')}.
                                    </small>
                                </label>
                            </div>
                            <div className='d-flex align-items-start'>
                                <input type="radio" name='tempCategory' id="radio-marketing" value="MARKETING" className='tempCategory mt-1' onChange={ handleNewTemplateChange } />
                                <label htmlFor='radio-marketing' className='category-radio'>
                                    <span>{t('marketing')}</span>
                                    <small>
                                        {t('marketingDetails')}.
                                    </small>
                                </label>
                            </div>
                        </div>
                        
                        <div className='mt-4 d-flex justify-content-center'>
                            <button
                                disabled={loading}
                                className='btn btn-sm btn-91'
                                onClick={ handleCancelNewTemplate }
                            >
                                {t('cancel')}
                            </button>
                            
                            <button
                                disabled={ !firstPart.completed }
                                className='btn btn-sm btn-lightblue ms-3'
                                onClick={ (e) => handleConfigurationPage(e, false) }
                            >
                                {t('next')}
                            </button>
                        </div>
                    </div>

                    <div className={ !firstPart.enabled ? 'template-form-second-part animate__animated animate__fadeInRight' : 'd-none' }>
                        <div className="mb-3">
                            <label htmlFor="tempHeader" className="form-label template-labels mb-0">
                                {t('header')}
                            </label>
                            <input
                                autoComplete='off'
                                disabled={loading}
                                type="text"
                                className="form-control"
                                id="tempHeader"
                                name='tempHeader'
                                value={ newTemplateValues.tempHeader }
                                onChange={ handleNewTemplateChange }
                                placeholder={`${t('write')}...`} />
                        </div>

                        <div className="mb-2">
                            <label htmlFor="tempBody" className="form-label template-labels mb-0">
                                {t('bodyMessage')}
                            </label>
                            <textarea
                                disabled={loading}
                                className="form-control"
                                placeholder={`${t('write')}...`}
                                id="tempBody"
                                name='tempBody'
                                value={ newTemplateValues.tempBody }
                                onChange={ handleNewTemplateChange }
                                rows="3">
                            </textarea>
                            <div className='d-flex justify-content-end align-items-center'>
                                <small className='pointer' onClick={ handleInsertVariable }>+ {t('insertVariable')}</small>
                            </div>
                        </div>

                        <div className='row mb-2'>
                            {
                                (insertedVar > 0) && (
                                    <label htmlFor="" className='form-label template-labels'>
                                        {t('selectField')}
                                    </label>
                                )
                            }
                            <div id='insertedVarInputs' className="col-12"></div>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="tempFooter" className="form-label template-labels mb-0">
                                {t('footerMessage')}
                            </label>
                            <input
                                autoComplete='off'
                                disabled={loading}
                                type="text"
                                className="form-control"
                                id="tempFooter"
                                name='tempFooter'
                                value={ newTemplateValues.tempFooter }
                                onChange={ handleNewTemplateChange }
                                placeholder={`${t('write')}...`} />
                        </div>
                        
                        <div className='d-flex justify-content-between button-agrupation'>
                            <div>
                                <button
                                    disabled={loading}
                                    className='btn btn-sm btn-mediumblue'
                                    onClick={ handleSendNewTemplate }
                                >
                                    {
                                        loading ? (
                                            <>
                                            <span className='text-light'>{t('processing')}...</span>
                                            <div className="spinner-border text-light loading-button ms-2" role="status">
                                                <span className="visually-hidden">...</span>
                                            </div>
                                            </>
                                        ) : t('createTemplate')
                                    }
                                </button>
                            </div>

                            <div className='btn-group'>
                                <button
                                    disabled={loading}
                                    className='btn btn-sm btn-lightblue'
                                    onClick={ (e) => handleConfigurationPage(e, true) }
                                >
                                    {t('back')}
                                </button>
                                
                                <button
                                    disabled={loading}
                                    className='btn btn-sm btn-91'
                                    onClick={ handleCancelNewTemplate }
                                >
                                    {t('cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
  )
}

export default WAPINewTemplateModal