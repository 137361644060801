import React, { useContext } from 'react';
import '../styles/sidebar.css'
import { ElementContext } from './ElementContext';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';


Modal.setAppElement('#root');


// Uso del replace insensitive-case
String.prototype.replaceAll = function(strReplace, strWith) {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};



const FormularioSMSBloqueado = () => {

    const { t } = useTranslation();
    const { toggleSidebar, userPlan } = useContext(ElementContext);


    
    return (
      <div className="home">
          <div className="d-flex align-items-center justify-content-between">
              <div className={ toggleSidebar ? 'titles-margin-close' : 'titles-margin-open' }>
                  <div className="text">
                      {t('sendMsgsBySmsTitle')}
                  </div>
                  <p className='subtitle mb-0'>
                      {t('sendMsgsBySmsSubtitle')}
                  </p>
              </div>
          </div>
          
            <Modal
                isOpen={true}
                closeTimeoutMS={ 200 }
                className="modal-sms-limit p-5 form-sms-bloqueado"
                overlayClassName={ toggleSidebar ? 'modal-fondo-sms-sidebaropened' : 'modal-fondo-sms-sidebarclosed' }
            >
                <h5 className='text-center text-blackseidor'>{t('maxSmsLimitModalTitle')}</h5>

                <div className="text-blackseidor d-flex flex-column justify-content-center align-items-center mt-4">
                    <p className='text-center mb-0'>
                        {t('maxSmsLimitModalMessage1')} <strong>{userPlan.toUpperCase()}</strong> {t('maxSmsLimitModalMessage2')} 
                    </p>
                </div>
                
            </Modal>
      </div>
    )
  };
  
  export default FormularioSMSBloqueado;
  