import React, { useContext } from 'react'
import { fetchBackend } from '../helpers/fetch';
import {ElementContext} from './ElementContext';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';

const PreValidationDisclaimer = () => {

    const { user } = useAuth();
    const { showDisclaimer, disclaimerAccepted, setDisclaimerAccepted, globalEnv } = useContext(ElementContext)
    
    const { t, i18n } = useTranslation();

    const handleLegalNotification = async() => {
        const tenant = user.email.replace('@', '_')
        try {
            const avisolegalaceptado = true
            const updateLegalNotification = await fetchBackend(`tenant-legalterms/${tenant}`, {avisolegalaceptado}, 'PUT')
    
            setDisclaimerAccepted(updateLegalNotification.ok)
        } catch (error) {
            console.log(error)
            Swal.fire('Oops', t('problem'), 'error')
        }
    }


    let helpURL = globalEnv.REACT_APP_HELP_LINK;


  return (
    <div
        className={ !disclaimerAccepted || showDisclaimer ? 'disclaimer-message' : 'disclaimer-without-message' }
    >

        <iframe
            src={ i18n.resolvedLanguage === 'es' ? `${helpURL}/aviso.html` : `${helpURL}/aviso_en.html` }
            frameBorder="0"
            className='avisolegal-iframe'
            title='disclaimer'
        ></iframe>


        <div className='d-flex justify-content-center mt-4'>
            <button
                className='btn btn-sm btn-mediumblue'
                disabled={ disclaimerAccepted }
                onClick={ handleLegalNotification }
            >
                {
                    disclaimerAccepted ? t('accepted') : t('agree')
                }
            </button>
        </div>
    </div>
  )
}

export default PreValidationDisclaimer