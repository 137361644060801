import { fetchBackend } from './fetch';
import phone from 'phone';

let accessToken;
let whatsappBusinessAccountId;
let phoneNumberId;


const getWhatsappApiTemplates = async(WACredentials) => {
    accessToken = await WACredentials.accessToken
    whatsappBusinessAccountId = await WACredentials.whatsappBusinessAccountId
    phoneNumberId = await WACredentials.phoneNumberId
  
    let dataResponse = [];
    const url = `https://graph.facebook.com/v15.0/${await whatsappBusinessAccountId}/message_templates`;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await accessToken}`
      }
    };
    
    await fetch(url, options)
                .then(res => res.json())
                .then(json => dataResponse = json.data)
                .catch(err => console.error('error:' + err));

    const allTemplates = dataResponse.filter( data => (data.category === 'TRANSACTIONAL' || data.category === 'MARKETING'))
    // const allTemplates = dataResponse
    const approvedTemplates = allTemplates.filter( data => data.status === 'APPROVED' )
    return { allTemplates, approvedTemplates }
}


const postWhatsappApiTemplates = async(WACredentials, templateParams) => {
    accessToken = await WACredentials.accessToken
    whatsappBusinessAccountId = await WACredentials.whatsappBusinessAccountId
    phoneNumberId = await WACredentials.phoneNumberId

    const url = `https://graph.facebook.com/v15.0/${await whatsappBusinessAccountId}/message_templates`;
    const options = {
        method: 'POST',
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await accessToken}`
        },
        body: JSON.stringify(templateParams)
    };
    
    return fetch(url, options)
                .then(res => res.json())
                .catch(err => console.error('error:' + err));
}


const deleteWhatsappApiTemplate = async(WACredentials, templateName) => {
    accessToken = await WACredentials.accessToken
    whatsappBusinessAccountId = await WACredentials.whatsappBusinessAccountId
    phoneNumberId = await WACredentials.phoneNumberId

    const url = `https://graph.facebook.com/v15.0/${await whatsappBusinessAccountId}/message_templates?name=${await templateName}&access_token=${await accessToken}`
    const options = {
        method: 'DELETE',
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
        }
    };

    return fetch(url, options)
                .then(res => res.json())
                .catch(err => console.log(err));
}


const sendTemplateMessage = async(WACredentials, template, phone) => {
    accessToken = await WACredentials.accessToken
    whatsappBusinessAccountId = await WACredentials.whatsappBusinessAccountId
    phoneNumberId = await WACredentials.phoneNumberId

    const url = `https://graph.facebook.com/v15.0/${await phoneNumberId}/messages`;
    const options = {
        method: 'POST',
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await accessToken}`
        },
        body: JSON.stringify({
            "messaging_product": "whatsapp",
            "to": phone,
            "type": "template",
            "template": {
                "name": await template.name,
                "language": { "code": await template.language }
            }
        })
    };
    
    return fetch(url, options)
                .then(res => res.json())
                .catch(err => console.error('error:' + err));
}


const getPhoneNumberInformation = async(WACredentials) => {
    accessToken = await WACredentials.accessToken
    phoneNumberId = await WACredentials.phoneNumberId

    const url = `https://graph.facebook.com/v15.0/${phoneNumberId}`;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await accessToken}`
      }
    };
    
    const dataResponse = await fetch(url, options)
                                .then(res => res.json())
                                .catch(err => console.error('error:' + err));

    if(dataResponse.error){
        return dataResponse
    } else {
        const number = await dataResponse.display_phone_number
        const iso2 = phone(`+${number}`).isValid ? phone(`+${number}`).countryIso2 : 'US'
        const flagUrl = `https://flagcdn.com/${iso2.toLowerCase()}.svg`;
        const WAPhoneInformation = {
            code_verification_status: await dataResponse.code_verification_status,
            display_phone_number: await dataResponse.display_phone_number,
            id: await dataResponse.id,
            quality_rating: await dataResponse.quality_rating,
            verified_name: await dataResponse.verified_name,
            country_flag: flagUrl
        }
        return WAPhoneInformation
    }
}


const handleDeleteCredentials = async(configuration, card, email) => {
    const cardId = await card.id
    const activeCredentials = await configuration.whatsappAPIcredentials
    const filteredCredentials = await activeCredentials.filter( cred => cred.phoneNumberId !== cardId)

    const newSelectedWACredentials = await configuration.selectedWACredentials === cardId ? '' : await configuration.selectedWACredentials

    const settings = {
        delay: await configuration.delay,
        language: await configuration.language,
        oscilacionTiempo: await configuration.oscilacionTiempo,
        selectedWACredentials: await newSelectedWACredentials,
        whatsappAPIcredentials: await filteredCredentials
    }

    const data = { email, settings: JSON.stringify(settings) }
    
    const saveConfigurationRequest = await fetchBackend( 'settings', {data}, 'PUT' )
    const saveConfigurationResponse = await saveConfigurationRequest.json();
    const saveSuccess = await saveConfigurationResponse.ok

    return saveSuccess
}


const handleUpdateSelectedCredentials = async(configuration, cardId, email) => {
    const settings = {
        delay: await configuration.delay,
        language: await configuration.language,
        oscilacionTiempo: await configuration.oscilacionTiempo,
        selectedWACredentials: await cardId,
        whatsappAPIcredentials: await configuration.whatsappAPIcredentials
    }

    const data = { email, settings: JSON.stringify(settings) }
    
    const saveConfigurationRequest = await fetchBackend( 'settings', {data}, 'PUT' )
    const saveConfigurationResponse = await saveConfigurationRequest.json();
    const saveSuccess = await saveConfigurationResponse.ok

    return saveSuccess
}


const insertVariablesSelectElements = async(newVar) => {
    const selectElement = document.createElement('select')
    selectElement.id = `exampleVar${newVar}`
    selectElement.className = 'form-select form-select-sm mb-2'
    selectElement.required = true
    selectElement.defaultValue = 'default'
    const optionElement0 = document.createElement('option')
    const optionElement1 = document.createElement('option')
    const optionElement2 = document.createElement('option')
    const optionElement3 = document.createElement('option')
    const optionElement4 = document.createElement('option')
    const optionElement5 = document.createElement('option')
    const optionElement6 = document.createElement('option')
    optionElement0.innerText = `Campo para la variable ${newVar}`
    optionElement0.value = 'default'
    optionElement0.hidden = true
    optionElement1.innerText = 'Nombre'
    optionElement1.value = '$NOMBRE$'
    optionElement2.innerText = 'Apellido'
    optionElement2.value = '$APELLIDO$'
    optionElement3.innerText = 'Pais'
    optionElement3.value = '$PAIS$'
    optionElement4.innerText = 'Teléfono'
    optionElement4.value = '$NUMERO$'
    optionElement5.innerText = 'Cargo'
    optionElement5.value = '$CARGO$'
    optionElement6.innerText = 'Observaciones'
    optionElement6.value = '$OBSERVACIONES$'
    selectElement.appendChild(optionElement0)
    selectElement.appendChild(optionElement1)
    selectElement.appendChild(optionElement2)
    selectElement.appendChild(optionElement3)
    selectElement.appendChild(optionElement4)
    selectElement.appendChild(optionElement5)
    selectElement.appendChild(optionElement6)
    document.querySelector('#insertedVarInputs').appendChild(selectElement)
}




export {
    getWhatsappApiTemplates,
    postWhatsappApiTemplates,
    deleteWhatsappApiTemplate,
    sendTemplateMessage,
    getPhoneNumberInformation,
    handleDeleteCredentials,
    handleUpdateSelectedCredentials,
    insertVariablesSelectElements
}
