import React from 'react'
import { useTranslation } from 'react-i18next';

export const WAPINameRenderer = (props) => {

    return (
        <span className='table-text'>
            { props.data.name }
        </span>
    )
}

export const WAPICategoryRenderer = (props) => {

    return (
        <span className='table-text'>
            { props.data.category.charAt(0).toUpperCase() + props.data.category.slice(1).toLowerCase() }
        </span>
    )
}

export const WAPILanguageRenderer = (props) => {
    const { t } = useTranslation();

    return (
        <span className='table-text'>
            {
                props.data.language.includes('es') ? t('spanish') : t('english')
            }
        </span>
    )
}

export const WAPIStateRenderer = (props) => {
    let pointIcon;
    let statusText;
    if(props.data.status === 'APPROVED'){
        pointIcon = 'bx bxs-circle point-success'
        statusText = 'status-success ms-2'
    } else if(props.data.status === 'REJECTED'){
        pointIcon = 'bx bxs-circle point-danger'
        statusText = 'status-danger ms-2'
    } else {
        pointIcon = 'bx bxs-circle point-warning'
        statusText = 'status-warning ms-2'
    }


  return (
    <div className='d-flex  align-items-center'>
        <i className={pointIcon}></i>
        <span className={statusText}>
            { props.data.status.charAt(0).toUpperCase() + props.data.status.slice(1).toLowerCase() }
        </span>
    </div>
  )
}

export default WAPIStateRenderer