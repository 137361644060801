import React, { useMemo, useRef, useState, useContext } from 'react';
import {ElementContext} from './ElementContext'
import { useAuth } from '@frontegg/react';
import { AgGridReact } from 'ag-grid-react';
import { fetchBackend } from '../helpers/fetch';
import {DateTime} from 'luxon';
import { useTranslation } from 'react-i18next';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


const timezone = DateTime.now().zoneName;


// ID number renderer
class idFormater {
  init(params) {
      const element = document.createElement('span');

      if(params.value.length === 1){
        const idFormated = '0' + params.value;

        element.innerText = idFormated
        this.eGui = element;
      } else {
        element.innerText = params.value;
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}

// Datetime renderer
class dateTimeFormater {
  init(params) {
      const element = document.createElement('span');

      if(params.value){
        const hourConvertion = DateTime.fromISO(params.value).setZone(timezone).toFormat('D tt');
        element.innerText = hourConvertion
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}



const ScheduleCompleted = () => {
  
  const { user } = useAuth();
  const { setScheduleToShow, scheduleExecutedToShow, setScheduleExecutedToShow } = useContext(ElementContext);

  const { t } = useTranslation();

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ height: '75%' }), []);
  const gridStyle = useMemo(() => ({ height: '75vh', marginTop: '5px', marginRight: '3px' }), []);
  const [columnDefs] = useState([
    { headerName: 'ID', field: 'idtareaejecutada', minWidth: 80, cellRenderer: idFormater },
    {
      headerName: t('executedOn'),
      field: 'fechaejecucion',
      minWidth: 180,
      sort: 'desc',
      cellRenderer: dateTimeFormater
    },
    { headerName: t('taskNumber'), field: 'nombreschedule', minWidth: 120 },
    { headerName: t('status'), field: 'estadoschedule', minWidth: 120},
    { headerName: t('fromNumber'), field: 'numeroemisor', minWidth: 140 },
    { headerName: t('fromName'), field: 'nombreemisor', minWidth: 200 },
    { headerName: t('sentTo'), field: 'nombrelista', minWidth: 160 },
    { headerName: t('sentMsg'), field: 'mensajeenviado', minWidth: 300 },
  ]);


  const defaultColDef = useMemo(() => {
    return {
      flex: 1, sortable: true, filter: true, resizable: true
    };
  }, []);


  const handleUpdateTable = async() => {
    const tenant = user.email.replace('@', '_')
    // Get executed schedule
    const getExecutedSchedule = await fetchBackend( `schedule-executed/${tenant}` )
    const resExecutedSchedule = await getExecutedSchedule.json();
    const executedScheduleData = await resExecutedSchedule.data
    setScheduleExecutedToShow(executedScheduleData);
      
    // Get Schedule
    const getNewSchedule = await fetchBackend(`schedule/${tenant}`);
    const bodyNewSchedule = await getNewSchedule.json();
    const newScheduleData = await bodyNewSchedule.data;
    setScheduleToShow(newScheduleData);
  }


  return (
    <div className='col-6 pe-0' style={containerStyle}>

      <h2 className="text-mediumblue text-center mt-4 mb-0">
        {t('executionsTitle')}
      </h2>
      <p className='text-center subtitle mb-4'>
          {t('executionsSubtitle')}
          
      </p>

      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flexGrow: '1' }}>
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={scheduleExecutedToShow}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              // rowSelection={'multiple'}
              animateRows={true}
              enableRangeSelection={false}
              suppressClipboardPaste={false}
              suppressAggFuncInHeader={true}
              enableCellChangeFlash={true}
            ></AgGridReact>
          </div>
        </div>
      </div>

      <button
        className='btn btn-sm btn-mediumblue mt-2 float-end'
        onClick={handleUpdateTable}
      >
        {t('updateTable')}
      </button>
    </div>
  );
};

export default ScheduleCompleted;