import React, {useContext, useState} from 'react'
import { apiBaseUrl } from '../helpers/fetch';
import {ElementContext} from './ElementContext';
import { useTranslation } from 'react-i18next';


const UpdateUserPlan = ({userPlan, userEmail, subscriptionPaid}) => {
  
  const { setShowAllPlans, userInformation, tenantData } = useContext(ElementContext);
  const [clicked, setClicked] = useState(false)
  const { t } = useTranslation();

  localStorage.removeItem('subscriptionID')

  const handleSubmit = () => {
    setClicked(true)
  }

  return subscriptionPaid ? (
    <div>
        <div className='d-flex flex-column align-items-center justify-content-around mt-3'>

            <h5 className='color-login mb-0'>{t('yourCurrentPlan')} <span className='text-mediumblue'>{userPlan.toUpperCase()}</span> {t('maximumReachedOne')}</h5>
            <h5 className='color-login'>{t('maximumReachedTwo')}</h5>
            <small className='text-mediumblue mt-4'>
                {t('counterMsgOne')}.
            </small>
            <small className='text-mediumblue'>
                {t('counterMsgTwo')}.
            </small>

            <button
              className='btn btn-lightblue btn-sm text-decoration-none mt-5'
              onClick={ () => setShowAllPlans(true) }
            >
              {t('otherPlans')}
            </button>

        </div>
    </div>
  ) : (
    <div>
        <div className='d-flex flex-column align-items-center justify-content-around mt-3'>
          {
            userPlan === 'trial' ? (
              <div>
                  <h5 className='text-center color-login'>{t('trialFinishedTitle')}</h5>

                  <p className='text-mediumblue text-center'>{t('trialFinishedSubtitle')}</p>
                  <small className='text-mediumblue mt-4'>
                    {t('trialFinishedMsg')}.
                  </small>

                  <div className='d-flex justify-content-center mt-4'>
                      <button
                        className='btn btn-lightblue btn-sm text-decoration-none'
                        onClick={ () => setShowAllPlans(true) }
                      >
                        {t('seePlans')}
                      </button>
                  </div>
              </div>
            ) : (
              <div>
                  <h5 className='text-center color-login'>{t('unpaidPlanMsg1')} <span className='text-mediumblue'>{userPlan.toUpperCase()}</span> {t('unpaidPlanMsg2')}</h5>
                  <small className='text-mediumblue mt-4'>
                    {t('unpaidPlanMsg3')}.
                  </small>

                  <div className='d-flex justify-content-center mt-5'>
                      {/* Form que acciona el link de pago | Oculto para el usuario */}
                      <form
                        // className={ userInformation.isoCode === 'AR' && 'd-none' }
                        action={
                            userInformation.isoCode === 'MX'
                            ? `${apiBaseUrl}/api/payment-${userPlan}-mex`
                            : `${apiBaseUrl}/api/payment-${userPlan}`
                        }
                        onSubmit={ handleSubmit }
                        method="POST">
                          <input
                            className='d-none'
                            type="email"
                            name="customeremail"
                            id="customeremail"
                            value={userEmail}
                            onChange={ () => console.log(userEmail) }
                          />
                          <input
                            className='d-none'
                            type="text"
                            name="interval"
                            id="interval"
                            value={tenantData.recurrencia}
                            onChange={ () => console.log(tenantData.recurrencia) }
                          />
                          <button
                            id='payment-button'
                            type="submit"
                            className="btn btn-mediumblue btn-sm"
                            disabled={ clicked }
                          >
                            { clicked
                              ? <div>
                                  <span className='text-light'>
                                      {t('redirect')}...
                                  </span>
                                  <div className="spinner-border text-light loading-button ms-2" role="status">
                                      <span className="visually-hidden">...</span>
                                  </div>
                                </div>
                              : t('payment') }
                              
                          </button>
                      </form>

                      {/* <a
                        href={
                          (userPlan === 'personal' &&
                          `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848823e7510188299d1ff60237`)
                          || (userPlan === 'pro' &&
                          `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c93808487fe91120188059ccc5a0281`)
                          || (userPlan === 'business' &&
                          `https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848823e7510188299f15b20238`)
                        }
                        type="button"
                        className='btn btn-mediumblue btn-sm'
                      >
                          {t('payment')}
                      </a> */}

                      <button
                        className='btn btn-lightblue btn-sm ms-3'
                        onClick={ () => setShowAllPlans(true) }
                      >
                        {t('otherPlans')}
                      </button>
                  </div>
              </div>
            )
          }
        </div>
    </div>
  )
}

export default UpdateUserPlan