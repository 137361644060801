import { Icon } from '@iconify/react'
import React from 'react'
import '../../styles/icons.css'

export const PlatformIconRenderer = (prop) => {

    const platform = prop.data.platform.toLowerCase()

    if(platform === 'gdrive'){
        return (
          <div className='gdrive-container'>
            <Icon icon="logos:google-drive" className='gdrive-icon' />
          </div>
        )
    }
}

export default PlatformIconRenderer