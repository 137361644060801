import React, { useContext } from 'react';
import { ElementContext } from './ElementContext';
import PlanCards from './ui/PlanCards';
import { useTranslation } from 'react-i18next';


const UpdatePlanScreen = () => {
    
  const { userChecked } = useContext(ElementContext);
  const { t } = useTranslation();

  return (
      <div className={
        userChecked
        ? 'home d-flex flex-column justify-content-center'
        : 'd-flex flex-column justify-content-center'
      }>

          <h3 className='text-center text-mediumblue m-0'>
              {t('plans')}
          </h3>
          <p className='text-center subtitle'>
              {t('selectPlanMsg')}
          </p>

          <PlanCards />
      </div>
  )
}

export default UpdatePlanScreen