import React, { useContext, useState } from 'react'
import { ElementContext } from '../ElementContext';
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('es', es)
setDefaultLocale('es');

const ExtractionSchedule = ({scheduleActivate}) => {

  const {frequencySelected,setFrequencySelected} = useContext(ElementContext)
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());

  const handleSelectChange = () => {
    const optionSelected = document.querySelector('#frecuencia').selectedOptions[0].value;
    setFrequencySelected(optionSelected)
    
  }

  return (
<div className={scheduleActivate ? 'box-extraction-date-disabled' : 'box-extraction-date'}>
        <h4 className='text-center'>{t('scheduleModalTitle')}</h4>
            
            <form>
                <div className='d-block my-4'>
                    {/* Seleccionar frecuencia */}
                    <label htmlFor="frecuencia" className='me-3'>{t('frequency')}:</label>
                    <select disabled={scheduleActivate} name="frecuencia" id="frecuencia" onChange={ handleSelectChange }>
                        <option value="seleccionar" hidden>{t('select')}</option>
                        <option value="once">{t('once')}</option>
                        <option value="onceperday">{t('daily')}</option>
                        <option value="onceperweek">{t('weekly')}</option>
                        <option value="oncepermonth">{t('monthly')}</option>
                        <option value="basedonepirationdate">{t('basedonexpirationdate')}</option>
                    </select>
                </div>
                
                {
                    (frequencySelected === 'once') ? (
                        <div className='d-flex align-items-center mb-1'>
                            <label htmlFor="msgDia" className='label-width'>{t('date')}:</label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                minDate={startDate}
                            />
                        </div>
                    ) : null
                }
                
                {
                    (frequencySelected === 'onceperweek') ? (
                        <div className="d-block mt-4">
                            {/* Seleccionar DIA DE LA SEMANA */}
                            <label htmlFor="msgDayOfWeek" className='label-width-2'>
                                {t('dayOfTheWeek')}:
                            </label>
                            <select
                                title={t('dayOfWeekSelected')}
                                className='mx-1'
                                name="msgDayOfWeek"
                                id="msgDayOfWeek"
                                onInvalid={(e)=>{e.target.setCustomValidity(t('dayOfWeekSelected'))
                                }}
                                onChange={(e)=>{e.target.setCustomValidity('')}}
                                required
                            >
                                <option value="" hidden>{t('select')}</option>
                                <option value="1">{t('monday')}</option>
                                <option value="2">{t('tuesday')}</option>
                                <option value="3">{t('wednesday')}</option>
                                <option value="4">{t('thursday')}</option>
                                <option value="5">{t('friday')}</option>
                                <option value="6">{t('saturday')}</option>
                                <option value="7">{t('sunday')}</option>
                            </select>
                        </div>
                    ) : null
                }
                
                {
                    (frequencySelected === 'oncepermonth') ? (
                        <div className="d-flex align-items-center mt-4">
                            {/* Seleccionar DIA DEL MES */}
                            <label htmlFor="oneDayPerMonth" className='label-width-3'>
                                {t('dayOfMonth')}:
                            </label>
                            <input
                                title={t('dayOfMonth')}
                                id='oneDayPerMonth'
                                type='number'
                                min={1}
                                max={31}
                                className='ms-2 me-1'
                                placeholder='-'
                                onInvalid={(e)=>{e.target.setCustomValidity(t('dayOfMonthSchedulesRequired'))
                                }}
                                onChange={(e)=>{e.target.setCustomValidity('')}}
                                required
                            />
                                {t('everyMonth')}
                        </div>
                    ) : null
                }

{
                    (frequencySelected === 'basadoenvencimento') ? (
                        <div className="date-expiration d-flex mt-4">
                            {/* Seleccionar DIAS DE ANTELACION*/}
                            
                            <div className="d-block mt-2">
                                {/* Seleccionar HORAS */}
                                <label htmlFor="msgHoras3" className='label-width'>{t('at')}:</label>
                                <input
                                    title={t('hours')}
                                    id='msgHoras3'
                                    type='number'
                                    min={0}
                                    max={23}
                                    className='ms-2 me-1'
                                    placeholder='-'
                                    onInvalid={(e)=>{e.target.setCustomValidity(t('hourSchedulesRequired'))
                                    }}
                                    onChange={(e)=>{e.target.setCustomValidity('')}}
                                    required />
                                Hs
                                {/* Seleccionar MINUTOS */}
                                <input
                                    title={t('minutes')}
                                    id='msgMinutos3'
                                    type='number'
                                    min={0}
                                    max={59}
                                    className='ms-2 me-1'
                                    placeholder='-'
                                    onInvalid={(e)=>{e.target.setCustomValidity(t('minSchedulesRequired'))}}
                                    onChange={(e)=>{e.target.setCustomValidity('')}}
                                    required
                                />
                                Min

                            </div>

                            <div>
                                <label htmlFor="msgDayOfWeek" className='label-width-2'>
                                    {t('dias de anticipiacion del aviso')}:
                                </label>
                                <input
                                    title={t('Fecha de vencimiento:')}
                                    id='oneDayPerMonth'
                                    type='number'
                                    min={0}
                                    max={30}
                                    className='ms-2 me-1'
                                    placeholder='-'
                                    onInvalid={(e)=>{e.target.setCustomValidity(t('dayOfMonthSchedulesRequired'))
                                    }}
                                    onChange={(e)=>{e.target.setCustomValidity('')}}
                                    required
                                />

                            </div>
                        </div>
                    ) : null
                }

                {
                    (frequencySelected === 'once' || frequencySelected === 'onceperday' || frequencySelected === 'onceperweek' || frequencySelected === 'oncepermonth') ? (
                        <div className='d-flex justify-content-between'>
                            <div className="d-block mt-2">
                                {/* Seleccionar HORAS */}
                                <label htmlFor="msgHoras3" className='label-width'>{t('at')}:</label>
                                <input
                                    title={t('hours')}
                                    id='msgHoras3'
                                    type='number'
                                    min={0}
                                    max={23}
                                    className='ms-2 me-1'
                                    placeholder='-'
                                    onInvalid={(e)=>{e.target.setCustomValidity(t('hourSchedulesRequired'))
                                    }}
                                    onChange={(e)=>{e.target.setCustomValidity('')}}
                                    required />
                                Hs
                                {/* Seleccionar MINUTOS */}
                                <input
                                    title={t('minutes')}
                                    id='msgMinutos3'
                                    type='number'
                                    min={0}
                                    max={59}
                                    className='ms-2 me-1'
                                    placeholder='-'
                                    onInvalid={(e)=>{e.target.setCustomValidity(t('minSchedulesRequired'))}}
                                    onChange={(e)=>{e.target.setCustomValidity('')}}
                                    required
                                />
                                Min

                            </div>
                            <p className='ms-3 mb-0 hoursystem'>* {t('hourSystem')}</p>
                        </div>
                    ) : null
                }
            </form>
    </div>
  )
}

export default ExtractionSchedule