import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { fetchBackend } from '../../helpers/fetch';
import { useAuth } from '@frontegg/react';

Modal.setAppElement('#root');


const BannedPhoneModal = () => {

    const { user } = useAuth();
    const { bannedPhoneModal, globalEnv } = useContext(ElementContext);
    const { t } = useTranslation();
    const [closeRequested, setCloseRequested] = useState(false)
    
    const handleCloseModal = async() => {
        const tenant = user.email.replace('@', '_')
        setCloseRequested(true)
        const removeSessionReq = await fetchBackend(`eliminar-sesion/${tenant}`, {}, 'DELETE')
        const removeResponse = await removeSessionReq.json()
        if( removeResponse.ok ){
            window.location.replace('/account/logout')
        }
    }

  return (
        <Modal
            isOpen={ bannedPhoneModal }
            className="modal-newgroup p-4"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center text-mediumblue'>{ t('warning') }</h4>
            <p className='text-center'>
                <small>
                    { t('bannedPhoneText1') }.
                </small>
            </p>
            <p className='text-center'>
                <small>
                    { t('bannedPhoneText2') } <strong>{ globalEnv.REACT_APP_SUPPORT_EMAIL }</strong> { t('bannedPhoneText3') }.
                </small>
            </p>

            <div className='d-flex justify-content-center align-items-center mt-4'>
                <button
                    className='btn btn-sm btn-lightblue'
                    onClick={ handleCloseModal }
                    disabled={ closeRequested }
                >
                    {
                        closeRequested ? (
                            <div className='d-flex align-items-center justify-content-center'>
                                <span className='text-light'>{t('processing')}...</span>
                                <div className="spinner-grow spinner-grow-sm text-light ms-2" role="status">
                                    <span className="visually-hidden">...</span>
                                </div>
                            </div>
                        ) : t('close')
                    }
                </button>
            </div>
        </Modal>
  )
}

export default BannedPhoneModal