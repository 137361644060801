import { Icon } from '@iconify/react';
import React, { useContext } from 'react'
import { ElementContext } from '../ElementContext';
import InsertWACredentials from '../modals/InsertWACredentials';
import WhatsappAPICredentialsCards from './WhatsappAPICredentialsCards';
import { useTranslation } from 'react-i18next';

const WAPICredentials = () => {
  
  const { setWACredentialsModal } = useContext(ElementContext);
  const { t } = useTranslation();


  return (
    <div className='home animate__animated animate__fadeIn'>
      <div className="container-fluid">
          <div className="row">
              <div className="col-10">
                  <h2 className='text-mediumblue mt-3 mb-0'>{t('wApiCredentialsTitle')}</h2>
                  <p className='subtitle'>
                      {t('wApiCredentialsSubtitle')}
                  </p>
              </div>
              <div className="col-2 d-flex align-items-center justify-content-center">
                  <button
                    className='btn btn-sm btn-mediumblue d-flex align-items-center justify-content-center'
                    onClick={ () => setWACredentialsModal(true) }
                  >
                    <Icon icon="ic:baseline-plus" className='icon-size-m me-1' />
                    {t('credentials')}
                  </button>
              </div>
          </div>
          <div className="row">
              <div className="col-12">
                  <WhatsappAPICredentialsCards/>
              </div>
          </div>
      </div>
      <InsertWACredentials/>
    </div>
  )
}

export default WAPICredentials