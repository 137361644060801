import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {ElementContext} from './ElementContext';
import { useTranslation } from 'react-i18next';


const UserBoxInfo = () => {
    
    const { user, changesApplied, toggleSidebar, setUserSettingsModal, setUserOptionSelected } = useContext(ElementContext);
    const { t } = useTranslation();


    const handleWithChangesApplied = () => {
        Swal.fire(t('warning'), t('unsavedChangesContactLists'), 'warning')
    }

    
    const handleOpenUserModal = (e) => {
        setUserOptionSelected(e.target.id)
        setUserSettingsModal(true)
    }
    
        
    const handleLogout = async() => {
        window.location.replace('/account/logout')
    }


    const handlePlanChange = () => {
        document.querySelector('#user-info').click();
    }


  return (
    <div>
        {/* User card */}
        <input type="checkbox" className="user-info d-none" name="" id="user-info"/>
        <div className={ !toggleSidebar ? 'card' : 'card card-sidebaropen' }>
            <label htmlFor="user-info" className='pointer ms-auto'>
                <i className='bx bx-x'></i>
            </label>
            <div className="card-information">
                <img className="profile-img" src={ user.profilePictureUrl } alt='' />
                <h6 className="card__title mt-2 mb-0">{ user.name}</h6>
                <p className="mb-0 mt-1">{ user.email}</p>
            </div>
    
            <div className="horizontal-line"></div>
    
            <div
                className="d-flex align-items-center card-actions"
                id='settings'
                value='settings'
                onClick={ handleOpenUserModal }
            >
                <div className='d-flex align-items-center'>
                    <i className='bx bx-cog me-2' id='settings' onClick={ handleOpenUserModal }></i>
                    <p className="mb-0" id='settings' onClick={ handleOpenUserModal }>{t('settings')}</p>
                </div>
            </div>
            
            <div
                className="d-flex align-items-center justify-content-start card-actions"
                id='about'
                onClick={ handleOpenUserModal }
            >
                <i className='bx bx-info-circle me-2' id='about' onClick={ handleOpenUserModal }></i>
                <p className="mb-0" id='about' onClick={ handleOpenUserModal }>{t('about')}</p>
            </div>
            
            <Link
                to='/cambio-de-plan'
                className="d-flex align-items-center justify-content-start card-actions text-decoration-none"
                id='user-subscribe'
                onClick={ handlePlanChange }
            >
                <i className='bx bx-user-pin me-2'></i>
                <p className="mb-0">{t('changePlan')}</p>
            </Link>
    
            <div className="horizontal-line"></div>
                
            {
                changesApplied ? (
                    <div className="d-flex flex-row align-items-center justify-content-start card-actions" onClick={handleWithChangesApplied}>
                        <i className='bx bx-log-out me-2'></i>
                        <span className="text">{t('logout')}</span>
                    </div>
                ) : (
                    <div className="d-flex flex-row align-items-center justify-content-start card-actions" onClick={ handleLogout }>
                        <i className='bx bx-log-out me-2'></i>
                        <span className="text">{t('logout')}</span>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default UserBoxInfo