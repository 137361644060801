import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import { fetchBackend } from '../../helpers/fetch';
import { useTranslation } from 'react-i18next';


Modal.setAppElement('#root');


const ConfirmModalLists = () => {

    const { confirmModal, setConfirmModal, setIsActive, listNames, setListNames, setInfoToShow, activeList, setActiveList, nameActive } = useContext(ElementContext);
    const { t } = useTranslation();


    const closeModal = () => {
        setConfirmModal(false)
    }


    const handleConfirm = async() => {
        try {
            await deleteContacts();
            await deleteList();
            const filtro = await listNames.filter( group => parseInt(group.idlista) !== activeList )
            
            setListNames(filtro)
            setIsActive(null)
            setInfoToShow(null)
            setActiveList(null)
    
            closeModal()
        } catch (error) {
            console.log('Problema en ConfirmModalList.jsx/handleConfirm: ' + error)
        }
    }


    const deleteList = async() => {
        try {
            const resultado = await fetchBackend( `lists/${activeList}`, {}, 'DELETE' )
            const body = await resultado.json();
            
            if( !body.ok ){
                Swal.fire('Oops', t('problem'), 'error')
            } else {
                Swal.fire(t('success'), t('listDeleted'), 'success')
            }
        } catch (error) {
            console.log('Problema en ConfirmModalList.jsx/deleteList: ' + error)
        }
    }

    const deleteContacts = async() => {
        try {
            const res = await fetchBackend( `delete-contacts/${activeList}`, {}, 'DELETE' )
            const bodyRes = await res.json();
            
            if( !bodyRes.ok ){
                console.log('No se pudieron eliminar los contactos de la lista')
            } else {
                console.log('Contactos eliminados correctamente')
            }
            return
        } catch (error) {
            console.log('Problema en ConfirmModalList.jsx/deleteContacts: ' + error)
        }
    }


  return (
        <Modal
            isOpen={confirmModal}
            onRequestClose={closeModal}
            closeTimeoutMS={ 200 }
            className="modal-confirm p-5"
            overlayClassName="modal-fondo"
        >
            <h5 className='text-center'>{t('confirmRemoveList1')} <span className='text-lightblue'>{nameActive}</span> {t('confirmRemoveList2')}</h5>
            <h4 className='text-center'>{t('areYouSure')}</h4>
            <div className='d-flex justify-content-center'>
                <button
                    className='btn btn-sm btn-mediumblue mt-3 me-2'
                    onClick={ handleConfirm }
                >
                    {t('remove')}
                </button>

                
                <button
                    className='btn btn-sm btn-91 mt-3'
                    onClick={ closeModal }
                >
                    {t('cancel')}
                </button>
            </div>
            
        </Modal>
  )
}

export default ConfirmModalLists