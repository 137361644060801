import React, { useContext, useState } from 'react';
import countries from 'i18n-iso-countries';
import AddButton from '../AddButton';
import { ElementContext } from '../ElementContext';
import AttachFileCard from '../AttachFileCard'
import ContactInfo from '../ContactInfo'
import InfoModal from '../modals/InfoModal'
import ScheduleWhatsAppMessages from '../modals/ScheduleWhatsAppMessages'
import SelectListModal from '../modals/SelectListModal'
import TemplateModal from '../modals/TemplateModal'
import SelectListButton from '../SelectListButton';
import RemoveButton from '../RemoveButton';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { apiBaseUrl, fetchBackend } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import axios from 'axios';
import phone from 'phone';



countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));


// Uso del replace insensitive-case
String.prototype.replaceAll = function(strReplace, strWith) {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};
let filesName = []


const WhatsAppMultisessionForm = () => {
    
    const { user, inputList, setInputList, infoToShow, setScheduleModal, setScheduleMessage, message, setMessage, setTemplateModal, tenantId, setShowInfoModal, toggleSidebar, delayMsg, userPlan, setFrequencySelected, oscillationMsg, multiSessionUsuarioEmisor, globalEnv } = useContext(ElementContext);

    
    const { t, i18n } = useTranslation();
    const [buttonSelected, setButtonSelected] = useState('')
    const [sendingMsg, setSendingMsg] = useState(false)
    const [infoSelected, setInfoSelected] = useState('')
    const [archivos, setArchivos] = useState(null)
    const [archivosImagen, setArchivosImagen] = useState(null)
    const [messageMaxLengthReached, setMessageMaxLengthReached] = useState(false)
    const [rawFileNames, setRawFileNames] = useState([])


    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list)
    }
    
    const handleTextChange = (e) => {
        const { value } = e.target;
        if( value.length <= globalEnv.MSG_MAX_LENGTH ){
            setMessageMaxLengthReached(false)
            setMessage(value);
        } else {
            setMessageMaxLengthReached(true)
        }
    }
  
    const handleRemoveInput = (index) => {
        if(inputList.length >= 2){
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
        } else {
            setInputList([
                {numero: ''}
              ])
        }
    }
    
    const openScheduleModal = (e) => {
        e.preventDefault();

        if(!message){
            return Swal.fire(t('warning'), t('beforeScheduleMsg'), 'warning')
        }

        setScheduleMessage(message)
        setFrequencySelected('seleccionar')
        setScheduleModal(true)
    }
    
    const openTemplateModal = (e) => {
        e.preventDefault();
        
        setButtonSelected(e.target.value)
        setScheduleMessage(message)
        setTemplateModal(true)
    }

    const handleInfoModal = (e) => {
        e.preventDefault()
        setInfoSelected(e.target.id)
        setShowInfoModal(true)
    }
    
    const handleFilesChange = async(e) => {
        setArchivos(e.target.files)
        const filesArray = await e.target.files
        for(let i=0; i<filesArray.length; i++){
            const nameFile = filesArray[i].name.split('.').shift().replace(/[^a-zA-Z0-9]/g, '')
            const ext = filesArray[i].name.split('.').pop()
            filesName.push(`${nameFile}.${ext}`)
        }
        
        setRawFileNames([...filesName])
        document.querySelector('#attachfilecard').click();
    }
    
    const handleImageFilesChange = async(e) => {
        setArchivosImagen(e.target.files)
        const filesArray = await e.target.files
        for(let i=0; i<filesArray.length; i++){
            const nameFile = filesArray[i].name.split('.').shift().replace(/[^a-zA-Z0-9]/g, '')
            const ext = filesArray[i].name.split('.').pop()
            filesName.push(`${nameFile}.${ext}`)
            
        }
        setRawFileNames([...filesName])
        document.querySelector('#attachfilecard').click();
    }
    
    const handleRemoveAttachFiles = () => {
        // Input files reset
        document.querySelector('#attach-imagefiles').value = '';
        document.querySelector('#attach-appfiles').value = '';
        filesName = []
        setArchivos(null)
        setArchivosImagen(null)
        setRawFileNames([])
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        setSendingMsg(true);
        
        if(infoToShow.length === 0 && inputList[0].numero === ''){
            Swal.fire(t('warning'), t('atLeastOneContact'), 'warning')
            setSendingMsg(false)
            return
        }
        
        // Si hay archivos, enviarlos a la API
        if(archivos !== null || archivosImagen !== null){
            const formData = new FormData();

            if(archivos !== null){
                for( let i=0; i<archivos.length; i++ ){
                    formData.append("files", archivos[i])
                }
            }
            if(archivosImagen !== null){
                for( let i=0; i<archivosImagen.length; i++ ){
                    formData.append("files", archivosImagen[i])
                }
            }

            await axios.post(`${apiBaseUrl}/api/send-media`, formData)
                       .then(res => 'Archivos subidos')
                       .catch(error => console.log(error))
        }


        const numbers = [];
        const arrayMessages = []
        const arrayEstados = []
        const parsedNumbers = []
        let data = {};
        let body;
        let estado;
  
        // Contactos sin agendar
        if( !(infoToShow.length > 0) ){
            // Filtro de inputs vacíos | Campo número
            inputList.map( num => {
                if(num.numero === ''){
                    console.log('Campo vacío encontrado')
                } else {
                    numbers.push(num.numero)
                }
            })
        }

        // Contactos agendados
        if(infoToShow.length > 0){
            // Filtro de inputs vacíos | Campo número
            infoToShow.map( contacto => {
                if(contacto.numero === ''){
                    console.log('Campo vacío encontrado')
                } else {
                    numbers.push(contacto.numero)
                }
            })
        }

        for(let num in numbers){
            // Verifica si el mensaje se va a enviar o no, por ser número inexistente
            let number = numbers[num];
            if(!number.includes('+')){
                number = '+' + numbers[num]
            }
            estado = phone(number).isValid;
            arrayEstados.push(estado);

            // Obtiene el país al cual pertenece el teléfono
            const parsedNum = await numbers[num].replace(/[^0-9]/g, '')
            parsedNumbers.push(parsedNum)
            
            let countryFullname;
            let countryShortname;
            let newMessages;

            if(infoToShow.length > 0){
                countryShortname = await infoToShow[num].pais || '';
                countryFullname = countries.getName(countryShortname, i18n.resolvedLanguage) || ''


                // Reemplazos de variables en el cuerpo del mensaje
                if(i18n.resolvedLanguage === 'en'){
                    newMessages = message.replaceAll('$name$', infoToShow[num].nombre)
                                            .replaceAll('$lastname$', infoToShow[num].apellido)
                                            .replaceAll('$position$', infoToShow[num].cargo)
                                            .replaceAll('$company$', infoToShow[num].empresa)
                                            .replaceAll('$country$', countryFullname)
                                            .replaceAll('$phone$', infoToShow[num].numero)
                                            .replaceAll('$observations$', infoToShow[num].observaciones)
                } else {
                    newMessages = message.replaceAll('$nombre$', infoToShow[num].nombre)
                                            .replaceAll('$apellido$', infoToShow[num].apellido)
                                            .replaceAll('$cargo$', infoToShow[num].cargo)
                                            .replaceAll('$empresa$', infoToShow[num].empresa)
                                            .replaceAll('$pais$', countryFullname)
                                            .replaceAll('$numero$', infoToShow[num].numero)
                                            .replaceAll('$observaciones$', infoToShow[num].observaciones)
                }
                
                arrayMessages.push(newMessages)
            }
        }

        data = {
            multiSessionUsuarioEmisor,
            datosDestinatarios: infoToShow.length > 0 ? infoToShow : null,
            message,
            messages: arrayMessages, //Array con msgs reemplazados
            arrayEstados,
            numbers: parsedNumbers.length === 0 ? numbers : parsedNumbers, //Array con los numeros destinatarios
            usuario: user.email,
            tenantid: tenantId,
            time: parseInt(delayMsg),
            oscillationMsg,
            canal: 'WhatsApp',
            filesName
        }

        const resultado = await fetchBackend( 'send-multisession-whatsapp', data, 'POST' );
        body = await resultado.json();


        if( body.ok ){
            Swal.fire(t('success'), t('msgSuccess'), 'success');

            // Input files reset
            document.querySelector('#attach-imagefiles').value = '';
            document.querySelector('#attach-appfiles').value = '';
            filesName = []
            setArchivos(null)
            setArchivosImagen(null)
            setRawFileNames([])
            setSendingMsg(false)
        } else {
            Swal.fire('Oops', t('problem'), 'error')
            setSendingMsg(false)
        }
    }

  return (
    <>
        <div className="d-flex align-items-center justify-content-between">
            <div className={ toggleSidebar ? 'titles-margin-close' : 'titles-margin-open' }>
                <div className="text">
                    {t('sendMsgsByWpTitle')}
                </div>
                <p className='subtitle mb-0'>
                    {t('sendMsgsByWpSubtitle')}
                </p>
            </div>
            
            <div className='d-flex align-items-center'>
                <span className='text-white bg-darkblue-op border border-light shadow py-1 px-2 me-5'>
                    {t('youHave')} {multiSessionUsuarioEmisor.length} {t('sessionStarted')}
                </span>
            </div>
        </div>

        <div className="container">
            <div className="row d-flex justify-content-between my-4">
                  <div className="col-6 btn-group btn-group-width">
                      <AddButton/>
                      <SelectListButton/>
                      <RemoveButton/>
                  </div>
                
                  <div className='col-6 d-flex justify-content-end align-items-center'>
                      <h6 className='text-mediumblue mb-0 me-3'>
                          {t('importantInfo')}
                      </h6>
                      <i
                          className='bx bx-info-circle info-icon animate__animated animate__heartBeat animate__infinite infinite'
                          id='importantInfo'
                          onClick={ handleInfoModal }
                      ></i>
                  </div>
            </div>

            
            <form id="whatsappForm" encType='multipart/form-data'>
                <div className="row d-flex justify-content-between">
                    <div id="formDiv" className="col-6 mb-3">
                        <label className="form-label text-91">
                            {t('contacts')}
                        </label>
                        {
                            infoToShow.length > 0 ? (
                                <ContactInfo/>
                            ) : (
                                inputList.map( (item, index) => {
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-1">
                                            <input
                                                type="text"
                                                className="form-control me-2"
                                                placeholder={t('enterPhoneNumber')}
                                                name="numero"
                                                value={item.numero}
                                                onChange={ e => handleInputChange(e, index) }
                                            />
                                            <i
                                                className='bx bx-trash icon text-danger me-4 pointer'
                                                onClick={ () => handleRemoveInput(index) }
                                            ></i>
                                        </div>
                                    )})
                            )
                        }
                    </div>
                    
                    <div className="col-6 mb-3">

                        <div className="d-flex align-items-center justify-content-between">
                            <label htmlFor="wa-text" className="form-label text-91">
                                {t('message')}
                            </label>

                            {
                                messageMaxLengthReached && (
                                    <small className='d-flex align-items-center text-lightblue mb-2'>
                                        <i className='bx bxs-hand x-size me-2'></i>
                                        <span>{ t('maxCharReached') }</span>
                                    </small>
                                )
                            }
                        </div>

                          <textarea
                              className="form-control"
                              id="wa-text"
                              name="message"
                              cols="30"
                              rows="10"
                              maxLength={globalEnv.MSG_MAX_LENGTH + 1}
                              value={message}
                              placeholder={`${t('write')}...`}
                              onChange={ (e) => { handleTextChange(e) } }
                          ></textarea>


                          <>
                              <div className='attachfile-position'>
                                  <label htmlFor="attachfilecard" className='pointer attachicon-position'>
                                      <Icon icon="ion:attach-outline" className='attach-icon' />
                                  </label>
  
                                  <AttachFileCard />
                              </div>
                              {/* Acá va el código */}
                              <input
                                  className='d-none'
                                  type="file"
                                  name="imagefiles"
                                  id="attach-imagefiles"
                                  accept='image/*'
                                  onChange={ handleImageFilesChange }
                                  multiple
                              />
                              <input
                                  className='d-none'
                                  type="file"
                                  name="appfiles"
                                  id="attach-appfiles"
                                  accept='application/*'
                                  onChange={ handleFilesChange }
                                  multiple
                              />
                          </>


                          {
                              (rawFileNames.length > 0) && (
                                  <>
                                      <ul className='attach-ul'>
                                          <div className='d-flex justify-content-between mt-3'>
                                              <span>{t('attachedFiles')}:</span>

                                              <button
                                                  className='btn btn-91 btn-removeattach'
                                                  onClick={ handleRemoveAttachFiles }
                                              >
                                                  {t('removeFiles')}
                                              </button>
                                          </div>
                                          {
                                              rawFileNames.map( file => (
                                                  <li key={file} className='ms-3'>
                                                      <small className='text-mediumblue'>{file}</small>
                                                  </li>
                                              ))
                                          }
                                      </ul>
                                  </>
                              )
                          }

                          <div className='btn-group w-100'>
                              <button
                                  id="form-button"
                                  className="btn btn-sm btn-lightblue mt-3 w-25"
                                  type="submit"
                                  onClick={ (e) => handleSubmit(e) }
                                  disabled={ !message || sendingMsg }
                              >
                                  {
                                      sendingMsg ? (
                                          <div>
                                          <span className='text-light'>
                                              {t('sending')}...
                                          </span>
                                          <div className="spinner-border text-light loading-button ms-2" role="status">
                                              <span className="visually-hidden">Loading...</span>
                                          </div>
                                          </div>
                                      ) : t('send')
                                  }
                              </button>
                          
                          {
                              userPlan === 'trial' || userPlan === 'personal' ? null : (
                                      <button
                                          id="form-button"
                                          className="d-none btn btn-sm btn-mediumblue mt-3 w-25"
                                          type="submit"
                                          onClick={ openScheduleModal }
                                          disabled={ infoToShow.length === 0 }
                                      >
                                          {t('scheduleMsg')}
                                      </button>
                              )
                          }
                          
                              <button
                                  id="form-button"
                                  className="btn btn-sm btn-lightblue mt-3 w-25"
                                  type="submit"
                                  onClick={ openTemplateModal }
                                  value="opentemplate"
                              >
                                  {t('openTemplate')}
                              </button>

                              <button
                                  id="form-button"
                                  className="btn btn-sm btn-darkblue mt-3 w-25"
                                  type="submit"
                                  onClick={ openTemplateModal }
                                  value="savetemplate"
                                  disabled={ !message }
                              >
                                  {t('saveTemplate')}
                              </button>
                          </div>

                          
                          <div className='d-flex justify-content-end align-items-center mt-4'>
                              <h6 className='text-mediumblue mb-0 me-3'>
                                  {t('customMsgs')}
                              </h6>
                              <i
                                  className='bx bx-message-alt-edit info-icon animate__animated animate__heartBeat animate__infinite infinite'
                                  id='customMessages'
                                  onClick={ handleInfoModal }
                              ></i>
                          </div>
                    </div>
                </div>
            </form>
        </div>

        <InfoModal infoSelected={infoSelected}/>
        <SelectListModal/>
        <ScheduleWhatsAppMessages/>
        <TemplateModal buttonSelected={buttonSelected} />
    </>
  )
}

export default WhatsAppMultisessionForm