import React, { useContext } from 'react';
import { ElementContext } from './ElementContext';
import { useTranslation } from 'react-i18next';


const RemoveButton = () => {

  const { setInputList, infoToShow, setInfoToShow, userInformation } = useContext(ElementContext);
  const { t } = useTranslation();

  const handleRemove = () => {
    if( infoToShow.length > 0 ){
      setInfoToShow([])
    } else {
      setInputList([
        { numero: userInformation.countryCode }
      ])
    }
  }

  return (
    <button
        id="addField"
        className="btn btn-outline-lightblue btn-sm"
        onClick={ handleRemove }
    >
        {t('clean')}
    </button>
  )
};

export default RemoveButton;
