import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';


Modal.setAppElement('#root');


const InfoModal = ({infoSelected}) => {

    const { showInfoModal, setShowInfoModal } = useContext(ElementContext);
    const { t, i18n } = useTranslation();


    const closeModal = () => {
        setShowInfoModal(false)
    }

    if(infoSelected === 'customMessages'){
        return (
            <Modal
                isOpen={showInfoModal}
                onRequestClose={closeModal}
                closeTimeoutMS={ 200 }
                className="modal-info p-5"
                overlayClassName="modal-fondo"
            >
                <h5 className='text-center text-blackseidor'>{t('infoModalTitle')}</h5>
    
                <div className="text-blackseidor mt-4">
                    <ul>
                        <li>{t('infoModalMsg1')}: <strong>${t('name').toUpperCase()}$</strong>, <strong>${t('lastname').toUpperCase()}$</strong>, <strong>${t('company').toUpperCase()}$</strong>, <strong>${t('position').toUpperCase()}$</strong>, <strong>${t('country').toUpperCase()}$</strong> {t('and')} <strong>${t('observations').toUpperCase()}$</strong></li>
                        <li>{t('infoModalMsg2')}.</li>
                    </ul>
                </div>
    
                <div className="text-blackseidor text-center mt-2">
                    <strong><u>{t('important')}:</u></strong> {t('infoModalMsg3')}.
                </div>
    
                <div className='d-flex justify-content-center mt-4'>
                    <button
                        className='btn btn-sm btn-lightblue'
                        onClick={ closeModal }
                    >
                        {t('gotIt')}
                    </button>
                </div>
            </Modal>
        )
    }

    
    if(infoSelected === 'importantInfo'){
        return (
            <Modal
                isOpen={showInfoModal}
                onRequestClose={closeModal}
                closeTimeoutMS={ 200 }
                className="modal-important-info p-5"
                overlayClassName="modal-fondo"
            >
                <h5 className='text-center text-blackseidor'>
                {t('importantInfoTitle')}
                </h5>
    
                <div className="text-blackseidor mt-4">
                    <ul>
                        <li>
                        {t('importantInfoBody1')}.
                        </li>
                        <li>
                        {t('importantInfoBody2')} 
                        {
                            i18n.resolvedLanguage === 'es' ? (
                                <>
                                    <strong className='mx-1'><u>+54</u></strong>1153458267.
                                </>
                            ) : (
                                <>
                                    <strong className='mx-1'><u>+1</u> (555) </strong>074 2531 or <strong className='mx-1'><u>+91</u></strong>98 10597886
                                </>
                            )
                        }
                        </li>
                        <li>
                        {t('importantInfoBody3')}.
                        </li>
                    </ul>
                </div>
    
                <div className='d-flex justify-content-center mt-4'>
                    <button
                        className='btn btn-sm btn-lightblue'
                        onClick={ closeModal }
                    >
                        {t('gotIt')}
                    </button>
                </div>
            </Modal>
        )
    }
    
    
    if(infoSelected === 'antiblockingNotice'){
        return (
            <Modal
                isOpen={showInfoModal}
                onRequestClose={closeModal}
                closeTimeoutMS={ 200 }
                className="modal-important-info p-5"
                overlayClassName="modal-fondo"
            >
                <h5 className='text-center text-mediumblue'>
                    HARBINGER: { t('effectiveUseGuide') }
                </h5>
                <small style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                    { t('harbingerUseSubtitle') }:
                </small>

                <div className="mt-4">
                    <h6 className='text-lightblue'>{ t('whatsappProfile') }:</h6>
                    <ul className='antiblocking-info' style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                        <li>
                            <small>
                                <u>{ t('profileInformationItem') }</u>: { t('profileInformationDescription') }.
                            </small>
                        </li>
                    </ul>
                </div>
                
                <div className="mt-4">
                    <h6 className='text-lightblue'>{ t('firstMessages') }:</h6>
                    <ul className='antiblocking-info' style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                        <li>
                            <small>
                                <u>{ t('knownRecipientsItem') }</u>: { t('knownRecipientsDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('saveNewPhoneItem') }</u>: { t('saveNewPhoneDescription') }.
                            </small>
                        </li>
                    </ul>
                </div>
                
                <div className="mt-4">
                    <h6 className='text-lightblue'>{ t('generalRecommendations') }:</h6>
                    <ul className='antiblocking-info' style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                        <li>
                            <small>
                                <u>{ t('QRCodeItem') }</u>: { t('QRCodeDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('phoneLineFormatItem') }</u>: { t('phoneLineFormatDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('waitingTimeItem') }</u>: { t('waitingTimeDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('messageSendingControlItem') }</u>: { t('messageSendingControlDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('dynamicMessagesItem') }</u>: { t('dynamicMessagesDescription1') } <span style={{ fontWeight: 600 }}>${ t('name').toUpperCase() }$</span> o <span style={{ fontWeight: 600 }}>${ t('lastname').toUpperCase() }$</span>. { t('dynamicMessagesDescription2') }.
                            </small>
                        </li>
                    </ul>
                </div>
                
                <div className="mt-4">
                    <h6 className='text-lightblue'>{ t('security') }:</h6>
                    <ul className='antiblocking-info' style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                        <li>
                            <small>
                                <u>{ t('avoidBeingBannedItem') }</u>: { t('avoidBeingBannedDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('corporateAccountItem') }</u>: { t('corporateAccountDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('explicitAcceptanceItem') }</u>: { t('explicitAcceptanceDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('optinWhatsappItem') }</u>: { t('optinWhatsappDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                <u>{ t('avoidUnknownPeopleItem') }</u>: { t('avoidUnknownPeopleDescription') }.
                            </small>
                        </li>
                        <li>
                            <small>
                                { t('moreWhatsappDetails') }: <a className='text-decoration-none' href="https://www.whatsapp.com/legal/terms-of-service" target='_blank'>https://www.whatsapp.com/legal/terms-of-service</a>.
                            </small>
                        </li>
                    </ul>
                </div>

                <div className='d-flex justify-content-center mt-4'>
                    <button
                        className='btn btn-sm btn-lightblue'
                        onClick={ closeModal }
                    >
                        {t('gotIt')}
                    </button>
                </div>
            </Modal>
        )
    }

    
    return ( <div></div> )
}

export default InfoModal