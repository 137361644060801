import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { fetchBackend } from '../../helpers/fetch';
import { useAuth } from '@frontegg/react';
import { getPhoneNumberInformation } from '../../helpers/whatsapp-api';
import { useState } from 'react';

Modal.setAppElement('#root');


const InsertWACredentials = () => {

    const { user } = useAuth();
    const { WACredentialsModal, setWACredentialsModal, userConfiguration, wAPIUserInformation, setwAPIUserInformation, setUserConfiguration } = useContext(ElementContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [wrongCredentials, setWrongCredentials] = useState({ ok: false, msg: '' })
    

    const handleSubmitCredentials = async(e) => {
        e.preventDefault()
        setLoading(true)
        const accessToken = await document.querySelector('#tokenAccessId').value
        const phoneNumberId = await document.querySelector('#phoneidCred').value
        const whatsappBusinessAccountId = await document.querySelector('#businessIdCred').value
        const checkIfCredentialExists = await wAPIUserInformation.some( credential => credential.id === phoneNumberId )
        if(checkIfCredentialExists){
            setWrongCredentials({ ok: true, msg: t('credentialsExists') })
            setLoading(false)
            return
        }

        const activeCredentials = await userConfiguration.whatsappAPIcredentials ? userConfiguration.whatsappAPIcredentials : []
        const newCredentials = { accessToken, phoneNumberId, whatsappBusinessAccountId }
        const whatsappApiPhoneNumber = await getPhoneNumberInformation(newCredentials)
        
        if(whatsappApiPhoneNumber.error){
            setWrongCredentials({ ok: true, msg: 'Las credenciales ingresadas son incorrectas' })
        } else {
            const allCredentials = [...activeCredentials, newCredentials]

            const settings = {
                delay: await userConfiguration.delay,
                language: await userConfiguration.language,
                oscilacionTiempo: await userConfiguration.oscilacionTiempo,
                selectedWACredentials: await userConfiguration.selectedWACredentials,
                whatsappAPIcredentials: allCredentials
            }

            const data = { email: user.email, settings: JSON.stringify(settings) }
            const saveConfigurationRequest = await fetchBackend( 'settings', {data}, 'PUT' )
            const saveConfigurationResponse = await saveConfigurationRequest.json();

            if(saveConfigurationResponse.ok){
                const WAPIUserInfo = []
                for(let credentials of allCredentials){
                    const whatsappApiPhoneNumber = await getPhoneNumberInformation(credentials)
                    WAPIUserInfo.push(whatsappApiPhoneNumber)
                }
                setwAPIUserInformation(WAPIUserInfo)
                
                const configRequest = await fetchBackend(`settings/${await user.email}`)
                const configResponse = await configRequest.json()
                const configData = await configResponse.data[0].configuracion
                const newUserConfiguration = await JSON.parse(await configData)
                setUserConfiguration(newUserConfiguration)
            }
            setWrongCredentials({ ok: false, msg: '' })
            setWACredentialsModal(false)
        }
        setLoading(false)
    }

    const handleCloseForm = (e) => {
        e.preventDefault()
        setWrongCredentials({ ok: false, msg: '' })
        setWACredentialsModal(false)
    }


  return (
    <Modal
        isOpen={ WACredentialsModal }
        className="modal-mfa p-4"
        overlayClassName="modal-fondo"
    >
        <h4 className='text-center text-mediumblue mb-4'>
            {t('wApiCredentialsTitle')}
        </h4>

        <small className='text-justify border-top d-block py-3'>
            {t('wApiCredentialsSubtitle2')}.
        </small>
        {
            wrongCredentials.ok && (
                <div className="alert alert-danger" role="alert">
                    { wrongCredentials.msg }
                </div>
            )
        }

        <form onSubmit={ handleSubmitCredentials }>
            <div className="d-flex flex-column mt-2 mb-3">
                <label htmlFor="tokenAccessId">
                    <small className='credentials-label'>{t('accessToken')}</small>
                </label>
                <input
                    disabled={loading}
                    autoComplete='off'
                    id='tokenAccessId'
                    type="text"
                    className='form-control text-center'
                    required
                />
            </div>
            
            <div className="d-flex flex-column mt-2 mb-3">
                <label htmlFor="businessIdCred">
                    <small className='credentials-label'>{t('wAccountId')}</small>
                </label>
                <input
                    disabled={loading}
                    autoComplete='off'
                    id='businessIdCred'
                    type="text"
                    className='form-control text-center'
                    required
                />
            </div>
        
            <div className="d-flex flex-column mt-2 mb-3">
                <label htmlFor="phoneidCred">
                    <small className='credentials-label'>{t('phoneId')}</small>
                </label>
                <input
                    disabled={loading}
                    autoComplete='off'
                    id='phoneidCred'
                    type="text"
                    className='form-control text-center'
                    required
                />
            </div>

            <div className='d-flex justify-content-center align-items-center mt-4'>
                <button
                    type='submit'
                    className='btn btn-lightblue btn-sm me-3'
                    disabled={loading}
                >
                    {
                        loading ? (
                            <>
                                <span className='text-light'>{t('processing')}...</span>
                                <div className="spinner-border text-light loading-button ms-2" role="status">
                                    <span className="visually-hidden">...</span>
                                </div>
                            </>
                        ) : t('save')
                    }
                </button>

                <button
                    className='btn btn-91 btn-sm me-3'
                    onClick={ handleCloseForm }
                    disabled={loading}
                >
                    {t('close')}
                </button>
            </div>
        </form>
    </Modal>
  )
}

export default InsertWACredentials