import React, { useMemo, useRef, useState, useContext, useCallback } from 'react'
import {ElementContext} from '../ElementContext';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../styles/table.css'
import { WAPICategoryRenderer, WAPILanguageRenderer, WAPINameRenderer, WAPIStateRenderer } from '../whatsappAPICloud/WhatsappAPIRenderer';
import WAPIEditTemplateModal from '../modals/WAPIEditTemplateModal';


const WhatsappTemplatesTable = () => {
    
    const { whatsappTemplates, setWAPIEditTemplateModalOpen } = useContext(ElementContext)
    const { t } = useTranslation()
    const [templateData, setTemplateData] = useState({
        name: '',
        language: '',
        category: '',
        components: [{text:''},{text:''},{text:''}]
    })
  
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'name',
            headerName: t('templateName'),
            cellRenderer: WAPINameRenderer
        },
        {
            field: 'category',
            headerName: t('category'),
            cellRenderer: WAPICategoryRenderer
        },
        {
            field: 'language',
            headerName: t('language'),
            cellRenderer: WAPILanguageRenderer
        },
        {
            field: 'status',
            headerName: t('status'),
            cellRenderer: WAPIStateRenderer
            // wrapText: true,
            // autoHeight: true,
        }
    ]);
    
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({ sortable: true, filter: true }) );

    
    const onFirstDataRendered = useCallback( async() => {
        gridRef.current.api.sizeColumnsToFit();
        // const allColumnIds = [];
        // await gridRef.current.columnApi.getColumns().forEach((column) => {
        //     allColumnIds.push(column.getId());
        // });
        // await gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, []);

    const handleCellClicked = (e) => {
        setTemplateData(e.data)
        setWAPIEditTemplateModalOpen(true)
    }


    return (
        <div>
            <div className="ag-theme-alpine" style={{width: "100%", height: 450}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={whatsappTemplates}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    pagination={true}
                    onCellClicked={ handleCellClicked }
                    onFirstDataRendered={onFirstDataRendered}
                />
            </div>

            <WAPIEditTemplateModal templateData={templateData} />
        </div>
    )
}

export default WhatsappTemplatesTable