import React, { useCallback, useMemo, useRef, useState, useContext } from 'react';
import {ElementContext} from './ElementContext'
import { AgGridReact } from 'ag-grid-react';
import { AckMessageRenderer } from './CellRenderer';
import phone from 'phone';
import { fetchBackend } from '../helpers/fetch';
import {DateTime} from 'luxon';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';
import 'moment/locale/es'
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


const timezone = DateTime.now().zoneName;

class flagCountryIndicator {
  init(params) {
    const element = document.createElement('span');
    const imageElement = document.createElement('img');
    const countryElement = document.createElement('span');
    const noCountryElement = document.createElement('small');
    
    if(params.value){
      imageElement.src = `https://flagcdn.com/${(params.value).toLowerCase()}.svg`;
      imageElement.width = '25';
      imageElement.className = 'me-2'
      
      element.appendChild(imageElement);
      element.appendChild(countryElement);
      element.appendChild(document.createTextNode(params.value));
        this.eGui = element;
      } else {
        noCountryElement.innerHTML = '<strong>No válido</strong>'
        element.appendChild(noCountryElement);
        this.eGui = element;
      }
    }
    getGui() {
      return this.eGui;
    }
  }
  
  
  // Datetime renderer
  class dateTimeFormater {
    init(params) {
      const element = document.createElement('span');
      
      if(params.value){
        const hourConvertion = DateTime.fromISO(params.value).setZone(timezone).toFormat('D tt');
        element.innerText = hourConvertion
        this.eGui = element;
      }
    }
    getGui() {
      return this.eGui;
    }
  }
  

  // class deliveredMessageIndicator {
  //   init(params) {
  //     const element = document.createElement('div');
  //     const iconElement = document.createElement('i');
  //     const stateElement = document.createElement('span');
      
  //     if(params.value.state === 'true'){
  //       stateElement.innerText = params.value.language === 'en' ? 'Sent' : 'Enviado'
  //       stateElement.className = 'text-success'
  //       iconElement.className = 'bx bx-check text-success icon-size'
        
  //       element.className = 'text-center d-flex align-items-center px-0'
  //       element.appendChild(iconElement);
  //       element.appendChild(stateElement);
        
  //       this.eGui = element;
  //     } else {
  //       stateElement.innerText = params.value.language === 'en' ? 'Not sent' : 'No enviado'
  //       stateElement.className = 'text-danger'
  //       iconElement.className = 'bx bx-x text-danger icon-size'
        
  //       element.className = 'text-center d-flex align-items-center px-0'
  //       element.appendChild(iconElement);
  //       element.appendChild(stateElement);
        
  //       this.eGui = element;
  //     }
  //   }
  //   getGui() {
  //     return this.eGui;
  //   }
  // }
  
  
  const Historial = () => {
    
    const { user } = useAuth();
    const { messagesToShow, toggleSidebar, setMessagesToShow } = useContext(ElementContext);
  
    const { t } = useTranslation();

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ height: '80%' }), []);
    const gridStyle = useMemo(() => ({ height: '80vh', marginTop: '5px', marginRight: '3px' }), []);
    const [columnDefs, setColumnDefs] = useState([
    { headerName: t('status'),
      field: 'estadorecepcion',
      minWidth: 100,
      valueGetter: () => {
        const translatedTexts = {
          'msgNotSent': t('msgNotSent'),
          'sentMsg': t('sentMsg'),
          'receivedMsg': t('receivedMsg'),
          'readMsg': t('readMsg'),
          'playedMsg': t('playedMsg'),
        }
        return translatedTexts
      },
      cellRenderer: AckMessageRenderer },
    // {
    //   headerName: t('status'),
    //   field: 'estado',
    //   minWidth: 130,
    //   filterParams: {
    //     valueGetter: params => {
    //       let status;
    //       params.data.estado === 'true' ? status = 'Enviado' : status = 'No enviado'
    //       return status
    //     }
    //   },
    //   cellRenderer: deliveredMessageIndicator,
    //   valueGetter: (params) => {
    //     const state = params.data.estado
    //     const language = i18n.resolvedLanguage
    //     return {state, language}
    //   }
    // },
    { headerName: t('date'), field: 'fechacreacion', sort: 'desc', minWidth: 180, cellRenderer: dateTimeFormater },
    { headerName: t('fromNumber'), field: 'numeroemisor', minWidth: 180, cellClass: 'numberType' },
    { headerName: t('fromName'), field: 'nombreemisor', minWidth: 180 },
    {
      headerName: t('to'),
      field: 'numerodestinatario',
      minWidth: 140,
      cellClass: 'numberType'
    },
    { headerName: t('name'), field: 'nombredestinatario', minWidth: 140 },
    { headerName: t('lastname'), field: 'apellidodestinatario', minWidth: 140 },
    { headerName: t('company'), field: 'empresadestinatario', minWidth: 180 },
    { headerName: t('position'), field: 'cargodestinatario', minWidth: 140 },
    {
      headerName: t('country'),
      field: 'paisdestinatario',
      valueGetter: (params) => {
        const verify = params.data.numerodestinatario.slice(0, 3) === '503' ? {countryIso2:'SV'} : phone(`+${params.data.numerodestinatario}`)
        return verify.countryIso2
      },
      minWidth: 100,
      cellRenderer: flagCountryIndicator
    },
    { headerName: t('message'), field: 'mensajeenviado', minWidth: 250, wrapText: false, autoHeight: false },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1, sortable: true, filter: true
    };
  }, []);

  
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  
  const defaultExcelExportParams = useMemo(() => {
    return {
      processCellCallback: (params) => {
        if(!params.value){
          return '-'
        } else if(params.value.state !== undefined && params.value.state === 'true' && params.value.language === 'es'){
          return 'Enviado'
        } else if(params.value.state !== undefined && params.value.state === 'false' && params.value.language === 'es'){
          return 'No enviado'
        } else if(params.value.state !== undefined && params.value.state === 'true' && params.value.language === 'en'){
          return 'Sent'
        } else if (params.value.state !== undefined && params.value.state === 'false' && params.value.language === 'en'){
          return 'Not sent'
        } else {
          return params.value
        }
      },
    };
  }, []);


  const handleUpdateTable = async() => {
    const tenant = user.email.replace('@', '_')
    try {
      const getHistorial = await fetchBackend(`history-by-tenant/${tenant}`);
      const parseHistorial = await getHistorial.json();
      const dataHistorial = await parseHistorial.data;
      setMessagesToShow(dataHistorial);
    } catch (error) {
        console.log(error)
    }
  }


  const excelStyles = [
      {
        id: 'numberType',
        numberFormat: {
          format: '0',
        }
      }
  ];
  
      

  return (
    <div className='home col-12 animate__animated animate__fadeIn' style={containerStyle}>

      <div className="d-flex align-items-center justify-content-between">
        <div className={ toggleSidebar ? 'titles-margin-close mb-4' : 'titles-margin-open mb-4' }>
            <div className="text">
              {t('historyTitle')}
            </div>
            <p className='subtitle mb-0'>
              {t('historySubtitle')}
            </p>
        </div>

        <div className='btn-group btn-group-sm me-5'>
          <button
            className='btn btn-sm btn-mediumblue'
            onClick={handleUpdateTable}
          >
            {t('updateTable')}
          </button>

          <button
            className='btn btn-sm btn-lightblue'
            onClick={onBtExport}
          >
            {t('export')}
          </button>
        </div>
      </div>
      
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flexGrow: '1' }}>
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={messagesToShow}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection={'multiple'}
              animateRows={true}
              excelStyles={excelStyles}
              enableRangeSelection={true}
              suppressClipboardPaste={false}
              defaultExcelExportParams={defaultExcelExportParams}
              suppressAggFuncInHeader={true}
              enableCellChangeFlash={true}
              pagination={true}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historial;