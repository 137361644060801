import React, {useContext} from 'react'
import { ElementContext } from './ElementContext';
import ContactList from './ContactList';
import GroupList from './GroupList';
import { useTranslation } from 'react-i18next';


const ListsManagement = () => {
  
  const { toggleSidebar } = useContext(ElementContext);
  const { t } = useTranslation();

  return (
    <div className='home animate__animated animate__fadeIn'>
      <div className={ toggleSidebar ? 'titles-margin-close mb-4' : 'titles-margin-open mb-4' }>
          <div className="text">
            {t('listsTitle')}
          </div>
          <p className='subtitle mb-0'>
            {t('listsSubtitle')}
          </p>
      </div>
      <div className="container-fluid">
        <div className="row">
            <GroupList/>
            <ContactList/>
        </div>
      </div>
    </div>
  )
}

export default ListsManagement