import { fetchBackend, fetchPythonBackend } from "./fetch";


export const getGlobalEnv = async() => {
    const getGlobalSettings = await fetchBackend('settings/cloud@seidoranalytics.com');
    const bodySettings = await getGlobalSettings.json();
    const resSettings = await bodySettings.data[0].configuracion;
    const data = await JSON.parse(resSettings);

    return data
}


export const getGlobalUserInformation = async(email) => {

    // Checkeo de la API KEY, si no tiene uno, se le genera
    // const userApiKey = localStorage.getItem('USER_API_KEY')
    // if(!userApiKey){
    //     const body = { 'tenantId': email.replace('@', '_') }
    //     const apiKeyRequest = await fetchPythonBackend('api/generate-token', body, 'POST');
    //     const apiKeyResponse = await apiKeyRequest.json()
        
    //     if( apiKeyResponse.ok ){
    //         localStorage.setItem('USER_API_KEY', apiKeyResponse.token)
    //     }
    // }

    // Obtener información global y personal del usuario
    const getGlobalUserInformation = await fetchBackend(`all-user-information/${email}`)
    const respInformation = await getGlobalUserInformation.json()
    const { contactos, historial, listas, tenants, globalSettings, userSettings, whatsappCount, smsCount, templates, executedJobs, schedules, userInfo, whatsappCountTrial, smsCountTrial, aiTemplatesGeneratedCount } = await respInformation.data
    // , extractions, integrations, executedExtractions

    const standardTemplates = templates.filter( templates => templates.tipoplantilla === 'standard' || templates.tipoplantilla === 'ai-saved' )
    const aiTemplatesGenerated = templates.filter( templates => templates.tipoplantilla === 'ai-generated' )
    const aiTemplatesSaved = templates.filter( templates => templates.tipoplantilla === 'ai-saved' )

    return { contactos, historial, listas, tenant: tenants, globalSettings, userSettings, whatsappCount, smsCount, whatsappCountTrial, smsCountTrial, standardTemplates, aiTemplatesGenerated, aiTemplatesSaved, executedJobs, schedules, userInfo, aiTemplatesGeneratedCount }
    // , extractions, integrations, executedExtractions
}


export const getEnv = async() => {
    const getGlobalSettings = await fetchBackend('settings/cloud@seidoranalytics.com');
    const bodySettings = await getGlobalSettings.json();
    const resSettings = await bodySettings.data[0].configuracion;
    const data = await JSON.parse(resSettings);
    return data
}
