import React, { useContext, useState } from 'react';
import '../styles/sidebar.css'
import { ElementContext } from './ElementContext';
import { useTranslation } from 'react-i18next';
import MultisessionModal from './modals/MultisessionModal';
import WhatsAppMultisessionForm from './whatsappMultisesiones/WhatsAppMultisessionForm';
import SessionsTable from './whatsappMultisesiones/SessionsTable';
import { fetchBackend } from '../helpers/fetch';
import Swal from 'sweetalert2';



const FormularioWhatsAppMultisesion = () => {
    
    const { toggleSidebar, multiSessionUsuarioEmisor, multiSessionReady, setMultiSessionReady, tenantId, setMultiSessionUsuarioEmisor, setQRSolicitado, setIsMultiSessionAuthenticated, gettingSessions, setGettingSessions } = useContext(ElementContext);
    
    const { t } = useTranslation();
    
    const handleGetAllSavedSessions = async() => {
        setGettingSessions(true)
        let contador = 0;
        let arrayUser = []

        let timerId = setTimeout(async function sendMessage() {
            const checkIfSessionExists = await fetchBackend(`check-session/${tenantId}${contador}`)
            const checkResp = await checkIfSessionExists.json()

            if(checkResp.sessionExists && checkResp.info){
                const {pushname, wid} = await checkResp.info;
                arrayUser.push({
                    nombreEmisor: pushname,
                    numeroEmisor: wid.user,
                    ordenUsuario: contador,
                    tenantSession: tenantId + contador
                })
            }
            
            contador++
            if(contador === 10){
                setGettingSessions(false)
                setQRSolicitado(false)
                setIsMultiSessionAuthenticated(true)
                clearTimeout(timerId);

                if(arrayUser.length === 0){
                    Swal.fire(t('done'), t('notSavedSessions'), 'success')
                }
            } else {
                timerId = setTimeout(sendMessage, 2 * 1000);
            }
        }, 2 * 1000);
        
        setMultiSessionUsuarioEmisor(arrayUser)
    }


    return multiSessionReady ? (
      <div className="home animate__animated animate__fadeIn">
        <WhatsAppMultisessionForm />

        <div>
            <button
                className='btn btn-sm btn-outline-mediumblue d-flex align-items-center'
                onClick={ () => setMultiSessionReady(false) }
            >
                <i className='bx bxs-left-arrow me-2'></i>
                {t('manageSessions')}
            </button>
        </div>
      </div>
    ) : (
        <div className="home">
            <div className="d-flex align-items-center justify-content-between">
                <div className={ toggleSidebar ? 'titles-margin-close' : 'titles-margin-open' }>
                    <div className="text">
                        {t('multisessionMsgsTitle')}
                    </div>
                    <p className='subtitle mb-0'>
                        {t('multisessionMsgsSubtitle')}
                    </p>
                </div>
            </div>
            
            <div className="row w-100">
                <h5 className='text-center text-mediumblue mt-5 mb-3'>
                    {t('youHave')} {multiSessionUsuarioEmisor.length} {t('sessionStartedSoFar')}
                </h5>
            </div>

            <div className="row w-100 mb-5">
                <div className='d-flex justify-content-center'>
                    <button
                        className='btn btn-sm btn-outline-lightblue'
                        onClick={ handleGetAllSavedSessions }
                        disabled={ gettingSessions || multiSessionUsuarioEmisor.length > 0 }
                    >
                        {
                            gettingSessions ? (
                                <div className='d-flex align-items-center justify-content-center'>
                                  <span className='text-lightblue'>{t('processing')}...</span>
                                  <div className="spinner-grow spinner-grow-sm text-lightblue ms-2" role="status">
                                      <span className="visually-hidden">...</span>
                                  </div>
                                </div>
                            ) : t('getSavedSessions')
                        }
                    </button>
                </div>
            </div>

            <SessionsTable />
            
            <div className='mt-5'>
                <button
                    className='btn btn-sm btn-outline-mediumblue d-flex align-items-center'
                    onClick={ () => setMultiSessionReady(true) }
                    disabled={!(multiSessionUsuarioEmisor.length > 0)}
                >
                    {t('sendMsgs')}
                    <i className='bx bxs-right-arrow ms-2'></i>
                </button>
            </div>

            <MultisessionModal />
        </div>
    )
  };

  
  export default FormularioWhatsAppMultisesion;
  