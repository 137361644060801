import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { handleDeleteCredentials } from '../../helpers/whatsapp-api';
import { useAuth } from '@frontegg/react';
import Swal from 'sweetalert2';
import { refreshPage } from '../../helpers/utils';


Modal.setAppElement('#root');


const DeleteWACredentials = ({credentials}) => {

    const { confirmDeleteModal, setConfirmDeleteModal, userConfiguration, setwAPIUserInformation, wAPIUserInformation, setUserConfiguration } = useContext(ElementContext);
    const { t } = useTranslation();
    const { user } = useAuth();


    const closeModal = () => {
        setConfirmDeleteModal(false)
    }

    const handleDeleteCard = async() => {
        const email = await user.email
        const deleteCredentials = await handleDeleteCredentials(userConfiguration, credentials, email)
        const deleteSuccess = await deleteCredentials
    
        if(deleteSuccess){
            refreshPage('/whatsapp-api-credentials')
        } else {
            Swal.fire(t('problem'), 'No se ha podido eliminar', 'error')
        }
        
        setConfirmDeleteModal(false)
    }

    
    return (
        <Modal
            isOpen={confirmDeleteModal}
            closeTimeoutMS={ 200 }
            overlayClassName="modal-fondo"
            className="modal-delete-credentials"
        >
            <h5 className='text-center text-blackseidor'>
              Confirmar eliminación
            </h5>
            <hr />

            <p className='text-center py-4'>
                ¿Estás seguro de que deseas eliminar <strong className='text-mediumblue'>{credentials && credentials.verified_name}</strong>?
            </p>

            <hr />

            <div className='d-flex justify-content-center mt-4'>
                <button
                    className='btn btn-sm btn-mediumblue'
                    onClick={ handleDeleteCard }
                >
                    Eliminar
                </button>
                <button
                    className='btn btn-sm btn-91 ms-2'
                    onClick={ closeModal }
                >
                    Cancelar
                </button>
            </div>
            
        </Modal>
        )
}

export default DeleteWACredentials