import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { ElementContext } from '../ElementContext';
import {fetchBackend} from '../../helpers/fetch'
import Swal from 'sweetalert2';


const SelectListFromContacts = () => {
    
    const { setInputList, setModalIsOpen, cloudConnectionsList } = useContext(ElementContext);
    const { t } = useTranslation();
    const [listRequested, setListRequested] = useState(false)


    const handleAddList = async(lista) => {
        setListRequested(true)
        const { fileUrl } = lista
        const recipientsRequest = await fetchBackend('get-spreadsheet-recipients', {fileUrl}, 'POST')
        const recipientsResponse = await recipientsRequest.json()

        if(recipientsResponse.recipients === 'notfound'){
            Swal.fire(t('warning'), 'No se han encontrado resultados. Por favor asegurate de que tu archivo incluya la columna "Destinatario" o "Recipient"', 'warning')
            setModalIsOpen(false)
            setListRequested(false)
            return
        }

        const numbersList = recipientsResponse.recipients.map( recipient => ({numero: recipient}) )
        setInputList(numbersList)
        setModalIsOpen(false)
        setListRequested(false)
    }

  return (
    <div className='animate__animated animate__fadeIn'>
        {
            listRequested ? (
                <div className='d-flex align-items-center justify-content-center py-5'>
                  <h5 className='text-mediumblue'>{t('loadingContacts')}...</h5>
                  <div className="spinner-grow text-mediumblue ms-2" role="status">
                      <span className="visually-hidden">...</span>
                  </div>
                </div>
            ) : (
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th className='ps-4'>{t('name')}</th>
                        <th>{t('platform')}</th>
                        <th>{t('fileName')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        cloudConnectionsList.map(list => (
                        <tr
                            className='pointer'
                            key={list.idlist}
                            onClick={ () => handleAddList(list) }
                        >
                            <th className='ps-4'>{list.listname}</th>
                            <td>{list.platform}</td>
                            <td>{list.fileName}</td>
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
            )
        }
    </div>
  )
}

export default SelectListFromContacts