import React from 'react';
import { useTranslation } from 'react-i18next';

const CheckingLoader = () => {
  
  const { t } = useTranslation();

  return (
    <div style={{ height: '100vh' }} className='d-flex justify-content-center align-items-center'>
        <h5 className='text-mediumblue mb-0 me-3'>
          {t('checkLoader')}...
        </h5>
        <div className="spinner-grow text-mediumblue" role="status">
            <span className="visually-hidden">...</span>
        </div>
    </div>
  )
}

export default CheckingLoader