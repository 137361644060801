import React, {useContext, useState} from 'react';
import countries from 'i18n-iso-countries';
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import { apiBaseUrl, fetchBackend } from '../../helpers/fetch';
import { DateTime } from 'luxon';
import checkLastDayOfMonth from 'is-last-day-of-month';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import phone from 'phone';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from '@frontegg/react';
import axios from 'axios';
registerLocale('es', es)
setDefaultLocale('es');


countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));


const timezone = DateTime.now().zoneName;
Modal.setAppElement('#root');

// Uso del replace insensitive-case
String.prototype.replaceAll = function(strReplace, strWith) {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};


const ScheduleWhatsAppMessages = () => {
    const { user } = useAuth();
    const { scheduleModal, setScheduleModal, infoToShow, nombreEmisor, numeroEmisor, scheduleMessage, setScheduleMessage, setScheduleToShow, madeByUser, delayMsg, frequencySelected, setFrequencySelected, oscillationMsg, whatsappFilesName, archivos, archivosImagen } = useContext(ElementContext);
    const { t, i18n } = useTranslation();

    // console.log(infoToShow)

    const [startDate, setStartDate] = useState(new Date());

    const closeModal = () => {
        setScheduleModal(false)
    }

    const handleCloseModal = (e) => {
        e.preventDefault();
        setScheduleModal(false)
    }
    
    // Envío de configuración del CRON
    const handleSubmit = async(e) => {
        e.preventDefault();
        const tenant = user.email.replace('@', '_')

        let hour;
        let min;
        let date;
        let month;
        let dayWeek;
        let fullYear;
        let stringCron;
        let serverHour;

        const idlistaInfo = infoToShow[0].idlista;
        console.log(idlistaInfo)

        switch (frequencySelected) {
            case 'once':
                hour = document.querySelector('#msgHoras3').value;
                min = document.querySelector('#msgMinutos3').value;
                date = startDate.getDate();
                month = parseInt(startDate.getMonth()) + 1;
                fullYear = startDate.getFullYear();
                dayWeek = '*';
                serverHour = hour - (DateTime.now().offset / 60);
                
                if(hour.length === 1){
                    hour = `0${hour}`
                }
                if(min.length === 1){
                    min = `0${min}`
                }

                stringCron = `${t('on')} ${date}/${month}/${fullYear} ${t('at').toLowerCase()} ${hour}:${min} hs`
                break;

            case 'onceperday':
                hour = document.querySelector('#msgHoras3').value;
                min = document.querySelector('#msgMinutos3').value;
                serverHour = hour - (DateTime.now().offset / 60);

                date = '*';
                month = '*';
                dayWeek = '*';
                
                if(hour.length === 1){
                    hour = `0${hour}`
                }
                if(min.length === 1){
                    min = `0${min}`
                }

                stringCron = `${t('everyDayAt')} ${hour}:${min} hs`
                break;
                
            case 'onceperweek':
                hour = document.querySelector('#msgHoras3').value;
                min = document.querySelector('#msgMinutos3').value;
                dayWeek = document.querySelector('#msgDayOfWeek').value;
                serverHour = hour - (DateTime.now().offset / 60);

                const stringDayWeek = document.querySelector('#msgDayOfWeek').selectedOptions[0].innerText;
                
                date = '*';
                month = '*';
                
                if(hour.length === 1){
                    hour = `0${hour}`
                }
                if(min.length === 1){
                    min = `0${min}`
                }
                
                stringCron = `${t('every')} ${stringDayWeek} ${t('at').toLowerCase()} ${hour}:${min} hs`
                break;
                
            case 'oncepermonth':
                hour = document.querySelector('#msgHoras3').value;
                min = document.querySelector('#msgMinutos3').value;
                date = document.querySelector('#oneDayPerMonth').value;
                serverHour = hour - (DateTime.now().offset / 60);

                month = '*';
                dayWeek = '*';

                if(hour.length === 1){
                    hour = `0${hour}`
                }
                if(min.length === 1){
                    min = `0${min}`
                }
                
                stringCron = `${t('onDate')} ${date} ${t('everyMonth')}, ${t('at').toLowerCase()} ${hour}:${min} hs`
                break;
        
            default:
                Swal.fire(t('warning'), t('pleaseConfigureYourSchedule'), 'warning')
                break;
        }


        const to = [];
        let data = {};
        let body;
        let estado;
        const isLastDayOfTheMonth = checkLastDayOfMonth();

        // Corrige los casos donde el número sea inexistente en término de "horas reloj"
        if(serverHour > 23 && isLastDayOfTheMonth){
            // Manejo para el caso que la diferencia horaria sea el último día del mes
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)

            serverHour = serverHour - 24;
            date = tomorrow.getDate();
            month = tomorrow.getMonth() + 2; // Se le suma 2, porque en JS Enero comienza con 0

        } else if(serverHour > 23){
            // Manejo de diferencia horaria cuando cambia el día
            serverHour = serverHour - 24;
            date = date + 1;
        }

        const timeToSend = {
            min,
            localHour: hour,
            serverHour,
            date,
            month,
            dayWeek,
            frequencySelected
        }

        infoToShow.map( contacto => to.push(contacto.numero) )

        const arrayMessages = []
        const arrayEstados = []
        const parsedNumbers = []

        for(let num in to){
            // Verifica si el mensaje se va a enviar o no, por ser número inexistente
            let number = to[num];
            if(!number.includes('+')){
                number = '+' + to[num]
            }
            estado = phone(number).isValid;


            const parsedNum = await to[num].replace(/[^0-9]/g, '')
            parsedNumbers.push(parsedNum)

            
            // Si hay archivos, enviarlos a la API
            if(archivos !== null || archivosImagen !== null){
                const formData = new FormData();

                if(archivos !== null){
                    for( let i=0; i<archivos.length; i++ ){
                        formData.append("files", archivos[i])
                    }
                }
                if(archivosImagen !== null){
                    for( let i=0; i<archivosImagen.length; i++ ){
                        formData.append("files", archivosImagen[i])
                    }
                }

                await axios.post(`${apiBaseUrl}/api/send-media`, formData)
                        .then(res => 'Archivos subidos')
                        .catch(error => console.log(error))
            }
            
            
            // Parseo de la clave PAIS
            const countryShortname = await infoToShow[num].pais || '';
            const countryFullname = countries.getName(countryShortname, i18n.resolvedLanguage) || ''


            // Reemplazos de variables en el cuerpo del mensaje
            let newMessages;
            if(i18n.resolvedLanguage === 'en'){
                newMessages = scheduleMessage.replaceAll('$name$', infoToShow[num].nombre)
                                        .replaceAll('$lastname$', infoToShow[num].apellido)
                                        .replaceAll('$position$', infoToShow[num].cargo)
                                        .replaceAll('$company$', infoToShow[num].empresa)
                                        .replaceAll('$country$', countryFullname)
                                        .replaceAll('$phone$', infoToShow[num].numero)
                                        .replaceAll('$observations$', infoToShow[num].observaciones)
            } else {
                newMessages = scheduleMessage.replaceAll('$nombre$', infoToShow[num].nombre)
                                        .replaceAll('$apellido$', infoToShow[num].apellido)
                                        .replaceAll('$cargo$', infoToShow[num].cargo)
                                        .replaceAll('$empresa$', infoToShow[num].empresa)
                                        .replaceAll('$pais$', countryFullname)
                                        .replaceAll('$numero$', infoToShow[num].numero)
                                        .replaceAll('$observaciones$', infoToShow[num].observaciones)
            }

            arrayMessages.push(newMessages)
            arrayEstados.push(estado)
        }
        
        
        data = {
            datosEmisor: {nombreEmisor, numeroEmisor},
            datosDestinatarios: infoToShow, //Array con todos los datos de los destinatarios
            messages: arrayMessages, //Array con msgs reemplazados
            arrayEstados,
            numbers: parsedNumbers,  //Array con los numeros destinatarios
            timeToSend,
            scheduleMessage, //Mensaje genérico, sin reemplazo de parámetros
            idListaSchedule: idlistaInfo,//#ID de lista
            fechacreacion: DateTime.now().setZone(timezone),
            timezone,
            usuario: madeByUser,
            tenantid: tenant,
            time: parseInt(delayMsg),
            oscillationMsg,
            canal: 'WhatsApp',
            filesName: whatsappFilesName
        }
        console.log(data)
  
        const resultado = await fetchBackend( 'scheduled-whatsapp-messages', data, 'POST' )
        body = await resultado.json();
  
        if( body.ok ){
            Swal.fire({
                title: t('scheduled'),
                icon: 'success',
                html: `
                    <p>${t('willBeSent')} ${stringCron}</p>
                    <small className='mb-0'>${t('remindingNotCloseSession')}</small>
                `
            })
            setScheduleMessage('')
        } else {
            Swal.fire('Oops', t('problem'), 'error')
        }

        setTimeout( async() => {
            // Get Schedule
            const getNewSchedule = await fetchBackend(`schedule/${tenant}`);
            const bodyNewSchedule = await getNewSchedule.json();
            const newScheduleData = await bodyNewSchedule.data;
            setScheduleToShow(newScheduleData)
        } , 2000)

        setScheduleModal(false)
    }

    
    const handleSelectChange = () => {
        const optionSelected = document.querySelector('#frecuencia').selectedOptions[0].value;
        setFrequencySelected(optionSelected)
    }

    

  return (
        <Modal
            isOpen={scheduleModal}
            onRequestClose={closeModal}
            closeTimeoutMS={ 200 }
            className="modal-schedule"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center'>{t('scheduleModalTitle')}</h4>
            
            <form onSubmit={handleSubmit} >
                <div className='d-block my-4'>
                    {/* Seleccionar frecuencia */}
                    <label htmlFor="frecuencia" className='me-3'>{t('frequency')}:</label>
                    <select name="frecuencia" id="frecuencia" onChange={ handleSelectChange }>
                        <option value="seleccionar" hidden>{t('select')}</option>
                        <option value="once">{t('once')}</option>
                        <option value="onceperday">{t('daily')}</option>
                        <option value="onceperweek">{t('weekly')}</option>
                        <option value="oncepermonth">{t('monthly')}</option>
                    </select>
                </div>
                
                {
                    (frequencySelected === 'once') ? (
                        <div className='d-flex align-items-center mb-1'>
                            <label htmlFor="msgDia" className='label-width'>{t('date')}:</label>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                minDate={startDate}
                            />
                        </div>
                    ) : null
                }
                
                {
                    (frequencySelected === 'onceperweek') ? (
                        <div className="d-block mt-4">
                            {/* Seleccionar DIA DE LA SEMANA */}
                            <label htmlFor="msgDayOfWeek" className='label-width-2'>
                                {t('dayOfTheWeek')}:
                            </label>
                            <select
                                title={t('dayOfWeekSelected')}
                                className='mx-1'
                                name="msgDayOfWeek"
                                id="msgDayOfWeek"
                                onInvalid={(e)=>{e.target.setCustomValidity(t('dayOfWeekSelected'))
                                }}
                                onChange={(e)=>{e.target.setCustomValidity('')}}
                                required
                            >
                                <option value="" hidden>{t('select')}</option>
                                <option value="1">{t('monday')}</option>
                                <option value="2">{t('tuesday')}</option>
                                <option value="3">{t('wednesday')}</option>
                                <option value="4">{t('thursday')}</option>
                                <option value="5">{t('friday')}</option>
                                <option value="6">{t('saturday')}</option>
                                <option value="7">{t('sunday')}</option>
                            </select>
                        </div>
                    ) : null
                }
                
                {
                    (frequencySelected === 'oncepermonth') ? (
                        <div className="d-flex align-items-center mt-4">
                            {/* Seleccionar DIA DEL MES */}
                            <label htmlFor="oneDayPerMonth" className='label-width-3'>
                                {t('dayOfMonth')}:
                            </label>
                            <input
                                title={t('dayOfMonth')}
                                id='oneDayPerMonth'
                                type='number'
                                min={1}
                                max={31}
                                className='ms-2 me-1'
                                placeholder='-'
                                onInvalid={(e)=>{e.target.setCustomValidity(t('dayOfMonthSchedulesRequired'))
                                }}
                                onChange={(e)=>{e.target.setCustomValidity('')}}
                                required
                            />
                                {t('everyMonth')}
                        </div>
                    ) : null
                }

                {
                    (frequencySelected === 'once' || frequencySelected === 'onceperday' || frequencySelected === 'onceperweek' || frequencySelected === 'oncepermonth') ? (
                        <div className='d-flex justify-content-between'>
                            <div className="d-block mt-2">
                                {/* Seleccionar HORAS */}
                                <label htmlFor="msgHoras3" className='label-width'>{t('at')}:</label>
                                <input
                                    title={t('hours')}
                                    id='msgHoras3'
                                    type='number'
                                    min={0}
                                    max={23}
                                    className='ms-2 me-1'
                                    placeholder='-'
                                    onInvalid={(e)=>{e.target.setCustomValidity(t('hourSchedulesRequired'))
                                    }}
                                    onChange={(e)=>{e.target.setCustomValidity('')}}
                                    required />
                                Hs
                                {/* Seleccionar MINUTOS */}
                                <input
                                    title={t('minutes')}
                                    id='msgMinutos3'
                                    type='number'
                                    min={0}
                                    max={59}
                                    className='ms-2 me-1'
                                    placeholder='-'
                                    onInvalid={(e)=>{e.target.setCustomValidity(t('minSchedulesRequired'))}}
                                    onChange={(e)=>{e.target.setCustomValidity('')}}
                                    required
                                />
                                Min

                            </div>
                            <p className='ms-3 mb-0 hoursystem'>* {t('hourSystem')}</p>
                        </div>
                    ) : null
                }



                <div className='d-flex justify-content-center mt-4'>
                    <button
                        type='submit'
                        className='btn btn-sm btn-darkblue me-2'
                        disabled={ frequencySelected === 'seleccionar' }
                    >
                        {t('confirm')}
                    </button>

                    <button
                        className='btn btn-sm btn-lightblue'
                        onClick={ handleCloseModal }
                    >
                        {t('cancel')}
                    </button>
                </div>
            </form>
            
        </Modal>
  )
}

export default ScheduleWhatsAppMessages