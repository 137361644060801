import React, { useState } from 'react';
import { FronteggProvider } from '@frontegg/react';
import Application from './Application';
import { getEnv } from './helpers/globalSettings';
import MainLoader from './components/MainLoader';
import { fetchBackend } from './helpers/fetch';
import { useLocation } from 'react-router-dom';
import './styles/modal.css';

const themeOptions = {
  loginBox: {
      submitButtonTheme: {
          base: {
              backgroundColor: '#263c7a',
              color: '#fff',
              borderColor: 'transparent'
          }
      },
      inputTheme: {
          base: {
              borderColor: '#66b6ff',
          }
      }
  }
}

const App = () => {

  const location = useLocation();
  const [fronteggBaseUrl, setFronteggBaseUrl] = useState(null)
  const [pythonBackendUrl, setPythonBackendUrl] = useState(null)

  const getFronteggUrl = async(field) => {
    // Validar token
    const verifyToken = await fetchBackend('verify-token')
    const verifyResponse = await verifyToken.json()

    
    if( verifyResponse.ok ){
      
      const getAllEnvDataFromDB = await getEnv()
      const fronteggUrl = await getAllEnvDataFromDB[field]
      const pythonBackendUrl = await getAllEnvDataFromDB['REACT_APP_PYTHON_BACKEND_URL']
  
      setFronteggBaseUrl(fronteggUrl)
      setPythonBackendUrl(pythonBackendUrl)

    } else {

      const generateToken = await fetchBackend('generate-token')
      const tokenResponse = await generateToken.json()
      const token = await tokenResponse.token
      localStorage.setItem('hbg-token', token)

      // window.location.replace('/?plan=trial');
      // window.location.replace('/');

      if (
        !location.search.includes('personal') && 
        !location.search.includes('pro') && 
        !location.search.includes('business')
      ) {

        window.location.replace('/?plan=trial');

      } else {
        window.location.replace(location.pathname + location.search);
      }
      
    }
  }

  getFronteggUrl('REACT_APP_FRONTEGG_API')
  
  
  const contextOptions = {
    baseUrl: fronteggBaseUrl
  };


  return  fronteggBaseUrl ? (
    <FronteggProvider contextOptions={contextOptions} themeOptions={themeOptions}>
        <Application pythonBackendUrl={pythonBackendUrl} />
    </FronteggProvider>
  ) : (
    <>
      <MainLoader />
    </>
  )
};

export default App;