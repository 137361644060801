import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { deleteWhatsappApiTemplate, getWhatsappApiTemplates } from '../../helpers/whatsapp-api';
import { useAuth } from '@frontegg/react';


Modal.setAppElement('#root');


const WAPIEditTemplateModal = ({templateData}) => {
    
    const { user } = useAuth();
    const { WAPIEditTemplateModalOpen, setWAPIEditTemplateModalOpen, userConfiguration, selectedWACredentials, setWhatsappTemplates, setApprovedTemplates } = useContext(ElementContext)
    const { t, i18n } = useTranslation();
    const [editMode, setEditMode] = useState(true)

    const closeModal = () => {
        setWAPIEditTemplateModalOpen(false)
    }

    

    const handleDeleteTemplate = async(e) => {
        e.preventDefault()
        const email = await user.email
        const allWACredentials = await userConfiguration.whatsappAPIcredentials
        const WACredentials = await allWACredentials.filter( cred => cred.phoneNumberId === selectedWACredentials )[0]
        const deleteReq = await deleteWhatsappApiTemplate(await WACredentials, await templateData.name)

        if(deleteReq.success){
            const whatsappApiTemplates = await getWhatsappApiTemplates(WACredentials)
            const allTemplates = whatsappApiTemplates.allTemplates
            const approvedTemplates = whatsappApiTemplates.approvedTemplates
            setWhatsappTemplates(allTemplates)
            setApprovedTemplates(approvedTemplates)
        }

        closeModal()
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setEditMode(true)
        closeModal()
    }


  return (
        <Modal
            isOpen={WAPIEditTemplateModalOpen}
            closeTimeoutMS={ 200 }
            className="modal-usersettings"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center'>Template</h4>
            <hr />

            <form>
                <div className="mb-3">
                    <label htmlFor="tempName" className="form-label template-labels mb-0">
                        {t('name')}
                    </label>
                    <div className='wa-message-box-modal py-1 px-2'>
                        <span>{templateData.name}</span>
                    </div>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="tempLang" className="form-label template-labels mb-0">
                        {t('language')}
                    </label>
                    <div className='wa-message-box-modal py-1 px-2'>
                        <span>{templateData.language.includes('es') ? t('spanish') : t('english')}</span>
                    </div>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="tempCateg" className="form-label template-labels mb-0">
                        {t('category')}
                    </label>
                    <div className='wa-message-box-modal py-1 px-2'>
                        <span>{templateData.category}</span>
                    </div>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="tempMsg" className="form-label mb-0">{t('bodyMessage')}</label>
                    
                    <div className='wa-message-box-modal'>
                        <h6 className='wa-header'>{ templateData.components[0].text }</h6>
                        <p className='wa-body'>{ templateData.components[1].text }</p>
                        <span className='wa-footer'>{ templateData.components[2].text }</span>
                    </div>
                </div>

                <div className='mt-4 btn-group w-100'>
                    <button
                        className='btn btn-sm btn-lightblue'
                        onClick={ handleDeleteTemplate }
                    >
                        {t('delete')}
                    </button>
                    <button
                        className='btn btn-sm btn-91'
                        onClick={ handleCancel }
                    >
                        {t('close')}
                    </button>
                </div>
            </form>
        </Modal>
  )
}

export default WAPIEditTemplateModal