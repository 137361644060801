import React, {useContext, useState} from 'react'
import {ElementContext} from '../ElementContext';
import WAPINewTemplateModal from '../modals/WAPINewTemplateModal'
import WhatsappTemplatesTable from '../tables/WhatsappTemplatesTable'
import { Icon } from '@iconify/react';
import { getWhatsappApiTemplates } from '../../helpers/whatsapp-api';
import WhatsappAPISelector from './WhatsappAPISelector';
import { useTranslation } from 'react-i18next';

const WhatsappAPITemplates = () => {
    
    const { setWhatsAPIModalOpen, setWhatsappTemplates, userConfiguration, setApprovedTemplates } = useContext(ElementContext)
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)


    const handleUpdateTable = async() => {
        setLoading(true)
        const whatsappApiTemplates = await getWhatsappApiTemplates()
        const allTemplates = whatsappApiTemplates.allTemplates
        const approvedTemplates = whatsappApiTemplates.approvedTemplates
        setWhatsappTemplates(allTemplates)
        setApprovedTemplates(approvedTemplates)
        setLoading(false)
    }

    if(!userConfiguration.whatsappAPIcredentials || userConfiguration.whatsappAPIcredentials.length < 1){
        return <div className='home'>
                    <h1>{t('youDontHaveCreentials')}</h1>
               </div>
    }


  return (
    <div className='home animate__animated animate__fadeIn'>
        <div className="d-flex flex-column justify-content-between table-container pb-3">
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-12">
                        <h2 className='text-mediumblue mt-3 mb-0'>{ t('wApiTemplatesTitle') }</h2>
                        <p className='subtitle'>
                            { t('wApiTemplatesSubtitle') }
                        </p>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row d-flex justify-content-between align-items-end">
                    <div className='col-4'>
                        <WhatsappAPISelector />
                    </div>

                    <div className='col-4 btn-group'>
                        <button
                            disabled={loading}
                            className="btn btn-sm btn-mediumblue d-flex align-items-center justify-content-center"
                            onClick={ () => setWhatsAPIModalOpen(true) }>
                                <Icon icon="ic:baseline-plus" className='icon-size-m me-1' />
                                {t('newTemplate')}
                        </button>
                        <button
                            disabled={loading}
                            className="btn btn-sm btn-lightblue d-flex align-items-center justify-content-center"
                            onClick={ handleUpdateTable }>
                                <Icon icon="dashicons:update-alt" className='icon-size-m me-1' />
                                {t('updateTable')}
                        </button>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <WhatsappTemplatesTable />
                    </div>
                </div>
            </div>
        </div>

        <WAPINewTemplateModal />
    </div>
  )
}

export default WhatsappAPITemplates