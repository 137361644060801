import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import '../../styles/modal.css';
import { useTranslation } from 'react-i18next';
import SelectListFromContacts from './SelectListFromContacts';
import SelectListFromCloud from './SelectListFromCloud';


Modal.setAppElement('#root');

const SelectListModal = () => {
    
    const { setInfoToShow, modalIsOpen, setModalIsOpen, listNames, setActiveList, allTenantContacts } = useContext(ElementContext);
    const { t } = useTranslation();
    const [tabSelected , setTabSelected ] = useState(1)


    const closeModal = () => {
        setModalIsOpen(false)
    }

  return (
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={ 200 }
        className="modal-select-list"
        overlayClassName="modal-fondo"
      >
        <div className='btn-group w-100 mb-4'>
            <button
                className={ tabSelected === 1 ? 'btn btn-lightblue' : 'btn btn-outline-lightblue'}
                onClick={ () => setTabSelected(1) }
            >
                Lista de contactos
            </button>
            <button
                className={ tabSelected === 2 ? 'btn btn-lightblue' : 'btn btn-outline-lightblue'}
                onClick={ () => setTabSelected(2) }
            >
                Lista de archivos
            </button>
        </div>
        {
            tabSelected === 1 ? (
                <SelectListFromContacts />
            ) : (
                <SelectListFromCloud />
            )
        }
      </Modal>
  )
}

export default SelectListModal