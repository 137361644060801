const { fetchPythonBackend } = require("./fetch")


// Uso del replace insensitive-case
String.prototype.replaceAll = function(strReplace, strWith) {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};


const getConvertedMessages = async(rawMessage, contacList, numbers) => {
    const arrayMessages = []
    const currency = 'USD'
    // Reemplazos de variables en el cuerpo del mensaje
    for(let num in numbers){
        const formatDate = new Date(contacList[num].customfields.fechavencimiento).toLocaleDateString()
        const newMessages = await rawMessage.replaceAll('$name$', contacList[num].nombre)
                                        .replaceAll('$lastname$', contacList[num].apellido)
                                        .replaceAll('$position$', contacList[num].cargo)
                                        .replaceAll('$company$', contacList[num].empresa)
                                        .replaceAll('$country$', contacList[num].pais)
                                        .replaceAll('$phone$', contacList[num].numero)
                                        .replaceAll('$observations$', contacList[num].observaciones)
                                        .replaceAll('$nombre$', contacList[num].nombre)
                                        .replaceAll('$apellido$', contacList[num].apellido)
                                        .replaceAll('$cargo$', contacList[num].cargo)
                                        .replaceAll('$empresa$', contacList[num].empresa)
                                        .replaceAll('$pais$', contacList[num].pais)
                                        .replaceAll('$numero$', contacList[num].numero)
                                        .replaceAll('$observaciones$', contacList[num].observaciones)
                                        .replaceAll('$deuda$', currency + ' ' + contacList[num].customfields.deuda)
                                        .replaceAll('$fechavencimiento$', formatDate)
                                        
        arrayMessages.push(newMessages)
    }
    
    return arrayMessages
}


const getRecipientsPhone = async(usersData) => {
    const phoneNumbers = []
    for( let user of usersData ){
        const phone = await user.numero.replace(/[^0-9]/g, '')
        phoneNumbers.push(phone)
    }
    return phoneNumbers
}

const extractInfoFromDatabase = async() => {
    const getInformation = await fetchPythonBackend('api/sap-bo')
    if( getInformation.ok ){
        const informationResponse = await getInformation.json()
        console.log(informationResponse)
    }
}


export { extractInfoFromDatabase, getConvertedMessages, getRecipientsPhone }