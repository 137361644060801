import React, { useContext, useEffect, useState } from 'react'
import { ElementContext } from './ElementContext';
import { useTranslation } from 'react-i18next';


const ThankYouScreen = () => {
  
  const { updatedPlanMessage } = useContext(ElementContext);
  const [counter, setCounter] = useState(7);

  const { t } = useTranslation();

  useEffect(() => {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if(counter === 0){
      window.location.replace('/')
    }
  }, [counter])
  


  return (
      <div style={{ "height": "100vh" }} className='d-flex flex-column justify-content-center'>
          <div>
              <div className='harbinger-logo-azul'></div>
              <h5 className='text-center my-4'>{updatedPlanMessage}</h5>
          </div>

          <div className='d-flex flex-column align-items-center justify-content-center'>
              <span className='color-login text-center'>
                  {t('redirectMsg')}.
              </span>
              <span className='lead color-login text-center mt-3'>
                  {counter}
              </span>
          </div>
      </div>
  )
}

export default ThankYouScreen