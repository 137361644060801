
const apiBaseUrl = window.location.href.includes('localhost:3000')
                                            ? 'http://localhost:8080'
                                            : window.location.origin;

const pythonBaseUrl = window.location.href.includes('localhost:3000')
                                            ? 'http://localhost:5000'
                                            : 'https://hbgaiqas.seidoranalytics.com';


const fetchBackend = ( endpoint, data, method = 'GET' ) => {
    const url = `${ apiBaseUrl }/api/${ endpoint }`;

    if ( method === 'GET' ) {
        return fetch( url, {
                    headers: {
                        'Content-type': 'application/json',
                        'hbg-token': localStorage.getItem('hbg-token')
                    }
                });
    } else {
        return fetch( url, {
                    method,
                    headers: {
                        'Content-type': 'application/json',
                        'hbg-token': localStorage.getItem('hbg-token')
                    },
                    body: JSON.stringify( data )
                });
    }
}


const fetchPythonBackend = ( endpoint, data, method = 'GET' ) => {
    const url = `${ apiBaseUrl }/${ endpoint }`;

    if ( method === 'GET' ) {
        return fetch( url, {
                    headers: {
                        'Content-type': 'application/json',
                        'hbg-token': localStorage.getItem('hbg-token')
                    }
                });
    } else {
        return fetch( url, {
                    method,
                    headers: {
                        'Content-type': 'application/json',
                        'hbg-token': localStorage.getItem('hbg-token')
                    },
                    body: JSON.stringify( data )
                });
    }
}


export {
    fetchBackend,
    fetchPythonBackend,
    apiBaseUrl,
    pythonBaseUrl
}