import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import ExtractionWizardStepper from '../ui/ExtractionWizardStepper'
import '../../styles/integrations.css'


Modal.setAppElement('#root');

const NewExtractionWizardModal = () => {
    
    const { newExtractionModalOpen, toggleSidebar } = useContext(ElementContext)


  return (
    <Modal
        isOpen={newExtractionModalOpen}
        closeTimeoutMS={ 200 }
        className={`modal-new-extraction modal-sidebar-${toggleSidebar ? 'open' : 'close'} d-flex flex-column justify-content-between`}
        overlayClassName={`modal-fondo-sidebar-${toggleSidebar ? 'opened' : 'closed'}`}
    >
        <ExtractionWizardStepper />
    </Modal>
  )
}

export default NewExtractionWizardModal