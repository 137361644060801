import React, { useContext, useState } from 'react';
import { useAuth } from '@frontegg/react';
import countries from 'i18n-iso-countries';
import Swal from 'sweetalert2';
import { fetchBackend } from '../helpers/fetch';
import '../styles/sidebar.css'
import AddButton from './AddButton';
import { ElementContext } from './ElementContext';
import RemoveButton from './RemoveButton';
import SelectListButton from './SelectListButton';
import ContactInfo from './ContactInfo';
import phone from 'phone';
import InfoModal from './modals/InfoModal';
import SelectListModal from './modals/SelectListModal';
import ScheduleSMSMessages from './modals/ScheduleSMSMessages';
import TemplateModal from './modals/TemplateModal';
import { useTranslation } from 'react-i18next';
import FormularioSMSBloqueado from './FormularioSMSBloqueado';
import BannedTenantModal from './modals/BannedTenantModal';
import SMSMFAModal from './modals/SMSMFAModal';


countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));


// Uso del replace insensitive-case
String.prototype.replaceAll = function(strReplace, strWith) {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};



const FormularioSMS = () => {

    const { user } = useAuth();
    const { inputList, setInputList, infoToShow, setScheduleSMSModal, setScheduleMessage, message, setMessage, setTemplateModal, setShowInfoModal, toggleSidebar, delayMsg, userPlan, setFrequencySelected, oscillationMsg, totalSMS, setTotalSMS, setBannedTenantModal, globalEnv, setMFAModal, userConfiguration } = useContext(ElementContext);

    const { t, i18n } = useTranslation();
    const [buttonSelected, setButtonSelected] = useState('');
    const [sendingMsg, setSendingMsg] = useState(false);
    const [infoSelected, setInfoSelected] = useState('');
    const [messageMaxLengthReached, setMessageMaxLengthReached] = useState(false)

    const limiteSMSPorPlan = globalEnv.PLANS[userPlan].maxSMS;

    
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list)
    }
  
    
    const handleTextChange = (e) => {
        const { value } = e.target;
        if( value.length <= globalEnv.MSG_MAX_LENGTH ){
            setMessageMaxLengthReached(false)
            setMessage(value);
        } else {
            setMessageMaxLengthReached(true)
        }
    }
  
    const handleRemoveInput = (index) => {
        if(inputList.length >= 2){
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
        } else {
            setInputList([
                {numero: ''}
              ])
        }
    }
  
    const handleSubmit = async(e) => {
        e.preventDefault();
        const tenant = user.email.replace('@', '_')
        setSendingMsg(true)

        if(infoToShow.length === 0 && inputList[0].numero === ''){
            Swal.fire(t('warning'), t('atLeastOneContact'), 'warning')
            setSendingMsg(false)
            return
        }

        const numbers = [];
        const arrayMessages = []
        const arrayEstados = []
        const parsedNumbers = []
        let data = {};
        let body;
        let estado;
  
        // Contactos sin agendar
        if( !(infoToShow.length > 0) ){
            // Filtro de inputs vacíos | Campo número
            inputList.map( num => {
                if(num.numero === ''){
                    console.log('Campo vacío encontrado')
                } else {
                    numbers.push(num.numero)
                }
            })
        }

        // Contactos agendados
        if(infoToShow.length > 0){
            // Filtro de inputs vacíos | Campo número
            infoToShow.map( contacto => {
                if(contacto.numero === ''){
                    console.log('Campo vacío encontrado')
                } else {
                    numbers.push(contacto.numero)
                }
            })
        }


        // Control de cuerpo de mensaje, detección de fraude
        let esFraude = false
        const lowerCaseMessage = message.toLowerCase()
        const words = globalEnv.BANNED_TEXT
        for(let word of words){
            if(lowerCaseMessage.includes(word)){ esFraude = true }
        }
        if(esFraude){
            const data = {
                tenant: user.email,
                platform: 'SMS',
                message
            }
            await fetchBackend('sendEmailRequest', data, 'POST')
            setSendingMsg(false)
            return
        }


        // Control de cantidad de SMS a punto de enviar
        const controlSMS = controlarEnvioSMS(numbers.length)
        if(controlSMS){
            setSendingMsg(false)
            Swal.fire(t('warning'), t('SMSLimitExceded'), 'warning')
            return
        }
        
        // Chequear si el es Tenant baneado
        const isBannedTenant = await globalEnv.BANNED_TENANTS.includes(tenant)
        if( isBannedTenant ){
            setBannedTenantModal(true)
            setSendingMsg(false)
            return
        }

    
        for(let num in numbers){
            // Verifica si el mensaje se va a enviar o no, por ser número inexistente
            let number = numbers[num];
            if(!number.includes('+')){
                number = '+' + numbers[num]
            }
            estado = number.includes('503') ? true : phone(number).isValid;
            arrayEstados.push(estado);

            // Obtiene el país al cual pertenece el teléfono
            let parsedNum = await numbers[num].replace(/[^0-9]/g, '')
            
            if(parsedNum[2] === '9'){
                parsedNum = await parsedNum.replace('9', '')
            }
            parsedNumbers.push(parsedNum)
            
            let countryFullname;
            let countryShortname;
            let newMessages;

            if(infoToShow.length > 0){
                countryShortname = await infoToShow[num].pais || '';
                countryFullname = countries.getName(countryShortname, i18n.resolvedLanguage) || ''
                
                // Reemplazos de variables en el cuerpo del mensaje
                if(i18n.resolvedLanguage === 'en'){
                    newMessages = message.replaceAll('$name$', infoToShow[num].nombre)
                                            .replaceAll('$lastname$', infoToShow[num].apellido)
                                            .replaceAll('$position$', infoToShow[num].cargo)
                                            .replaceAll('$company$', infoToShow[num].empresa)
                                            .replaceAll('$country$', countryFullname)
                                            .replaceAll('$phone$', infoToShow[num].numero)
                                            .replaceAll('$observations$', infoToShow[num].observaciones)
                } else {
                    newMessages = message.replaceAll('$nombre$', infoToShow[num].nombre)
                                            .replaceAll('$apellido$', infoToShow[num].apellido)
                                            .replaceAll('$cargo$', infoToShow[num].cargo)
                                            .replaceAll('$empresa$', infoToShow[num].empresa)
                                            .replaceAll('$pais$', countryFullname)
                                            .replaceAll('$numero$', infoToShow[num].numero)
                                            .replaceAll('$observaciones$', infoToShow[num].observaciones)
                }
                
                arrayMessages.push(newMessages)
            }
        }
        
        data = {
            datosEmisor: {nombreEmisor: user.name, numeroEmisor: 'Enviado por SMS'},
            datosDestinatarios: infoToShow.length > 0 ? infoToShow : null, //Array con todos los datos de los destinatarios
            message,
            messages: arrayMessages, //Array con msgs reemplazados
            arrayEstados,
            numbers: parsedNumbers.length === 0 ? numbers : parsedNumbers, //Array con los numeros destinatarios
            usuario: user.email,
            tenantid: tenant,
            time: parseInt(delayMsg),
            subject: user.name,
            oscillationMsg,
            canal: 'SMS'
        }

        const resultado = await fetchBackend( 'send-sms', data, 'POST' );
        body = await resultado.json();

        if( body.ok ){
            Swal.fire(t('success'), t('msgSuccess'), 'success');
        } else {
            Swal.fire('Oops', t('problem'), 'error')
        }

        setTotalSMS(parseInt(totalSMS) + numbers.length)
        setSendingMsg(false)
    }


    
    const openScheduleModal = (e) => {
        e.preventDefault();

        if(!message){
            return Swal.fire(t('warning'), t('beforeScheduleMsg'), 'warning')
        }

        setScheduleMessage(message)
        setFrequencySelected('seleccionar')
        setScheduleSMSModal(true)
    }
    
    const openTemplateModal = (e) => {
        e.preventDefault();
        
        setButtonSelected(e.target.value)
        setScheduleMessage(message)
        setTemplateModal(true)
    }

    
    // Apertura de modal: Cómo usar variables en caja de texto
    const handleInfoModal = (e) => {
        e.preventDefault()
        setInfoSelected(e.target.id)
        setShowInfoModal(true)
    }


    const controlarEnvioSMS = (num) => {
        const nuevoTotal = num + parseInt(totalSMS)
        return nuevoTotal > limiteSMSPorPlan
    }


    if(!userConfiguration.smsCodeValidated){
        setMFAModal(true)
    }


    
    return (parseInt(totalSMS) >= limiteSMSPorPlan) ? (
        <>
            <FormularioSMSBloqueado limiteSMSPorPlan={limiteSMSPorPlan} />
            <SMSMFAModal />
        </>
    ) : (
      <div className="home">
          <div className="d-flex align-items-center justify-content-between">
              <div className={ toggleSidebar ? 'titles-margin-close' : 'titles-margin-open' }>
                  <div className="text">
                      {t('sendMsgsBySmsTitle')}
                  </div>
                  <p className='subtitle mb-0'>
                      {t('sendMsgsBySmsSubtitle')}
                  </p>
              </div>
          </div>
  
          <div className="container">
              <div className="row d-flex justify-content-between my-4">
                    <div className="col-6 btn-group btn-group-width">
                        <AddButton/>
                        <SelectListButton/>
                        <RemoveButton/>
                    </div>

                    <div className='col-6 d-flex justify-content-end align-items-center'>
                        <h6 className='text-mediumblue mb-0 me-3'>
                            {t('importantInfo')}
                        </h6>
                        <i
                            className='bx bx-info-circle info-icon animate__animated animate__heartBeat animate__infinite infinite'
                            id='importantInfo'
                            onClick={ handleInfoModal }
                        ></i>
                    </div>
              </div>
              
              <form id="whatsappForm">
                  <div className="row d-flex justify-content-between">
                      <div id="formDiv" className="col-6 mb-3">
                          <label className="form-label text-91">
                              {t('contacts')}
                          </label>
                          {
                              infoToShow.length > 0 ? (
                                  <ContactInfo/>
                              ) : (
                                  inputList.map( (item, index) => {
                                      return (
                                          <div key={index} className="d-flex align-items-center mb-1">
                                              <input
                                                  type="text"
                                                  className="form-control me-2"
                                                  placeholder={t('enterPhoneNumber')}
                                                  name="numero"
                                                  value={item.numero}
                                                  onChange={ e => handleInputChange(e, index) }
                                              />
                                              <i
                                                  className='bx bx-trash icon text-danger me-4 pointer'
                                                  onClick={ () => handleRemoveInput(index) }
                                              ></i>
                                          </div>
                                      )})
                              )
                          }
                      </div>
                      
                      <div className="col-6 mb-3">

                            <div className="d-flex align-items-center justify-content-between">
                                <label htmlFor="wa-text" className="form-label text-91">
                                    {t('message')}
                                </label>

                                {
                                    messageMaxLengthReached && (
                                        <small className='d-flex align-items-center text-lightblue mb-2'>
                                            <i className='bx bxs-hand x-size me-2'></i>
                                            <span>{ t('maxCharReached') }</span>
                                        </small>
                                    )
                                }
                            </div>

                            <textarea
                                className="form-control"
                                id="wa-text"
                                name="message"
                                cols="30"
                                rows="10"
                                maxLength={globalEnv.MSG_MAX_LENGTH + 1}
                                value={message}
                                placeholder={`${t('write')}...`}
                                onChange={ (e) => { handleTextChange(e) } }
                            ></textarea>

                          <div className='btn-group w-100'>
                            <button
                                id="form-button"
                                className="btn btn-sm btn-lightblue mt-3 w-25"
                                type="submit"
                                onClick={ (e) => handleSubmit(e) }
                                disabled={ !message || sendingMsg }
                            >
                                {
                                    sendingMsg ? (
                                        <div>
                                          <span className='text-light'>
                                              {t('sending')}...
                                          </span>
                                          <div className="spinner-border text-light loading-button ms-2" role="status">
                                              <span className="visually-hidden">Loading...</span>
                                          </div>
                                        </div>
                                    ) : t('send')
                                }
                            </button>
                          
                          {
                              userPlan === 'trial' || userPlan === 'personal' ? null : (
                                    <button
                                        id="form-button"
                                        className="btn btn-sm btn-mediumblue mt-3 w-25"
                                        type="submit"
                                        onClick={ openScheduleModal }
                                        disabled={ infoToShow.length === 0 }
                                    >
                                        {t('scheduleMsg')}
                                    </button>
                              )
                          }
                          
                            <button
                                id="form-button"
                                className="btn btn-sm btn-lightblue mt-3 w-25"
                                type="submit"
                                onClick={ openTemplateModal }
                                value="opentemplate"
                            >
                                {t('openTemplate')}
                            </button>

                            <button
                                id="form-button"
                                className="btn btn-sm btn-darkblue mt-3 w-25"
                                type="submit"
                                onClick={ openTemplateModal }
                                value="savetemplate"
                                disabled={ !message }
                            >
                                {t('saveTemplate')}
                            </button>
                          </div>

                          
                            <div className='d-flex justify-content-end align-items-center mt-4'>
                                <h6 className='text-mediumblue mb-0 me-3'>
                                    {t('customMsgs')}
                                </h6>
                                <i
                                    className='bx bx-message-alt-edit info-icon animate__animated animate__heartBeat animate__infinite infinite'
                                    id='customMessages'
                                    onClick={ handleInfoModal }
                                ></i>
                            </div>
                      </div>
                  </div>
  
              </form>
          </div>

          <InfoModal infoSelected={infoSelected}/>
          <SMSMFAModal />
          <SelectListModal/>
          <ScheduleSMSMessages/>
          <TemplateModal buttonSelected={buttonSelected} />
          <BannedTenantModal />
      </div>
    )
  };
  
  export default FormularioSMS;
  