import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import { fetchBackend } from '../../helpers/fetch';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';


import dotenv from 'dotenv'
import { useAuth } from '@frontegg/react';
dotenv.config()

Modal.setAppElement('#root');


const NuevaListaModal = () => {

    const { user } = useAuth();
    const [nuevaLista, setNuevaLista] = useState('')
    const { modalIsOpen, setModalIsOpen, setListNames, madeByUser, setSelectedGroupList } = useContext(ElementContext);
    const { t } = useTranslation();
    
    const closeModal = () => {
        setModalIsOpen(false)
    }

    const closeModal2 = (e) => {
        e.preventDefault();
        setModalIsOpen(false)
    }

    const handleInputChange = (e) =>{
        const { value } = e.target
        setNuevaLista(value)
    }

    const handleSubmit = async(e) =>{
        e.preventDefault();
        const tenant = user.email.replace('@', '_')
        const fechacreacion = DateTime.now().toString();
        const origen = null

        const resultado = await fetchBackend( 'lists', {newList: nuevaLista, email: madeByUser, tenantId: tenant, fechacreacion, origin: origen}, 'POST' );
        const body = await resultado.json();
        
        if( !body.ok ){
          return Swal.fire('Oops', t('problem'), 'error')
        }
        
        // Get Listas
        const getListas = await fetchBackend(`lists/${tenant}`);
        const resListas = await getListas.json();
        const filterCommonLists = await resListas.data.filter(lista => lista.tipodelista === 'common')
        const listIndex = await filterCommonLists.length - 1
        setListNames(filterCommonLists)
        setSelectedGroupList(listIndex)

        setNuevaLista('')
        closeModal()
    }

  return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            closeTimeoutMS={ 200 }
            className="modal-newgroup p-4"
            overlayClassName="modal-fondo"
        >
            <h4>{t('newList')}</h4>
            <hr />
            <form onSubmit={(e) => handleSubmit(e)}>
                <label htmlFor="nombre-grupo" className='mb-2'>{t('listName')}</label>
                
                <input
                    id='nombre-grupo'
                    type="text"
                    className="form-control me-2"
                    placeholder={t('enterListName')}
                    name="nuevaLista"
                    value={nuevaLista}
                    autoComplete="off"
                    autoFocus
                    onChange={ e => handleInputChange(e) }
                    required
                />

                <div className="d-flex justify-content-center">
                    <button
                        className='btn btn-sm btn-mediumblue float-right mt-4 me-1'
                        type="submit"
                    >
                        {t('create')}
                    </button>
                    <button
                        className='btn btn-sm btn-91 float-right mt-4 ms-1'
                        onClick={ closeModal2 }
                    >
                        {t('cancel')}
                    </button>
                </div>
            </form>
        </Modal>
  )
}

export default NuevaListaModal