import React, { useContext } from 'react'
import { ElementContext } from '../ElementContext';
import { Icon } from '@iconify/react'
import { getWhatsappApiTemplates, handleUpdateSelectedCredentials } from '../../helpers/whatsapp-api';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';
import DeleteWACredentials from '../modals/DeleteWACredentials';
import { useState } from 'react';
import { fetchBackend } from '../../helpers/fetch';
import '../../styles/card.css'

const WhatsappAPICredentialsCards = () => {
    
  const { wAPIUserInformation, setConfirmDeleteModal, selectedWACredentials, setSelectedWACredentials, userConfiguration, setUserConfiguration, setWhatsappTemplates, setApprovedTemplates } = useContext(ElementContext);
  const { user } = useAuth();
  const { t } = useTranslation();
  const [credentials, setCredentials] = useState(null)


  const handleDeleteCard = async(cardInfo) => {
    setCredentials(await cardInfo)
    setConfirmDeleteModal(true)
  }

  const handleChangeSelectedCard = async(card) => {
    const cardId = await card.id
    const configuration = await userConfiguration
    const email = await user.email
    const updateSelected = await handleUpdateSelectedCredentials(configuration, cardId, email)
    if(updateSelected){
        const allWACredentials = await userConfiguration.whatsappAPIcredentials
        const filteredWACredentials = await allWACredentials.filter( cred => cred.phoneNumberId === cardId )[0]
        const whatsappApiTemplates = await getWhatsappApiTemplates(filteredWACredentials)
        const allTemplates = whatsappApiTemplates.allTemplates
        const approvedTemplates = whatsappApiTemplates.approvedTemplates
        setWhatsappTemplates(allTemplates)
        setApprovedTemplates(approvedTemplates)

        const configRequest = await fetchBackend(`settings/${email}`)
        const configResponse = await configRequest.json()
        const configData = await configResponse.data[0].configuracion
        const newUserConfiguration = await JSON.parse(await configData)
        setUserConfiguration(newUserConfiguration)
        setSelectedWACredentials(cardId)
    } else {
        console.log('No se pudo completar la solicitud')
    }
  }


  return (
    <div className="container-fluid">
        <div className="row">
            {
                wAPIUserInformation.map( (WInfo, index) => (
                    <div key={index} className='col-4 d-flex justify-content-center mt-5 animate__animated animate__backInRight'>
                        <div className={ selectedWACredentials === WInfo.id ? 'favourite-card' : 'not-favourite-card'}>
                            <div className='header-card'>
                                <div className='d-flex'>
                                    <div className='credentials-corner'>
                                        <Icon icon="logos:whatsapp-icon" className='credentials-card-icon' />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <span className='mt-2 mb-0 card-title'>
                                            { WInfo.verified_name }
                                        </span>
                                        <small className="text-91 card-detail">
                                            -
                                        </small>
                                    </div>
                                </div>

                                {
                                    selectedWACredentials === WInfo.id ? (
                                        <Icon
                                            icon="material-symbols:check-circle-rounded" className='selected-credentials'
                                        />
                                    ) : (
                                        <Icon
                                            icon="material-symbols:circle-outline"
                                            className='selected-credentials pointer'
                                            onClick={ () => handleChangeSelectedCard(WInfo) }
                                        />
                                    )
                                }
                            </div>
                            
                            <div className="body-card">
                                <div>
                                    <span className='data-header'>{t('status')}: </span>
                                    <span className='data-response'>
                                        { WInfo.code_verification_status }
                                    </span>
                                </div>
                                <div>
                                    <span className='data-header'>{t('phone')}: </span>
                                    <span className='data-response'>
                                        { WInfo.display_phone_number }
                                    </span>
                                </div>
                                <div>
                                    <span className='data-header'>{t('country')}: </span>
                                    <img src={WInfo.country_flag} width='25' alt="Country" className='ms-2' />
                                </div>
                                <div>
                                    <span className='data-header'>{t('phoneId')}: </span>
                                    <span className='data-response'>
                                        { WInfo.id }
                                    </span>
                                </div>
                                <div>
                                    <span className='data-header'>{t('name')}: </span>
                                    <span className='data-response'>
                                        { WInfo.verified_name }
                                    </span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className='data-header'>{t('quality')}: </span>
                                    <span className='data-response'>
                                        {
                                            WInfo.quality_rating && 
                                            <div className={`${WInfo.quality_rating.toLowerCase()}-quality-rating`}></div>
                                        }
                                    </span>
                                </div>
                            </div>

                            <div className="footer-card">
                                <div onClick={ () => handleDeleteCard(WInfo) }>
                                    <small>{t('delete')}</small>
                                    <div className="underline-footer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

        <DeleteWACredentials credentials={credentials}/>
    </div>
  )
}

export default WhatsappAPICredentialsCards