import React, { useContext, useState } from 'react';
import { useAuth } from '@frontegg/react';
import countries from 'i18n-iso-countries';
import AddButton from '../AddButton';
import { ElementContext } from '../ElementContext';
import RemoveButton from '../RemoveButton';
import SelectListButton from '../SelectListButton';
import ContactInfo from '../ContactInfo';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { sendTemplateMessage } from '../../helpers/whatsapp-api';
import WhatsappAPISelector from './WhatsappAPISelector';
import Swal from 'sweetalert2';
import SelectListModal from '../modals/SelectListModal';
import 'animate.css';
import '../../styles/sidebar.css';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));


// Uso del replace insensitive-case
String.prototype.replaceAll = function(strReplace, strWith) {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};


const WhatsappAPIMessages = () => {
    
    const { user } = useAuth();
    const { userInformation, inputList, setInputList, toggleSidebar, infoToShow, whatsappTemplates, userConfiguration, selectedWACredentials, approvedTemplates } = useContext(ElementContext);

    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [templateMessage, setTemplateMessage] = useState({ 'header': '', 'body': '', 'footer': '' })
    const [templateName, setTemplateName] = useState('')
    const [messageFailed, setMessageFailed] = useState({ ok: false, msg: '' })
    

    useEffect(() => {
        setInputList([
          { numero: userInformation.countryCode }
        ])
    }, [])
    
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list)
    }

  
    const handleRemoveInput = (index) => {
        if(inputList.length >= 2){
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
        } else {
            setInputList([
                {numero: ''}
              ])
        }
    }

    const handleSelectTemplate = async() => {
        const template = document.querySelector('#tempSelect').selectedOptions[0].value;
        const templateSelected = await whatsappTemplates.filter( temp => temp.name === template )
        const templateHeader = await templateSelected[0].components[0].text || ''
        const templateBody = await templateSelected[0].components[1].text || ''
        const templateFooter = await templateSelected[0].components[2].text || ''

        setTemplateName(template)
        setTemplateMessage({
            'header': templateHeader, 'body': templateBody, 'footer': templateFooter
        })
    }

    const handleSubmitTemplateMessage = async(e) => {
        e.preventDefault();
        setLoading(true);
        setMessageFailed({ ok: false, msg: '' })
        const usersList = infoToShow.length > 0
                                      ? await infoToShow.map( user => user.numero )
                                      : await inputList.map( user => user.numero )
        
        const allWACredentials = await userConfiguration.whatsappAPIcredentials
        const WACredentials = await allWACredentials.filter( cred => cred.phoneNumberId === selectedWACredentials )[0]
        
        const selectedTemplate = await whatsappTemplates.filter( temp => temp.name === templateName )[0]

        let deliverySuccess = 0
        let deliveryFailed = 0
        for(let phone of usersList){
            const envioMensaje = await sendTemplateMessage(WACredentials, selectedTemplate, phone)
            if(envioMensaje.messages){
                deliverySuccess++
            } else {
                deliveryFailed++
                setMessageFailed({ ok: true, msg: `${t('failedText1')} ${phone}, ${t('failedText2')}` })
            }
        }

        Swal.fire( t('completed'), `${t('successfulMessages')}: ${deliverySuccess}, ${t('failedMessages')}: ${deliveryFailed}`, 'success')
        setLoading(false)
    }


    
    if(!userConfiguration.whatsappAPIcredentials || userConfiguration.whatsappAPIcredentials.length < 1){
        return <div className='home'>
                    <h1>{t('youDontHaveCreentials')}</h1>
               </div>
    }
  

    return (
        <div className="home animate__animated animate__fadeIn">
            <div className="d-flex align-items-center justify-content-between">
                <div className={ toggleSidebar ? 'titles-margin-close' : 'titles-margin-open' }>
                    <div className="text">
                        {t('sendWApiMessages')}
                    </div>
                    <p className='subtitle mb-0'>
                        {t('sendWApiTemplates')}
                    </p>
                </div>
            </div>

            <div className="container">
                <div className="row d-flex justify-content-between my-4">
                    <div className="col-6 btn-group btn-group-width">
                        <AddButton/>
                        <SelectListButton/>
                        <RemoveButton/>
                    </div>
                    
                    <div className="col-6">
                        <WhatsappAPISelector/>

                        <select className='form-select form-select-sm wa-selector-box mt-2' id="tempSelect" defaultValue={'default'} aria-label="Select template" onChange={ handleSelectTemplate }>
                            <option value="default" hidden>{t('selectTemplate')}</option>
                            {
                                approvedTemplates.map( template => (
                                    <option
                                        key={template.id}
                                        value={template.name}>{template.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>

            
                <form id="whatsappForm" encType='multipart/form-data' onSubmit={ handleSubmitTemplateMessage }>
                    <div className="row d-flex justify-content-between">
                        <div id="formDiv" className="col-6 mb-3">
                            <label className="form-label text-91">
                                {t('contacts')} - {t('enterPhoneNumber')}
                            </label>
                            {
                                infoToShow.length > 0 ? (
                                    <ContactInfo/>
                                ) : (
                                    inputList.map( (item, index) => {
                                        return (
                                            <div key={index} className="d-flex align-items-center mb-1">
                                                <input
                                                    type="text"
                                                    className="form-control me-2"
                                                    placeholder={t('enterPhoneNumber')}
                                                    name="numero"
                                                    value={item.numero || ''}
                                                    onChange={ e => handleInputChange(e, index) }
                                                />
                                                <i
                                                    className='bx bx-trash icon text-danger me-4 pointer'
                                                    onClick={ () => handleRemoveInput(index) }
                                                ></i>
                                            </div>
                                        )})
                                )
                            }
                        </div>
                        
                        <div className="col-6 mb-3">
                            
                                <div className="d-flex align-items-center justify-content-between">
                                    <label htmlFor="wa-text" className="form-label text-91">
                                        {t('message')}
                                    </label>
                                </div>
                                
                                {
                                    messageFailed.ok && (
                                        <div className="alert alert-danger" role="alert">
                                            { messageFailed.msg }
                                        </div>
                                    )
                                }

                                <div className='wa-message-box'>
                                    {
                                        templateMessage.body ? (
                                            <>
                                                <h6 className='wa-header'>{ templateMessage.header }</h6>
                                                <p className='wa-body'>{ templateMessage.body }</p>
                                                <span className='wa-footer'>{ templateMessage.footer }</span>
                                            </>
                                        ) : (
                                            <p className='text-91'>
                                                {t('templateNotSelected')}
                                            </p>
                                        )
                                    }
                                </div>

                                <div className='btn-group w-100'>
                                    <button
                                        id="form-button"
                                        className="btn btn-sm btn-lightblue mt-3 w-25"
                                        type="submit"
                                    >
                                        {t('send')}
                                    </button>
                                </div>
                        </div>
                    </div>
                </form>
            </div>
            
            <SelectListModal/>
        </div>
    )
  };
  
  export default WhatsappAPIMessages;
  