import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import {fetchBackend} from '../../helpers/fetch'
import { useAuth } from '@frontegg/react';
let QRCode = require('qrcode.react');


Modal.setAppElement('#root');


const MultisessionModal = () => {

    const { user } = useAuth();
    const { codigoQR, setCodigoQR, QRSolicitado, multisessionModal, multiSessionUsuarioEmisor, requestedQRNumber, setMultisessionModal, setQRSolicitado } = useContext(ElementContext);
    const { t } = useTranslation();
    const [cancelRequested, setCancelRequested] = useState(false)


    const handleCancelQR = async() => {
        setCancelRequested(true)
        const tenantid = user.email.replace('@', '_')
        const removeSession = await fetchBackend(`eliminar-sesion/${tenantid}${requestedQRNumber}`, {}, 'DELETE')
        const removeResponse = await removeSession.json()

        if( removeResponse.ok ){
            setMultisessionModal(false)
            setQRSolicitado(false)
            setCancelRequested(false)
            setCodigoQR(false)
        }
    }


  return (
        <Modal
            isOpen={ multisessionModal }
            className="modal-multisession"
            overlayClassName="modal-fondo"
        >
            <div className="row">
                <h6 className='text-center text-lightblue'>
                    {t('youHave')} {multiSessionUsuarioEmisor.length} {t('sessionStartedSoFar')}
                </h6>
                <h5 className='text-center text-mediumblue'>
                    {t('scanThisQR')}
                </h5>
            </div>
                        
            <div className="row mt-4">
                <div className="col d-flex flex-column justify-content-center align-items-center">
                {
                    codigoQR && (
                        <div className="d-flex justify-content-center">
                            <QRCode
                                value={codigoQR}
                                renderAs='svg'
                                size={ 400 }
                                className='p-4 bg-white animate__animated animate__fadeInDown'
                            />
                        </div>
                    )
                }

                {
                    (!codigoQR && QRSolicitado) && (
                        <div className='d-flex flex-column justify-content-center align-items-center my-4'>
                            <h5 className='text-color'>
                                {t('gettingSession')}...
                            </h5>
                            <div className="spinner-grow text-color" role="status"></div>
                        </div>
                    )
                }
                </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
                <button
                    className='btn btn-sm btn-91'
                    onClick={ handleCancelQR }
                    disabled={ cancelRequested }
                >
                    {
                        cancelRequested ? t('processing') : t('cancel')
                    }
                </button>
            </div>
        </Modal>
  )
}

export default MultisessionModal