import React from 'react';
import { useTranslation } from 'react-i18next';


const AttachFileCard = () => {
    
    const { t } = useTranslation();


    const handleAttachImages = () => {
        document.querySelector('#attach-imagefiles').click();
    }
    
    const handleAttachFiles = () => {
        document.querySelector('#attach-appfiles').click();
    }


  return (
    <div>
        {/* User card */}
        <input type="checkbox" className="attachfilecard d-none" name="" id="attachfilecard"/>
        <div className='attachcard'>
            <label htmlFor="attachfilecard" className='pointer d-flex justify-content-end'>
                <i className='bx bx-x x-icon'></i>
            </label>

            <div className='d-flex mt-3'>
                <div className='d-flex flex-column align-items-center'>
                    <i
                        className='bx bxs-image attachfiles-icon'
                        onClick={ handleAttachImages }
                    ></i>
                    <small>{t('image')}</small>
                </div>

                <div className='d-flex flex-column align-items-center'>
                    <i
                        className='bx bxs-file-blank attachfiles-icon'
                        onClick={ handleAttachFiles }
                    ></i>
                    <small>{t('document')}</small>
                </div>
            </div>

        </div>
    </div>
  )
}

export default AttachFileCard